import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  Tabs,
  Tab,
  Stack,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useTitle } from "../../hooks/useTitle";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/Email";
import { Customer } from "../../types/customer";
import ClientProfilePanel from "./ClientProfilePanel";
import ClientProjectsPanel from "./ClientProjectsPanel";
import { useGetCustomerByIdQuery } from "../../services/customer.service";

const ProfileStat = ({ label, value }: { label: string; value: string }) => (
  <Box
    sx={{
      border: "1px solid #ccc",
      borderRadius: 1,
      py: 0.5,
      px: 1,
      alignItems: "center",
      mr: 1,
      bgcolor: "white",
      minWidth: 80,
    }}
  >
    <Typography textAlign={"center"} fontSize={16}>
      {value}
    </Typography>
    <Typography textAlign={"center"} fontSize={12} color="text.secondary">
      {label}
    </Typography>
  </Box>
);

const ProfileMeta = ({
  icon,
  value,
}: {
  icon: React.ReactNode;
  value: string;
}) => (
  <Button
    variant="text"
    startIcon={icon}
    size="small"
    sx={{ mr: 2, textTransform: "unset", color: "text.secondary" }}
  >
    {value}
  </Button>
);

const ProfileHeader = ({ data }: { data?: Customer }) => (
  <Stack direction={"row"} alignItems="center">
    <Stack direction="column">
      <Typography variant="h5">{data?.first_name || "n/a"}</Typography>
      <Stack direction="row" alignItems="center">
        <ProfileMeta
          icon={<LocationCityIcon />}
          value={`${data?.city || "Unknown City"}, ${
            data?.state || "Unknown State"
          }`}
        />
      </Stack>
      {/* <Stack direction="row" sx={{ mt: 1 }}>
        <ProfileStat label="Rating" value={"4.7"} />
        <ProfileStat label="Job Success" value={"100%"} />
        <ProfileStat label="Job" value={"1000"} />
        <ProfileStat label="Total Earned" value={"$20K"} />
      </Stack> */}
    </Stack>
  </Stack>
);

const ClientDetailPage = () => {
  const { customerId } = useParams();
  const [tab, setTab] = React.useState("profile");

  const { data } = useGetCustomerByIdQuery(parseInt(customerId!), {
    skip: !customerId,
  });

  useTitle(`Client #${data?.id}`, `${data?.first_name || "n/a"} | Client`);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Box>
      <ProfileHeader data={data} />
      <Box sx={{ mt: 2 }}>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="Profile" value={"profile"} />
          {/* <Tab label="Jobs" value={"jobs"} /> */}
        </Tabs>
      </Box>
      <Box sx={{ mt: 1 }}>
        {tab == "profile" && <ClientProfilePanel data={data} />}
        {/* {tab == "jobs" && <ClientProjectsPanel data={data} />} */}
      </Box>
    </Box>
  );
};
export default ClientDetailPage;
