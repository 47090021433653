import { BalanceHistory } from "../types/finance";
import { baseApi, QueryResponse } from "./base";
import { TAG_TYPE_FINANCE } from "./tags";

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_FINANCE],
});

export const financeApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getBalanceHistories: builder.query<
      QueryResponse<BalanceHistory>,
      {
        page?: number;
        pageSize?: number;
        sortField?: string;
        sortMode?: string | null;
        load?: string;
        search?: string;
      }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({
        page = 0,
        pageSize = 25,
        sortField,
        sortMode,
        load,
        search,
      }) => ({
        url: "balance-histories",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `created_at|desc`,
          load,
          search,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE_FINANCE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE_FINANCE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE_FINANCE, id })),
        ];
      },
    }),
    getBalanceHistoryById: builder.query<BalanceHistory, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (id) => ({
        url: `balance-histories/${id}`,
        method: "GET",
        params: {},
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE_FINANCE, id }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetBalanceHistoriesQuery, useGetBalanceHistoryByIdQuery } =
  financeApi;
