import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { ReportProgress } from "../../../types/project";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import { elapsed } from "../../../utils/dateHelper";

const ForceCheckoutModal = ({
  open,
  onClose,
  onSubmit,
  progress,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: (progress_id: number, checkout_date: Date) => Promise<void>;
  progress: ReportProgress | null;
}) => {
  const [date, setDate] = React.useState(new Date());
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = () => {
    if (!date) {
      toast.error("Please fill in all the required fields.");
      return;
    }
    if (dayjs(date).isBefore(dayjs(progress?.check_in!))) {
      toast.error(
        "Cannot checkout Independent Professional before his/her current checkin time!"
      );
      return;
    }
    setLoading(true);
    onSubmit(progress?.id!, date).finally(() => {
      setLoading(false);
      onClose();
    });
  };

  const workingTime = React.useMemo(() => {
    if (!!progress && !!date) {
      return elapsed(
        new Date(date).getTime(),
        new Date(progress.check_in).getTime(),
        false
      );
    }
    return "";
  }, [progress, date]);

  React.useEffect(() => {
    if (open) {
      setDate(new Date());
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <DialogTitle id="alert-dialog-title">Force Checkout</DialogTitle>
      <DialogContent>
        <Box>
          <TextField
            label="Checkin Time"
            value={dayjs(progress?.check_in).format("MM/DD/YYYY hh:mm A")}
            disabled={true}
            sx={{ width: "100%" }}
          />
          <DesktopDateTimePicker
            sx={{ width: "100%" }}
            onChange={(val: any) => setDate(val!)}
            value={dayjs(date)}
            label="Forced Checkout Time"
            slotProps={{
              textField: {
                helperText: !date && "Please set a valid date and time",
              },
            }}
          />

          <Typography variant="body2" sx={{ mt: 1 }}>
            Working Time → {workingTime}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton onClick={handleSubmit} loading={loading}>
          Checkout
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default ForceCheckoutModal;
