import dayjs from "dayjs";
import { AreaChart } from "recharts";
import { Area } from "recharts";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";

// interface dataChartProps {
//   name: string;
//   total: number;
//   week: string;
// }

interface HourLineChartProps {
  id?: string;
  typeData?: string;
  data?: any[];
  color?: string;
}

const HourLineChart = (props: HourLineChartProps) => {
  const { typeData, id, data, color = "#82ca9d" } = props;

  return (
    <ResponsiveContainer width="100%" height={500}>
      <AreaChart
        id={id}
        // width={1200}
        // height={300}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={color} stopOpacity={0.8} />
            <stop offset="95%" stopColor={color} stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis xAxisId="0" dataKey="name" />
        <XAxis xAxisId="1" dataKey="week" allowDuplicatedCategory={false} />
        <YAxis tickCount={9} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        {/* <Line
          type="monotone"
          dataKey="total"
          stroke="#82ca9d"
          strokeWidth={3}
        /> */}
        <Area
          type="monotone"
          dataKey="total"
          stroke={color}
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#colorUv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default HourLineChart;
