import { Invoice } from "../types/invoice";
import { baseApi, StripeQueryResponse } from "./base";
import { TAG_TYPE_INVOICE } from "./tags";

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_INVOICE],
});

export const invoiceApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getInvoices: builder.query<
      StripeQueryResponse<Invoice>,
      { startingAfter?: string }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ startingAfter }) => ({
        url: "invoices",
        method: "GET",
        params: {
          starting_after: startingAfter,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE_INVOICE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE_INVOICE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE_INVOICE, id })),
        ];
      },
    }),
    addInvoice: builder.mutation<Invoice, Omit<Invoice, "id">>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (body) => {
        return {
          url: `invoices`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE_INVOICE, id: "PARTIAL-LIST" },
      ],
    }),
    // getBalanceHistoryById: builder.query<BalanceHistory, number>({
    //   extraOptions: {
    //     isCompanyApi: true,
    //   },
    //   query: (id) => ({
    //     url: `balance-histories/${id}`,
    //     method: "GET",
    //     params: {},
    //   }),
    //   providesTags: (result, error, id) => [{ type: TAG_TYPE_INVOICE, id }],
    // }),
  }),
  overrideExisting: false,
});

export const {
  useGetInvoicesQuery,
  useAddInvoiceMutation /*, useGetBalanceHistoryByIdQuery*/,
} = invoiceApi;
