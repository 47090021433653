import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Rating,
  TextField,
} from "@mui/material";
import { ProjectResource } from "../../../types/project";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

const GiveReviewModal = ({
  open,
  onClose,
  onSubmit,
  job,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    resource_id: number,
    star: number,
    message: string
  ) => Promise<void>;
  job: ProjectResource | null;
}) => {
  const [star, setStar] = React.useState(0);
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = () => {
    if (!star || !message) {
      toast.error("Please fill in all the required fields.");
      return;
    }
    setLoading(true);
    onSubmit(job?.resource_id!, star, message).finally(() => {
      setLoading(false);
      onClose();
    });
  };

  React.useEffect(() => {
    if (open) {
      setStar(0);
      setMessage("");
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <DialogTitle id="alert-dialog-title">
        Review for {job?.resource?.first_name} {job?.resource?.last_name}
      </DialogTitle>
      <DialogContent>
        <Rating
          value={star}
          readOnly={false}
          size={"large"}
          onChange={(e, newVal) => setStar(newVal || 0)}
          precision={0.5}
        />
        <Box>
          <TextField
            label="Review message"
            fullWidth
            multiline={true}
            rows={3}
            onChange={(e: any) => setMessage(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton onClick={handleSubmit} loading={loading}>
          Submit Review
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default GiveReviewModal;
