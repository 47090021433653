import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAddInvoiceMutation } from "../../services/invoice.service";
import SelectSimple from "../../components/SelectSimple";
import { Invoice } from "../../types/invoice";
import { toast } from "react-toastify";

interface TopupModalFormProps {
  open: boolean;
  onClose: () => void;
}

const TopupModalForm = ({ open, onClose }: TopupModalFormProps) => {
  const [add, { isLoading: adding }] = useAddInvoiceMutation();

  const {
    handleSubmit,
    clearErrors,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const currencyOptions = [
    { value: "usd", label: "USD" },
    // { value: "eur", label: "EUR" },
  ];

  const onSubmit = async (payload: any) => {
    try {
      const invoice: Invoice = await add(payload).unwrap();

      if (invoice) {
        // 👇️ redirect to external URL
        window.location.replace(invoice.hosted_invoice_url);
      }

      // console.log(JSON.stringify(payload));
    } catch (err: any) {
      toast.error(err);
    }
  };

  React.useEffect(() => {
    if (!open) return;

    setValue("total", 100);
    setValue("currency", "usd");

    clearErrors();
  }, [open]);

  //   const postAmount = (amount: number) => {};

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">Top Up</DialogTitle>
        <DialogContent>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            sx={{ mt: 2 }}
          >
            <Controller
              name="currency"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ref } }) => (
                <SelectSimple
                  title="Currency"
                  data={currencyOptions}
                  value={value}
                  sx={{ mt: 0 }}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name="total"
              control={control}
              rules={{ required: true, min: 100 }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Amount (min: 100)"
                  InputLabelProps={{ shrink: !!value }}
                  value={value}
                  type="number"
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  error={!!errors.total}
                  helperText={!!errors.total && "This field is required"}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton type="submit" variant="contained" loading={adding}>
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default TopupModalForm;
