import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import { ProjectResource } from "../../types/project";
import LoadingButton from "@mui/lab/LoadingButton";
import { useGiveBonusMutation } from "../../services/projectJob.service";
import { toast } from "react-toastify";
import SelectSimple from "../../components/SelectSimple";

interface ModalBonusFormProps {
  open: boolean;
  onClose: () => void;
  job: ProjectResource | null;
}

const spendingOptions = [
  { value: "bonus", label: "Bonus" },
  { value: "additional hours", label: "Additional Hours" },
  { value: "reimbursement", label: "Reimbursement" },
];

const ModalBonusForm = ({ open, onClose, job }: ModalBonusFormProps) => {
  const {
    handleSubmit,
    clearErrors,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const type = watch("type");

  const [giveBonus, { isLoading }] = useGiveBonusMutation();

  const onSubmit = async (payload: any) => {
    try {
      if (!job) return;

      await giveBonus({
        project_id: job?.project_id,
        resource_id: job?.resource_id,
        total: payload.total,
        type: payload.type,
        user_comment: payload.user_comment || "",
      }).unwrap();
      toast.success("Other expenses successfully sent! Thank You!");
      onClose();
    } catch (err) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (open) {
      setValue("total", 0);
      setValue("type", "bonus");
      setValue("user_comment", "");
      clearErrors();
    }
  }, [job, open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">Send Other Expenses</DialogTitle>
        <DialogContent>
          <Controller
            name="type"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, ref } }) => (
              <SelectSimple
                title="Select Type"
                data={spendingOptions}
                value={value}
                sx={{ mt: 1 }}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="total"
            control={control}
            rules={{ required: true, min: 1 }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label="Amount ($)"
                value={value}
                fullWidth
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.total}
                helperText={!!errors.total && "Invalid amount"}
              />
            )}
          />
          {type == "reimbursement" && (
            <Controller
              name="user_comment"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Reason"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.user_comment}
                  helperText={!!errors.user_comment && "Required"}
                />
              )}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton type="submit" variant="contained" loading={isLoading}>
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalBonusForm;
