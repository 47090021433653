import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetTalentByIdQuery,
  useGetTalentTotalEarnedByIdQuery,
  useGetTalentTotalJobHiredByIdQuery,
  useGetTalentTotalJobSuccessRateByIdQuery,
} from "../../services/talent.service";
import {
  useGetAllFavoriteTalentsQuery,
  useAddFavoriteTalentMutation,
  useDeleteFavoriteTalentMutation,
} from "../../services/favoriteTalent.service";
import { useTitle } from "../../hooks/useTitle";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import FavoriteIcon from "@mui/icons-material/Favorite";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { Talent, UserWorkStatus } from "../../types/users";
import TalentProfilePanel from "./TalentProfilePanel";
import { formatCash } from "../../utils/helper";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Favorite,
  FavoriteOutlined,
  Info,
  More,
  MoreVert,
} from "@mui/icons-material";

import dayjs from "dayjs";

// declare var ActiveXObject: {
//   new (s: string): any;
// }

const MoreMenu = ({ talent }: { talent?: Talent }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ ml: 1 }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() =>
            navigate(`/independent-professionals/${talent?.id}/reviews`)
          }
        >
          Reviews
        </MenuItem>
      </Menu>
    </div>
  );
};

const ProfileStat = ({
  label,
  value,
  isLoading,
  onClick,
}: {
  label: string;
  value: string;
  isLoading?: any;
  onClick?: () => void;
}) => (
  <Box
    sx={{
      border: "1px solid #ccc",
      borderRadius: 1,
      py: 0.5,
      px: 1,
      alignItems: "center",
      mr: 1,
      bgcolor: "white",
      minWidth: 80,
    }}
    onClick={onClick}
  >
    {isLoading ? (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress size={16} />
      </Box>
    ) : (
      <Typography textAlign={"center"} fontSize={16}>
        {value}
      </Typography>
    )}
    <Typography textAlign={"center"} fontSize={12} color="text.secondary">
      {label}
    </Typography>
  </Box>
);

const ProfileMeta = ({
  icon,
  value,
  onClick,
}: {
  icon: React.ReactNode;
  value: string;
  onClick?: () => void;
}) => (
  <Button
    onClick={onClick}
    variant="text"
    startIcon={icon}
    size="small"
    sx={{ mr: 2, textTransform: "unset", color: "text.secondary" }}
  >
    {value}
  </Button>
);

const ProfileHeader = ({
  data,
  dataTotalJobSuccessRate,
  dataTotalJobHired,
  dataTotalEarned,
  isLoadingTotalJobSuccessRate,
  isLoadingTotalJobHired,
  isLoadingTotalEarned,
  addingFavorite,
  deletingFavorite,
  isFavorite,
  onAddFav,
  onDeleteFav,
}: {
  data?: Talent;
  dataTotalJobSuccessRate: any;
  dataTotalJobHired: any;
  dataTotalEarned: any;
  isLoadingTotalJobSuccessRate: boolean;
  isLoadingTotalJobHired: boolean;
  isLoadingTotalEarned: boolean;
  addingFavorite: boolean;
  deletingFavorite: boolean;
  isFavorite: boolean;
  onAddFav: () => void;
  onDeleteFav: () => void;
}) => {
  const navigate = useNavigate();
  return (
    <Stack direction={"row"} alignItems="center">
      <Avatar
        variant="square"
        sx={{ bgColor: "primary.main", width: 100, height: 100, mr: 2 }}
        // alt={data?.first_name}
        src={
          (!!data?.photo?.length && data?.photo[0].original_url) ||
          "broken-image.jpg"
        }
      />
      <Stack direction="column" flex={1}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h5">{data?.full_name}</Typography>
          <Rating
            value={data?.total_rating || 0}
            readOnly
            sx={{ ml: 2 }}
            precision={0.5}
          />
        </Stack>
        <Stack direction="row" alignItems="center">
          <ProfileMeta
            icon={<PersonPinCircleIcon />}
            value={`${data?.city || "Unknown City"}, ${
              data?.state || "Unknown State"
            }`}
          />
          <ProfileMeta
            icon={<EmailIcon />}
            value={`${data?.email || "No Email"}
          `}
            onClick={() => {
              // try {
              //   var outlookApp = new ActiveXObject('Outlook.Application');
              //   var nameSpace = outlookApp.getNameSpace('MAPI');
              //   var mailFolder = nameSpace.getDefaultFolder(6);
              //   mailFolder.Display();
              // } catch (e) {
              //   window.location.href = `${`mailto:${data?.email}`}`
              // }
              // window.open('https://outlook.office.com/mail/inbox#compose', '_blank');
              window.location.href = `${`mailto:${data?.email}`}`;
            }}
          />
          <ProfileMeta
            icon={<PhoneAndroidIcon />}
            value={`${data?.phone_number || "No Phone"}`}
            onClick={() => {
              // try {
              //   var outlookApp = new ActiveXObject('Outlook.Application');
              //   var nameSpace = outlookApp.getNameSpace('MAPI');
              //   var mailFolder = nameSpace.getDefaultFolder(6);
              //   mailFolder.Display();
              // } catch (e) {
              //   window.location.href = `${`mailto:${data?.email}`}`
              // }
              // window.open('https://outlook.office.com/mail/inbox#compose', '_blank');
              window.location.href = `${`TEL:${data?.phone_number}`}`;
            }}
          />
          <ProfileMeta
            icon={<Info />}
            value={`Member Since: ${dayjs(data?.created_at).format("MM/YY")}`}
          />
        </Stack>
        <Stack direction="row" sx={{ mt: 1 }}>
          <ProfileStat
            label="Rating"
            value={`${+Number(data?.total_rating || 0).toFixed(2)}`}
            onClick={() =>
              navigate(`/independent-professionals/${data?.id}/reviews`)
            }
          />
          <ProfileStat
            label="Work Success"
            value={`${+Number(dataTotalJobSuccessRate?.total || 0).toFixed(
              2
            )}%`}
            isLoading={isLoadingTotalJobSuccessRate}
          />
          <ProfileStat
            label="Work"
            value={`${dataTotalJobHired?.total || 0}`}
            isLoading={isLoadingTotalJobHired}
          />
          <ProfileStat
            label="Total Earned"
            value={`$${formatCash(Number(dataTotalEarned?.total || 0))}`}
            isLoading={isLoadingTotalEarned}
          />
          <ProfileStat
            label="Work Status"
            value={
              data?.talent?.work_status == UserWorkStatus.SOLE_PROPRIETOR
                ? "Sole Proprietor"
                : data?.talent?.work_status == UserWorkStatus.ENTREPRENEUR
                ? "Entrepreneur"
                : data?.talent?.work_status == UserWorkStatus.PART_TIME
                ? "Part Time"
                : "Not Set"
            }
          />
        </Stack>
      </Stack>
      <Stack direction={"row"}>
        <LoadingButton
          variant={isFavorite ? "contained" : "outlined"}
          loading={addingFavorite || deletingFavorite}
          onClick={isFavorite ? onDeleteFav : onAddFav}
          startIcon={<Favorite />}
        >
          {isFavorite ? "Favorited" : "Add as Favorite"}
        </LoadingButton>
        <MoreMenu talent={data} />
      </Stack>
    </Stack>
  );
};

const TalentDetailPage = () => {
  const { talentId } = useParams();

  const [doAddFavorite, { isLoading: addingFavorite }] =
    useAddFavoriteTalentMutation();
  const [doDeleteFavorite, { isLoading: deletingFavorite }] =
    useDeleteFavoriteTalentMutation();

  const handleAddFav = async () => {
    if (talentId) {
      try {
        await doAddFavorite(parseInt(talentId!)).unwrap();
        toast.success("Successfully add as favorite");
      } catch (err: any) {
        toast.error(err);
      }
    }
  };

  const handleDeleteFav = async () => {
    if (favId) {
      try {
        await doDeleteFavorite(favId).unwrap();
        toast.success("Successfully remove from favorite");
      } catch (err: any) {
        toast.error(err);
      }
    }
  };

  const { data } = useGetTalentByIdQuery(parseInt(talentId!), {
    skip: !talentId,
  });

  const { data: favorites } = useGetAllFavoriteTalentsQuery({});

  const { isFavorite, favId } = React.useMemo(() => {
    const found = favorites?.find(
      (item) => item.user.id === parseInt(talentId!)
    );
    return { isFavorite: !!found, favId: found?.id || 0 };
  }, [favorites, talentId]);

  useTitle(
    `Independent Profesional #${data?.id}`,
    `${data?.first_name} ${data?.last_name}`
  );

  const {
    data: dataTotalJobSuccessRate,
    isLoading: isLoadingTotalJobSuccessRate,
  } = useGetTalentTotalJobSuccessRateByIdQuery(parseInt(talentId!), {
    skip: !talentId,
  });

  const { data: dataTotalJobHired, isLoading: isLoadingTotalJobHired } =
    useGetTalentTotalJobHiredByIdQuery(parseInt(talentId!), {
      skip: !talentId,
    });

  const { data: dataTotalEarned, isLoading: isLoadingTotalEarned } =
    useGetTalentTotalEarnedByIdQuery(parseInt(talentId!), {
      skip: !talentId,
    });

  return (
    <Box>
      <ProfileHeader
        data={data}
        dataTotalJobSuccessRate={dataTotalJobSuccessRate}
        dataTotalJobHired={dataTotalJobHired}
        dataTotalEarned={dataTotalEarned}
        isLoadingTotalJobSuccessRate={isLoadingTotalJobSuccessRate}
        isLoadingTotalJobHired={isLoadingTotalJobHired}
        isLoadingTotalEarned={isLoadingTotalEarned}
        addingFavorite={addingFavorite}
        deletingFavorite={deletingFavorite}
        isFavorite={isFavorite}
        onAddFav={handleAddFav}
        onDeleteFav={handleDeleteFav}
      />
      <TalentProfilePanel data={data} />
    </Box>
  );
};
export default TalentDetailPage;
