import * as React from "react";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useTitle } from "../../hooks/useTitle";
import ProjectGrid from "./ProjectGrid";
import ProjectDrawer from "./ProjectDrawer";
import { Divider } from "@mui/material";
import { Project, ProjectStatus } from "../../types/project";
import { useNavigate } from "react-router-dom";
import qs from "qs";
import ProjectGridClient from "./ProjectGridClient";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const ProjectPage = () => {
  useTitle("Work Management");

  const theme = useTheme();
  const [value, setValue] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState(null);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    const params = qs.parse(window.location.href.split("?")[1]);
    console.log(params);
    if (params.t) {
      setValue(Number(params.t));
    }
  }, []);
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        width: "auto",
      }}
    >
      {/* <AppBar position="static"> */}
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label="Draft" {...a11yProps(0)} />
        <Tab label="Open Work" {...a11yProps(1)} />
        <Tab label="Scheduled" {...a11yProps(2)} />
        <Tab label="In-Progress" {...a11yProps(3)} />
        <Tab label="Completed" {...a11yProps(4)} />
        <Tab label="Cancelled" {...a11yProps(5)} />
      </Tabs>
      {/* </AppBar> */}
      <TabPanel value={value} index={0} dir={theme.direction}>
        <ProjectGrid
          status={ProjectStatus.DRAFT}
          onView={(item) => {
            setCurrentItem(item);
            setOpen(true);
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <ProjectGrid
          status={ProjectStatus.PUBLISHED}
          onView={(item) => {
            setCurrentItem(item);
            setOpen(true);
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <ProjectGridClient
          status={ProjectStatus.SCHEDULED}
          onView={(item) => {
            setCurrentItem(item);
            setOpen(true);
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={3} dir={theme.direction}>
        <ProjectGridClient
          status={ProjectStatus.IN_PROGRESS}
          onView={(item) => {
            setCurrentItem(item);
            setOpen(true);
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={4} dir={theme.direction}>
        <ProjectGrid
          status={ProjectStatus.COMPLETED}
          onView={(item) => {
            setCurrentItem(item);
            setOpen(true);
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={5} dir={theme.direction}>
        <ProjectGrid
          status={ProjectStatus.CANCELED}
          onView={(item) => {
            setCurrentItem(item);
            setOpen(true);
          }}
        />
      </TabPanel>
      <ProjectDrawer data={currentItem!} open={open} setOpen={setOpen} />
    </Box>
  );
};
export default ProjectPage;
