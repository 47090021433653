import { ROLE_TALENT_ID } from "../configs/api";
import { Talent } from "../types/users";
import { baseApi, QueryResponse } from "./base";
import { TAG_TYPE_TALENT } from "./tags";

const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [TAG_TYPE_TALENT] });

export const talentApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getTalents: builder.query<
      QueryResponse<Talent>,
      {
        page: number;
        pageSize: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
        load?: string;
        distance?: string;
        skills?: number[];
        city?: string;
        state?: string;
        country?: number;
        business_verticals?: number[];
        certificates?: number[];
        work_status?: string;
      }
    >({
      query: ({
        page,
        pageSize,
        sortField,
        sortMode,
        search,
        load = "country,photo,businessVerticals,languages,skills,certifications.certification,educations,workExperiences,talent",
        distance,
        skills,
        city,
        state,
        country,
        business_verticals,
        certificates,
        work_status,
      }) => ({
        url: "api/talents",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `first_name|asc`,
          // withTrashed: true,
          role: ROLE_TALENT_ID,
          search,
          load,
          distance,
          skills,
          city: city || undefined,
          state: state || undefined,
          country: country || undefined,
          business_verticals,
          certificates,
          work_status: work_status || undefined,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE_TALENT, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE_TALENT, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE_TALENT, id })),
        ];
      },
    }),
    getTalentsAll: builder.query<
      Array<Talent>,
      {
        sortField?: string;
        sortMode?: string | null;
        search?: string;
        load?: string;
        distance?: string;
      }
    >({
      query: ({
        sortField,
        sortMode,
        search,
        load = "photo,languages,skills",
        distance,
      }) => ({
        url: "api/talents",
        method: "GET",
        params: {
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `first_name|asc`,
          // withTrashed: true,
          search,
          load,
          distance,
        },
      }),
      providesTags: (result) => {
        const data = result;
        if (!data) return [{ type: TAG_TYPE_TALENT, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE_TALENT, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE_TALENT, id })),
        ];
      },
    }),
    getTalentById: builder.query<Talent, number>({
      query: (id) => ({
        url: `api/talents/${id}`,
        method: "GET",
        params: {
          load: "country,photo,businessVerticals,languages,skills,certifications.certification,educations,workExperiences,talent",
        },
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE_TALENT, id }],
    }),
    getTalentTotalJobSuccessRateById: builder.query<any, number>({
      query: (id) => ({
        url: `api/talent/${id}/total-success-rate`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "total-success-rate", id }],
    }),
    getTalentTotalJobHiredById: builder.query<any, number>({
      query: (id) => ({
        url: `api/talent/${id}/total-job-hired`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "total-job-hired", id }],
    }),
    getTalentTotalEarnedById: builder.query<any, number>({
      query: (id) => ({
        url: `api/talent/${id}/total-earning`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "total-earning", id }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTalentsQuery,
  useGetTalentsAllQuery,
  useGetTalentByIdQuery,
  useGetTalentTotalJobSuccessRateByIdQuery,
  useGetTalentTotalJobHiredByIdQuery,
  useGetTalentTotalEarnedByIdQuery,
} = talentApi;
