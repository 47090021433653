import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import { ProjectResource } from "../../types/project";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import SelectReason from "../../components/SelectReason";
import { useCancelTalentMutation } from "../../services/projectJob.service";

interface ModalCancelTalentProps {
  open: boolean;
  onClose: () => void;
  job: ProjectResource | null;
}

const ModalCancelTalent = ({ open, onClose, job }: ModalCancelTalentProps) => {
  const {
    handleSubmit,
    clearErrors,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [cancel, { isLoading }] = useCancelTalentMutation();

  const onSubmit = async (payload: any) => {
    try {
      if (!job) return;

      await cancel({
        projectId: job.project_id,
        jobId: job.id,
        reason: payload.reason,
      }).unwrap();
      toast.success("Independent Professional canceled!");
      onClose();
    } catch (err) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (open) {
      setValue("reason", "");
      clearErrors();
    }
  }, [job, open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">
          Remove Independent Professional From Work
        </DialogTitle>
        <DialogContent>
          <Controller
            name="reason"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <SelectReason
                label="Select Cancel Reason"
                onChange={onChange}
                error={!!errors.reason}
                helperText={!!errors.reason && "This field is required"}
                type="cancel-worker"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton type="submit" variant="contained" loading={isLoading}>
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalCancelTalent;
