import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import { ProjectTask } from "../../types/project";
import { Template } from "../../types/template";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddTemplateMutation,
  useEditTemplateMutation,
  useGetTemplateByIdQuery,
} from "../../services/template.service";
import { useTitle } from "../../hooks/useTitle";
import { Controller, useForm } from "react-hook-form";
import useScroll from "../../hooks/useScroll";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import SelectSimple from "../../components/SelectSimple";
import SelectLocation from "../../components/SelectLocation";
import SelectClient from "../../components/SelectClient";
import SelectSkills from "../../components/SelectSkills";
import SelectCertifications from "../../components/SelectCertification";
import ModalTaskForm from "./ModalTaskForm";
import StickyBox from "react-sticky-box";
import { Add, Delete } from "@mui/icons-material";
import LocationModalForm from "../location/LocationModalForm";
import { Location } from "../../types/location";
import { Customer } from "../../types/customer";
import ClientModalForm from "../client/ClientModalForm";
import { useGetProjectByIdQuery } from "../../services/project.service";
import { decode } from "html-entities";

const targetOptions = [
  {
    value: "location",
    label: "Select a Saved Location or add a new one",
  },
  {
    value: "customer",
    label: "Select a Saved Client or add a new one",
  },
  // { value: "new", label: "+ Add New Location" },
];

const pricingOptions = [
  { value: "hourly", label: "Hourly Rate" },
  { value: "fixed", label: "Fixed Rate" },
];

const ProjectTemplateEditPage = () => {
  const { templateId, projectId } = useParams();
  const navigate = useNavigate();

  const [scrollToBasic, basicRef] = useScroll();
  const [scrollToLocation, locationRef] = useScroll();
  const [scrollToSkill, skillRef] = useScroll();
  const [scrollToMore, moreRef] = useScroll();
  const [scrollToCertification, certificationRef] = useScroll();
  const [scrollToDocument, documentRef] = useScroll();
  const [scrollToTask, taskRef] = useScroll();

  const [taskModal, setTaskModal] = React.useState<{
    open: boolean;
    data: ProjectTask | null;
  }>({
    open: false,
    data: null,
  });
  const [newTasks, setNewTasks] = React.useState<Array<ProjectTask>>([]);

  const [locationModal, setLocationModal] = React.useState<{
    open: boolean;
    data: Location | null;
  }>({
    open: false,
    data: null,
  });
  const [clientModal, setClientModal] = React.useState<{
    open: boolean;
    data: Customer | null;
  }>({
    open: false,
    data: null,
  });

  const { data: dataProject, isLoading: loadingProject } =
    useGetProjectByIdQuery(parseInt(projectId!), {
      skip: !projectId,
    });
  const { data: dataTemplate, isLoading: loadingTemplate } =
    useGetTemplateByIdQuery(parseInt(templateId!), {
      skip: !templateId,
    });
  const [add, { isLoading: adding }] = useAddTemplateMutation();
  const [edit, { isLoading: updating }] = useEditTemplateMutation();
  const [target, setTarget] = React.useState("location");

  useTitle(
    !!dataTemplate
      ? "Edit Template"
      : !!dataProject
      ? "Create Template From Work"
      : "Create New Template"
  );

  const data = React.useMemo(() => {
    if (!!dataTemplate) return dataTemplate;
    else if (!!dataProject) return dataProject;
    else return null;
  }, [dataTemplate, dataProject]);

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      if (target == "customer" && !payload.customer) {
        setError("customer", { type: "custom", message: "Invalid" });
        return;
      } else if (target == "location" && !payload.location) {
        setError("location", { type: "custom", message: "Invalid" });
        return;
      }
      if (target == "customer") payload.location = undefined;
      else payload.customer = undefined;

      if (newTasks.length > 0) {
        payload.tasks = [...newTasks];
      }

      if (!data || dataProject) {
        const template: Template = await add(payload).unwrap();
        toast.success("Template created");
        navigate(`/works/templates/${template.id}`, { replace: true });
      } else {
        if (!payload.password) delete payload.password;
        payload.id = data.id;
        await edit(payload).unwrap();
        toast.success("Template updated");
        navigate(`/works/templates/${data.id}`, { replace: true });
      }
    } catch (err: any) {
      toast.error(err);
    }
  };

  const StickyMenu = React.useCallback(
    () => (
      <StickyBox offsetTop={70} offsetBottom={20}>
        <List>
          <ListItem>
            <ListItemButton onClick={scrollToBasic as any}>
              <ListItemText>Basic Information</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={scrollToLocation as any}>
              <ListItemText>Select Work Site</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={scrollToSkill as any}>
              <ListItemText>Select/Search Skills</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={scrollToCertification as any}>
              <ListItemText>Add Certifications (if needed)</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={scrollToTask as any}>
              <ListItemText>Add Tasks (if needed)</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={scrollToMore as any}>
              <ListItemText>Final Step: Additional Information</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </StickyBox>
    ),
    []
  );

  const handleAddTask = (task: ProjectTask) => {
    setTaskModal({ open: false, data: null });
    setNewTasks([...newTasks, task]);
  };
  const handleDeleteTask = (task: ProjectTask) => {
    const newList = newTasks.filter((it) => it.id != task.id);
    setNewTasks(newList);
  };

  React.useEffect(() => {
    setValue("title", decode(data?.title) || "");
    if (!!data && "tags" in data) setValue("tags", data?.tags || "");
    else setValue("tags", "work,template");
    setValue("description", decode(data?.description) || "");
    setValue(
      "confidential_information",
      decode(data?.confidential_information) || ""
    );
    setValue(
      "approximate_hours_to_complete",
      data?.approximate_hours_to_complete || ""
    );
    setValue("location", data?.location_id);
    setValue("customer", data?.customer_id);

    if (!!data?.customer_id) {
      setTarget("customer");
    } else {
      // default on init
      setTarget("location");
    }

    const skillIds = data?.skills?.map((it) => it.id);
    setValue("skills", skillIds || []);

    const certIds = data?.certifications?.map((it) => it.id);
    setValue("certifications", certIds || []);

    setNewTasks(data?.tasks || []);

    setValue("is_photo_required", !!data?.is_photo_required || false);
    setValue(
      "is_final_notes_required",
      !!data?.is_final_notes_required || false
    );
    setValue("tool_required", !!data?.tool_required || false);
    //setValue("covid_vaccine_required", !!data?.covid_vaccine_required || false);
    setValue(
      "background_check_required",
      !!data?.background_check_required || false
    );
    setValue("drug_test_required", !!data?.drug_test_required || false);
    setValue("mile_radius", data?.mile_radius);
    setValue("type", data?.type || "hourly");
    setValue("num_of_talent", data?.num_of_talent || 1);
  }, [data]);

  return (
    <Box>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item lg={3}>
          <StickyMenu />
        </Grid>
        <Grid item lg={9}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              {(loadingProject || loadingTemplate) && <CircularProgress />}
              {/* <Card sx={{ mt: 1 }} ref={basicRef}>
                <CardHeader
                  title="Template Info"
                  titleTypographyProps={{ fontSize: 18, color: "#17903d" }}
                />
                <CardContent>
                  <Controller
                    name="tags"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Keywords"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.tags}
                        helperText={!!errors.tags && "This field is required"}
                      />
                    )}
                  />
                  <FormHelperText>
                    Separate template keywords with a comma
                  </FormHelperText>
                </CardContent>
              </Card> */}
              <Card sx={{ mt: 1 }} ref={basicRef}>
                <CardHeader
                  title="General"
                  titleTypographyProps={{ fontSize: 18, color: "#17903d" }}
                />
                <CardContent>
                  <Controller
                    name="title"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Work ID# - Work Title"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.title}
                        helperText={!!errors.title && "This field is required"}
                      />
                    )}
                  />
                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Please enter Description/Scope of Work"
                        multiline={true}
                        minRows={10}
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.description}
                        helperText={
                          !!errors.description && "This field is required"
                        }
                      />
                    )}
                  />
                  <Controller
                    name="confidential_information"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Confidential Information (optional)"
                        multiline={true}
                        minRows={2}
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <Controller
                    name="num_of_talent"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Number of IPs Required"
                        value={value}
                        fullWidth
                        type={"number"}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.num_of_talent}
                        helperText={
                          !!errors.num_of_talent && "This field is required"
                        }
                      />
                    )}
                  />
                  <Controller
                    name="type"
                    control={control}
                    defaultValue="hourly"
                    render={({ field: { onChange, value } }) => (
                      <SelectSimple
                        title="Payment Type"
                        data={pricingOptions}
                        value={value}
                        onChange={(e) => onChange(e.target.value as string)}
                        sx={{ mt: 2 }}
                      />
                    )}
                  />
                  <Controller
                    name="approximate_hours_to_complete"
                    control={control}
                    defaultValue=""
                    rules={{ required: true, min: 1 }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Estimated Amount of Hours"
                        value={value}
                        type="number"
                        fullWidth
                        onChange={onChange}
                        sx={{ mt: 2 }}
                        onBlur={onBlur}
                        error={!!errors.approximate_hours_to_complete}
                        helperText={
                          !!errors.approximate_hours_to_complete &&
                          "This field is required"
                        }
                      />
                    )}
                  />
                  <Controller
                    name="mile_radius"
                    control={control}
                    defaultValue=""
                    rules={{ required: true, min: 1 }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Worksite Radius (miles)"
                        value={value}
                        type="number"
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.mile_radius}
                        helperText={
                          !!errors.mile_radius
                            ? "This field is required"
                            : "Only available to IPs within radius"
                        }
                      />
                    )}
                  />
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }} ref={locationRef}>
                <CardHeader
                  title="Work Site"
                  titleTypographyProps={{ fontSize: 18, color: "#17903d" }}
                />
                <CardContent>
                  {/* <Controller
                    name="target"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <SelectSimple
                        title="Location Type"
                        data={targetOptions}
                        value={target}
                        onChange={(e) => setTarget(e.target.value as string)}
                        sx={{ mb: 2 }}
                      />
                    )}
                  />
                  {target == "customer" && (
                    <>
                      <Controller
                        name="customer"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <SelectClient
                            label="Select a saved Client"
                            onChange={onChange}
                            value={value}
                            sx={{ mt: 0 }}
                            error={!!errors.customer}
                            helperText={
                              !!errors.customer && "This field is required"
                            }
                          />
                        )}
                      />
                      <Button
                        variant="text"
                        onClick={() =>
                          setClientModal({ open: true, data: null })
                        }
                      >
                        + Add New Client
                      </Button>
                    </>
                  )} */}
                  {target == "location" && (
                    <>
                      <Controller
                        name="location"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <SelectLocation
                            label="Select a saved Location"
                            onChange={onChange}
                            value={value}
                            sx={{ mt: 0 }}
                            error={!!errors.location}
                            helperText={
                              !!errors.location && "This field is required"
                            }
                          />
                        )}
                      />
                      <Button
                        variant="text"
                        onClick={() =>
                          setLocationModal({ open: true, data: null })
                        }
                      >
                        + Add New Location
                      </Button>
                    </>
                  )}
                  {target == "new" && (
                    <Controller
                      name="address"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="Address"
                          value={value}
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                          sx={{ mt: 0 }}
                          error={!!errors.address}
                          helperText={
                            !!errors.address && "This field is required"
                          }
                        />
                      )}
                    />
                  )}
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }} ref={skillRef}>
                <CardHeader
                  title="Required Skills"
                  titleTypographyProps={{ fontSize: 18, color: "#17903d" }}
                />
                <CardContent>
                  <Controller
                    name="skills"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <SelectSkills
                        label="Select/Search Skills"
                        onChange={onChange}
                        values={value}
                        error={!!errors.skills}
                        helperText={!!errors.skills && "This field is required"}
                      />
                    )}
                  />
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }} ref={certificationRef}>
                <CardHeader
                  title="Required Certifications (optional)"
                  titleTypographyProps={{ fontSize: 18, color: "#17903d" }}
                />
                <CardContent>
                  <Box>
                    <Controller
                      name="certifications"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <SelectCertifications
                          label="Select Certifications"
                          onChange={onChange}
                          values={value}
                        />
                      )}
                    />
                  </Box>
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }} ref={taskRef}>
                <CardHeader
                  title="Tasks"
                  titleTypographyProps={{ fontSize: 18, color: "#17903d" }}
                  action={
                    <Button
                      // variant="outlined"
                      onClick={() => setTaskModal({ open: true, data: null })}
                      startIcon={<Add />}
                    >
                      Add Task
                    </Button>
                  }
                />
                <CardContent>
                  <List>
                    {newTasks.map((it, idx) => (
                      <ListItem
                        sx={{ backgroundColor: "#f5f5f5", mt: 1, p: 2 }}
                        secondaryAction={
                          <IconButton
                            edge="end"
                            onClick={() => handleDeleteTask(it)}
                          >
                            <Delete />
                          </IconButton>
                        }
                      >
                        <ListItemText
                          key={idx}
                          primary={it.name}
                          secondary={it.description}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }} ref={moreRef}>
                <CardHeader
                  title="Additional Information"
                  titleTypographyProps={{ fontSize: 18, color: "#17903d" }}
                />
                <CardContent>
                  <Controller
                    name="is_photo_required"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label="Photo(s) Required - IPs must submit photo(s) of their work. Specify details in Scope Of Work"
                      />
                    )}
                  />
                  <br />
                  <Controller
                    name="is_final_notes_required"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label="Final Notes Required - IPs must submit final notes of their work. Specify details in Scope Of Work"
                      />
                    )}
                  />
                  <br />
                  <Controller
                    name="tool_required"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label="Tool(s) Required - IPs need to provide their own tool(s). Specify details in Scope Of Work"
                      />
                    )}
                  />
                  <br />
                  <Controller
                    name="background_check_required"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label="Background check required"
                      />
                    )}
                  />
                  <br />
                  <Controller
                    name="drug_test_required"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label="Drug test required"
                      />
                    )}
                  />
                </CardContent>
              </Card>
              <Stack direction="row" justifyContent={"flex-end"} sx={{ mt: 2 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={loadingTemplate || loadingProject}
                  loading={adding || updating}
                  // sx={{ width: "50%" }}
                >
                  {!!data && !dataProject ? "Update" : "Create"}
                </LoadingButton>
              </Stack>
            </Box>
          </form>
        </Grid>
      </Grid>
      <ModalTaskForm
        open={taskModal.open}
        onClose={() => setTaskModal({ open: false, data: null })}
        data={taskModal.data}
        onAdd={handleAddTask}
      />
      <LocationModalForm
        open={locationModal.open}
        onClose={() => setLocationModal({ open: false, data: null })}
        onCreated={(newId) => setValue("location", newId)}
        data={locationModal.data}
      />
      <ClientModalForm
        open={clientModal.open}
        onClose={() => setClientModal({ open: false, data: null })}
        onCreated={(newId) => setValue("customer", newId)}
        data={clientModal.data}
      />
    </Box>
  );
};
export default ProjectTemplateEditPage;
