import * as React from "react";
import { AddressComponent } from "../types/common";
import { Talent } from "../types/users";
import { Company } from "../types/company";

type Point = {
  lat: number;
  lng: number;
};

export function isProductionMode() {
  return process.env.REACT_APP_MODE == "production";
}

export const isFile = (input: any) => "File" in window && input instanceof File;

export function nl2br(str?: string | null) {
  if (!str) return "";
  // if is no newline char in the string
  if (!str.match("\n")) {
    return str;
  }

  const fragments = str.split("\n");
  const fragmentsCount = fragments.length;

  return fragments.reduce((res, line, i) => {
    if (line !== "") {
      //@ts-ignore
      res.push(React.createElement(React.Fragment, { key: i, children: line }));
    }

    // in the last fragment not display <br>
    if (i + 1 !== fragmentsCount) {
      //@ts-ignore
      res.push(React.createElement("br", { key: `${i}br` }));
    }

    return res;
  }, []);
}

export const getAddressComponent = (
  placeResult: google.maps.places.PlaceResult
): AddressComponent => {
  const address_components = placeResult.address_components;
  let components: any = {
    street_number: ["street_number"],
    postal_code: ["postal_code"],
    street: ["street_address", "route"],
    state: [
      "administrative_area_level_1",
      "administrative_area_level_2",
      "administrative_area_level_3",
      "administrative_area_level_4",
      "administrative_area_level_5",
    ],
    city: [
      "locality",
      "sublocality",
      "sublocality_level_1",
      "sublocality_level_2",
      "sublocality_level_3",
      "sublocality_level_4",
    ],
    country: ["country"],
  };

  let address: AddressComponent = {
    street_number: "",
    postal_code: "",
    street: "",
    state: "",
    city: "",
    country: "",
  };

  address_components?.forEach((component) => {
    for (let comp in components) {
      if (components[comp].indexOf(component.types[0]) !== -1) {
        (address as any)[comp] = component.long_name;

        if (comp == "state")
          (address as any)[comp] = component.short_name || component.long_name;
        if (comp == "country") (address as any)[comp] = component.short_name;
      }
    }
  });
  return address;
};

export const getAddressComponentFromGeocoder = (
  address_components: google.maps.GeocoderAddressComponent[]
): AddressComponent => {
  // const address_components = placeResult.address_components;
  let components: any = {
    street_number: ["street_number"],
    postal_code: ["postal_code"],
    street: ["street_address", "route"],
    state: [
      "administrative_area_level_1",
      "administrative_area_level_2",
      "administrative_area_level_3",
      "administrative_area_level_4",
      "administrative_area_level_5",
    ],
    city: [
      "locality",
      "sublocality",
      "sublocality_level_1",
      "sublocality_level_2",
      "sublocality_level_3",
      "sublocality_level_4",
    ],
    country: ["country"],
  };

  let address: AddressComponent = {
    street_number: "",
    postal_code: "",
    street: "",
    state: "",
    city: "",
    country: "",
  };

  address_components?.forEach((component) => {
    for (let comp in components) {
      if (components[comp].indexOf(component.types[0]) !== -1) {
        (address as any)[comp] = component.long_name;

        if (comp == "state")
          (address as any)[comp] = component.short_name || component.long_name;
        if (comp == "country") (address as any)[comp] = component.short_name;
      }
    }
  });
  return address;
};

export const formatCash = (n: number) => {
  if (n < 1e3) return +n.toFixed(1);
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export const formatMoney = (n: number) => {
  const fixedNum = +n.toFixed(2);
  return fixedNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function ucFirstAllWords(str: string): string {
  var pieces = str.split(" ");
  for (var i = 0; i < pieces.length; i++) {
    var j = pieces[i].charAt(0).toUpperCase();
    pieces[i] = j + pieces[i].substr(1);
  }
  return pieces.join(" ");
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function degToRadians(deg: number): number {
  return deg * (Math.PI / 180);
}

export function getPrivateCompanyName(talent: Talent) {
  const lastName = talent.last_name;
  if (lastName?.endsWith("]")) {
    const privateCompanyName = lastName.split("[")[1].replace("]", "");
    return privateCompanyName;
  }
}

export function filterPrivateTalent(
  talents: Talent[] | undefined,
  company: Company | undefined
) {
  return talents?.filter((talent) => {
    if (talent.last_name?.endsWith("]")) {
      if (
        company?.name.toLowerCase() !==
        getPrivateCompanyName(talent)?.toLowerCase()
      ) {
        return false;
      }
    }
    return true;
  });
}
