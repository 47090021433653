import { Coordinate } from "./common";
import { Photo } from "./photo";

export enum CompanyStatus {
  PENDING = "pending",
  ACTIVE = "active",
  INACTIVE = "in-active",
}

export interface Company {
  id: number;
  status: CompanyStatus;
  business_vertical_id: number;
  name: string;
  city: string;
  state: string;
  zip: string;
  logo: Array<Photo>;
  headquarters_address_1: string;
  headquarters_address_2: string;
  billing_country_id: number;
  billing_address_1: string;
  billing_address_2: string;
  billing_city: string;
  billing_state: string;
  billing_zip: string;
  billing_email: string;
  balance_decrypt: number;
  percentage_uplift: string;
  color_schema: string;
  phone_number: string;
  fax: string;
  website: string;
  linked_in: string;
  facebook: string;
  instagram: string;
  primary_contact_name: string;
  primary_contact_title: string;
  primary_contact_phone_number: string;
  primary_contact_email: string;
  coordinate: Coordinate;
  created_at: Date;
  updated_at: Date;
}
