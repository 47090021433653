import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import dayjs from "dayjs";
import {
  ProjectResource,
  ReportProgress,
  ReportProgressStatus,
} from "../../../types/project";
import { ucFirstAllWords } from "../../../utils/helper";

const LogModal = ({
  open,
  onClose,
  job,
}: {
  open: boolean;
  onClose: () => void;
  job: ProjectResource | null;
}) => {
  const sorted = React.useMemo(
    () =>
      job?.report_progresses
        ?.slice()
        .sort(
          (a: ReportProgress, b: ReportProgress) =>
            new Date(a.check_in).getTime() - new Date(b.check_in).getTime()
        ),
    [job]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">Log</DialogTitle>
      <DialogContent>
        <ul>
          <li>
            Hired at {dayjs(job?.hired_at).format("MMM DD, YYYY hh:mm A z")}
          </li>
          {sorted?.map((it) => (
            <>
              <li>
                {"Working from "}
                {dayjs(it.check_in).format("MMM DD, YYYY hh:mm A z")} {" → "}
                {!!it.check_out
                  ? dayjs(it.check_out).format("MMM DD, YYYY hh:mm A z")
                  : "-"}
              </li>
              {!!it.status && (
                <>
                  {/* <li>Work Submitted</li> */}
                  <li>
                    {it.status == ReportProgressStatus.SUBMITTED
                      ? "IP Submit Work Result"
                      : it.status == ReportProgressStatus.APPROVED
                      ? "Work Result Approved"
                      : "Work Result Rejected"}
                  </li>
                </>
              )}
            </>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
export default LogModal;
