import { Certification } from "../types/certification";
import { baseApi } from "./base";
import { TAG_TYPE_CERTIFICATION } from "./tags";

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_CERTIFICATION],
});

export const certApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getCertifications: builder.query<Array<Certification>, void>({
      query: () => ({
        url: "api/certifications",
        method: "GET",
        params: {
          sort: `name|asc`,
        },
      }),
      providesTags: (result) => [{ type: TAG_TYPE_CERTIFICATION, id: "LIST" }],
      keepUnusedDataFor: 300,
    }),
  }),
  overrideExisting: false,
});

export const { useGetCertificationsQuery } = certApi;
