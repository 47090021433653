import * as React from "react";
import {
  Box,
  Button,
  Chip,
  Link,
  Paper,
  Rating,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Talent } from "../../../types/users";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import TalentDrawer from "../../talent/TalentDrawer";
import {
  useAcceptBidMutation,
  useHireTalentMutation,
} from "../../../services/projectJob.service";
import dayjs from "dayjs";
import {
  NegotiationStatus,
  Project,
  ProjectResource,
  ProjectStatus,
} from "../../../types/project";
import ConfirmModal from "../../../components/ConfirmModal";
import { toast } from "react-toastify";
import { getTalentBidStatus } from "../../../utils/projectHelper";
import ModalInvite from "../ModalInvite";
import { formatWithTimezone } from "../../../utils/dateHelper";

const TableCancel = ({
  project,
  bids,
}: {
  project: Project;
  bids: Array<ProjectResource>;
}) => {
  const canceledJobs = bids.filter(
    (bid) => bid.status == NegotiationStatus.CANCELED
  );
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const user = useCurrentUser();
  const [selectedTalent, setSelectedTalent] = React.useState<Talent | null>(
    null
  );
  const [hireModal, setHireModal] = React.useState<{
    visible: boolean;
    job: ProjectResource | null;
  }>({
    visible: false,
    job: null,
  });
  const [showModalInvite, setShowModalInvite] = React.useState(false);
  const [showFav, setShowFav] = React.useState(false);

  const [showTable, setShowTable] = React.useState(
    project.status == ProjectStatus.PUBLISHED
  );
  const isHourly = React.useMemo(() => project?.type == "hourly", [project]);

  const [doHire] = useHireTalentMutation();
  const [doAccept] = useAcceptBidMutation();

  const isAcceptingBid = React.useMemo(
    () => project.status == ProjectStatus.PUBLISHED,
    [project]
  );

  const handleHire = async () => {
    try {
      const job = hireModal.job;
      if (!job) {
        toast.error("Invalid Work");
        return;
      }
      if (job.status == NegotiationStatus.NEW) {
        await doAccept({ projectId: project.id, jobId: job.id }).unwrap();
      }
      if (isHourly)
        await doHire({
          projectId: project.id,
          jobId: job.id,
          rate: job.rate,
          max_hour: job.max_hour,
        }).unwrap();
      else
        await doHire({
          projectId: project.id,
          jobId: job.id,
          price: job.price,
        }).unwrap();
    } catch (err: any) {
      toast.error(err);
    }
  };

  return (
    <Box>
      <Stack direction={"row"} alignItems="center" sx={{ mb: 1 }}>
        <Typography sx={{ fontSize: 16, fontWeight: "500", flex: 1 }}>
          Cancelled Offers{" "}
          <Button onClick={() => setShowTable(!showTable)} size="small">
            {showTable ? "Hide" : "Show"}
          </Button>
        </Typography>
        {/* {isAcceptingBid && (
          <Button variant="outlined" onClick={() => setShowModalInvite(true)}>
            + Invite Talent
          </Button>
        )} */}
      </Stack>
      {showTable && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#eee" }}>
                <TableCell>Independent Professional</TableCell>
                <TableCell>Price</TableCell>
                {isHourly && <TableCell>Max Hour</TableCell>}
                <TableCell>Start</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Updated</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {canceledJobs?.length == 0 && (
                <TableRow>
                  <TableCell colSpan={6}>No offers, yet</TableCell>
                </TableRow>
              )}
              {canceledJobs?.map((job) => {
                const status = getTalentBidStatus(job.status);
                return (
                  <TableRow
                    key={job.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell width={250}>
                      <Stack direction={"row"} alignItems="center">
                        <Box>
                          <Stack direction={"row"} alignItems="center">
                            <Link
                              onClick={() => {
                                setSelectedTalent(job.resource || null);
                                setDrawerOpen(true);
                              }}
                              sx={{ fontSize: 16 }}
                            >
                              {job.resource?.full_name}
                            </Link>
                            <Rating
                              value={job.resource?.total_rating}
                              readOnly
                              precision={0.5}
                              size={"small"}
                              sx={{ ml: 1 }}
                            />
                          </Stack>
                          <Typography variant="caption">
                            {job.resource?.city}, {job.resource?.state}{" "}
                            {job.resource?.zip}
                          </Typography>
                        </Box>
                      </Stack>
                    </TableCell>
                    <TableCell width={100}>
                      {job.status == NegotiationStatus.INVITED
                        ? "n/a"
                        : isHourly
                        ? `${job.rate} $/hr`
                        : `${job.price} $`}
                    </TableCell>
                    {isHourly && (
                      <TableCell width={120}>{job.max_hour} hr</TableCell>
                    )}
                    <TableCell width={150}>
                      🕗 {formatWithTimezone(job.start_date, job.timezone)}
                    </TableCell>
                    <TableCell width={150}>
                      <Chip
                        label={status.label}
                        color={status.color}
                        size="small"
                      />
                      {status.label == "CANCELED" && (
                        <Typography variant="caption">
                          <br /> {job.canceled_reason}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell width={225}>
                      {dayjs(job.updated_at).format("MMM DD, YYYY hh:mm A z")}
                    </TableCell>
                    <TableCell align="right" width={225}>
                      {isAcceptingBid &&
                        job.status != NegotiationStatus.INVITED &&
                        job.status != NegotiationStatus.CANCELED && (
                          <>
                            <Button
                              variant="contained"
                              onClick={() =>
                                setHireModal({ visible: true, job })
                              }
                            >
                              Hire
                            </Button>
                            <Button
                              variant="outlined"
                              sx={{ ml: 1 }}
                              onClick={() => {
                                window.location.href = `${`mailto:${
                                  job?.resource?.email
                                }?subject=Counter Offer from "${
                                  user?.company.name
                                }" for Work "${project.title}"!&body=${
                                  user?.company.name
                                } will like to counter offer your bid of $${
                                  project.type === "hourly"
                                    ? job.rate
                                    : job.price
                                } to: $
                              %0D%0DIf you agree to our counter offer, please change your bid directly in the app.
                              %0D%0DKind Regards,
                              %0D%0D%0D"${user?.company.name}"
                              %0D${project?.manager?.first_name} ${
                                  project?.manager?.last_name
                                }
                              %0D`}`;
                              }}
                            >
                              Negotiate
                            </Button>
                          </>
                        )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TalentDrawer
        forceRefresh={true}
        data={selectedTalent}
        open={drawerOpen}
        setOpen={setDrawerOpen}
      />
      <ConfirmModal
        open={hireModal.visible}
        title={`Hire ${hireModal?.job?.resource?.first_name || ""} ${
          hireModal?.job?.resource?.last_name || ""
        }?`}
        onClose={() => setHireModal({ visible: false, job: null })}
        onConfirm={handleHire}
      />
      <ModalInvite
        open={showModalInvite}
        onClose={() => {
          setShowFav(false);
          setShowModalInvite(false);
        }}
        project={project}
        showFav={showFav}
        setShowFav={setShowFav}
      />
    </Box>
  );
};
export default TableCancel;
