import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  colors,
} from "@mui/material";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import FaceIcon from "@mui/icons-material/Face";
import { Talent } from "../../types/users";
import { useTitle } from "../../hooks/useTitle";
import TalentDrawer from "./TalentDrawer";
import {
  Circle,
  GoogleMap,
  useLoadScript,
  OverlayView,
  Marker,
} from "@react-google-maps/api";
import { GMAP_API_KEY } from "../../configs/api";
import { useCompany } from "../../hooks/useCompany";
import {
  useGetTalentsAllQuery,
  useGetTalentsQuery,
} from "../../services/talent.service";
import { getDistanceBetweenPoints } from "../../utils/mapHelper";
import { useAppSelector } from "../../hooks/store";
import { RootState } from "../../app/store";
import { FilterAlt, FilterAltOutlined } from "@mui/icons-material";
import ModalTalentFilter from "./ModalTalentFilter";
import { GridSortModel } from "@mui/x-data-grid";

const mapContainerStyle = {
  width: "100%",
  height: "85vh",
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
  clickableIcons: false,
};

const CustomMarker = (props: any) => {
  const { name, photo, onClick } = props;

  return (
    <OverlayView {...props}>
      <Box sx={{ marginTop: -5.5, marginLeft: -2.5 }}>
        <Avatar alt={name} src={photo?.[0]?.original_url} onClick={onClick} />
      </Box>
    </OverlayView>
  );
};

const DEFAULT_ZOOM = 4.3; // zoom out to show all US
const DEFAULT_RADIUS = 11000; //m --> changed according to DEFAULT_ZOOM
const mapLibraries = ["geometry"];

const TalentsMapPage = () => {
  useTitle("Independent Professionals Map");
  const company = useCompany();
  const [showFilter, setShowFilter] = React.useState(false);
  // const [center, setCenter] = React.useState({
  //   lat: company?.coordinate?.coordinates[1] || 0,
  //   lng: company?.coordinate?.coordinates[0] || 0,
  // });
  const [center, setCenter] = React.useState({
    // central coordinate for United States
    lat: 37.0902,
    lng: -95.7129,
  });
  const [searchParam, setSearchParam] = React.useState({
    lat: company?.coordinate?.coordinates[1] || 0,
    lng: company?.coordinate?.coordinates[0] || 0,
    radius: DEFAULT_RADIUS,
  });

  // const { data, isFetching } = useGetTalentsAllQuery({
  //   distance: `${searchParam.lat}|${searchParam.lng}|${searchParam.radius}`,
  // });

  const searchFilter = useAppSelector(
    (state: RootState) => state.ui.talentFilter
  );
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );
  const { data, isLoading, isFetching } = useGetTalentsQuery({
    page: 0,
    pageSize: 999,
    distance: `${searchParam.lat}|${searchParam.lng}|${searchParam.radius}`,
    sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
    sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
    load: "country,photo,skills,languages",
    search: searchQuery,
    ...searchFilter,
  });

  // ?sort=first_name%7Casc&load=photo%2Clanguages%2Cskills&distance=29.7246822%7C-95.5564777%7C11000
  const [open, setOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState<Talent | null>(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GMAP_API_KEY,
    //@ts-ignore
    libraries: mapLibraries,
  });

  const filterOn = React.useMemo(() => {
    return (
      (Array.isArray(searchFilter.skills) && searchFilter.skills.length > 0) ||
      !!searchFilter.state ||
      !!searchFilter.city ||
      !!searchFilter.business_verticals ||
      !!searchFilter.work_status
    );
  }, [searchFilter]);

  const mapRef = React.useRef<google.maps.Map>();

  const onMapLoad = React.useCallback(
    // @ts-ignore
    (map) => {
      map.setCenter(center);
      mapRef.current = map;
    },
    [center]
  );

  const getRadius = React.useCallback(() => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      if (!bounds) return null;

      // computeDistanceBetween returns meters
      const nsRadius = google.maps.geometry.spherical.computeDistanceBetween(
        bounds.getCenter().toJSON(),
        {
          lat: bounds.getNorthEast().toJSON().lat,
          lng: bounds.getCenter().toJSON().lng,
        }
      );
      // console.log(`nsRadius: ${nsRadius.toFixed(2)}`);
      const ewRadius = google.maps.geometry.spherical.computeDistanceBetween(
        bounds.getCenter().toJSON(),
        {
          lat: bounds.getCenter().toJSON().lat,
          lng: bounds.getNorthEast().toJSON().lng,
        }
      );
      const radius = nsRadius >= ewRadius ? nsRadius : ewRadius;
      return Number(radius.toFixed(0));
    }
  }, [mapRef.current]);

  const onDragEnd = React.useCallback(() => {
    if (!mapRef.current) return;
    const lat = mapRef.current?.getCenter()?.lat() || 0;
    const lng = mapRef.current?.getCenter()?.lng() || 0;
    const dist = getDistanceBetweenPoints(
      { lat, lng },
      { lat: center.lat, lng: center.lng }
    );
    if (dist >= 1) {
      // if center changed more than 1 mile
      setCenter({ lat, lng });
      setSearchParam({ lat, lng, radius: getRadius() || DEFAULT_RADIUS });
    }
  }, [getRadius]);

  const onZoomChanged = React.useCallback(() => {
    if (!mapRef.current) return;
    const lat = mapRef.current?.getCenter()?.lat() || 0;
    const lng = mapRef.current?.getCenter()?.lng() || 0;
    setCenter({ lat, lng });
    setSearchParam({ lat, lng, radius: getRadius() || DEFAULT_RADIUS });
  }, [getRadius]);

  const handleMarkerClick = (item: Talent) => {
    setCurrentItem(item);
    setOpen(true);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <Box>
      <Button
        variant={filterOn ? "contained" : "outlined"}
        onClick={() => setShowFilter(true)}
        sx={{ mb: 1 }}
        style={{
          width: 150,
        }}
      >
        {filterOn ? (
          <FilterAlt
            style={{
              color: colors.common.black,
            }}
          />
        ) : (
          <FilterAltOutlined />
        )}{" "}
        Filter {filterOn ? "ON" : ""}
      </Button>
      <Box
        sx={{
          bgcolor: "background.paper",
          width: "auto",
          boxShadow: 1,
          height: "85vh",
        }}
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          // center={center}
          zoom={DEFAULT_ZOOM}
          options={options}
          onDragEnd={onDragEnd}
          onZoomChanged={onZoomChanged}
          onLoad={onMapLoad}
        >
          <React.Fragment>
            <Box
              sx={{
                position: "absolute",
                top: 10,
                left: 10,
                zIndex: 1,
              }}
            >
              {isFetching ? (
                <Chip
                  icon={<CircularProgress size={20} />}
                  label={`Searching Independent Professionals...`}
                />
              ) : (
                <>
                  {!data?.data.length ? (
                    <Chip
                      label={`IPs not found, try move to another location or change zoom level or please reach out to Workz360 support at info@workz360.com`}
                      color="warning"
                    />
                  ) : (
                    <Chip
                      icon={<FaceIcon />}
                      label={`${data?.data.length} IPs found`}
                      color="info"
                    />
                  )}
                </>
              )}
            </Box>
            {/* @ts-ignore */}
            <OverlayView
              key={"#company-marker"}
              position={{
                lat: company?.coordinate?.coordinates[1] || 0,
                lng: company?.coordinate?.coordinates[0] || 0,
              }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <Box sx={{ marginTop: -2.5, marginLeft: -2.5 }}>
                <Chip
                  icon={<HomeRepairServiceIcon />}
                  label={`${company?.name}`}
                  color="success"
                />
              </Box>
            </OverlayView>

            {/*
             * for debuging change circle component propreties visible=true
             */}
            {/* <Circle
            center={center} // required
            radius={searchParam.radius} // required
            options={{
              strokeColor: colors.red[400],
              fillColor: colors.red[400],
            }}
            visible={true}
          /> */}

            {/* {data?.data.map((talent) => (
            <CustomMarker
              id={talent.id}
              key={`#talent-marker-${talent.id}`}
              name={talent.full_name}
              photo={talent.photo}
              position={{
                lat: talent.coordinate?.coordinates[1] || 0,
                lng: talent.coordinate?.coordinates[0] || 0,
              }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              onClick={() => handleMarkerClick(talent)}
            />
          ))} */}
            {data?.data.map((talent) => {
              return (
                <Marker
                  icon={{
                    url: "/talent-marker.png",
                    scaledSize: new window.google.maps.Size(20, 35),
                  }}
                  key={`#talent-marker-${talent.id}`}
                  position={{
                    lat: talent.coordinate?.coordinates[1] || 0,
                    lng: talent.coordinate?.coordinates[0] || 0,
                  }}
                  // mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  onClick={() => handleMarkerClick(talent)}
                />
              );
            })}
          </React.Fragment>
        </GoogleMap>
        <TalentDrawer data={currentItem!} open={open} setOpen={setOpen} />
        <ModalTalentFilter
          open={showFilter}
          onClose={() => setShowFilter(false)}
        />
      </Box>
    </Box>
  );
};
export default TalentsMapPage;
