import * as React from "react";
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  Link,
  Stack,
  Chip,
  Grid,
  CardContent,
  colors,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Project,
  ProjectStatus,
  ReportProgress,
  ReportProgressStatus,
} from "../../types/project";
import { Place } from "@mui/icons-material";
import { formatWithTimezone } from "../../utils/dateHelper";

const ProjectNewsPanel = ({
  data,
  isLoading,
  emptyText,
}: {
  data: Project[];
  isLoading: boolean;
  emptyText?: string;
}) => {
  const navigate = useNavigate();
  const [waitingApprovalJobs, setWaitingApprovalJobs] = React.useState<
    Project[]
  >([]);
  const inProgressJobs = data?.filter(
    (project: Project) => project.status === ProjectStatus.IN_PROGRESS
  );

  React.useMemo(() => {
    const resultList: Project[] = [];
    inProgressJobs?.filter((inProgressJob) => {
      return inProgressJob.resources
        ?.filter((resource) => !!resource.hired_at)
        .map((hired) => {
          hired.report_progresses?.slice().map((report_progress) => {
            if (
              report_progress.status === ReportProgressStatus.SUBMITTED ||
              report_progress.status === ReportProgressStatus.APPROVED
            ) {
              resultList.push(inProgressJob);
            }
          });
        });
    });
    setWaitingApprovalJobs([...resultList]);
  }, [data]);

  if (isLoading)
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress />
      </Box>
    );

  if (waitingApprovalJobs && waitingApprovalJobs?.length == 0)
    return (
      <Box>
        <CardContent>
          <Typography>
            {emptyText ?? "No Projects in Waiting Approval"}
          </Typography>
        </CardContent>
      </Box>
    );

  return (
    <Box sx={{ background: `linear-gradient(${colors.green[100]}44, #fff)` }}>
      <List>
        {waitingApprovalJobs?.map((job, index) => {
          const hiredTalents = job.resources?.filter(
            (resource) => !!resource.hired_at
          );
          return hiredTalents?.map((hiredTalent) => {
            const sorted = hiredTalent.report_progresses
              ?.slice()
              .sort(
                (a: ReportProgress, b: ReportProgress) =>
                  new Date(b.check_in).getTime() -
                  new Date(a.check_in).getTime()
              );
            const submittedJob =
              sorted?.[0].status === ReportProgressStatus.SUBMITTED;
            const approvedJob =
              sorted?.[0].status === ReportProgressStatus.APPROVED;
            return (
              <div key={job.id}>
                <ListItem>
                  <ListItemText
                    style={{ alignItems: "flex-start" }}
                    primary={
                      <Link
                        style={{ textAlign: "left", fontWeight: 500 }}
                        onClick={() => navigate(`/works/${job.id}?t=1`)}
                        component="button"
                        variant="body2"
                      >
                        #{job.id} - {job.title}
                      </Link>
                    }
                    secondary={
                      <>
                        <Place fontSize="inherit" color="disabled" />{" "}
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="caption"
                        >
                          {!!job.customer
                            ? `${job.customer.first_name} ${job.customer.last_name}`
                            : !!job.location
                            ? job.location.name
                            : "?"}
                        </Typography>
                      </>
                    }
                  />
                  <Grid sm={2}>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography variant="caption" sx={{ fontSize: 13 }}>
                        {`MANAGER NAME: `}
                      </Typography>
                      <Typography variant="caption">
                        {`${job.manager?.full_name}`}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid sm={2}>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography variant="caption" sx={{ fontSize: 13 }}>
                        {`INDEPENDENT PROFESSIONAL:`}
                      </Typography>
                      <Typography variant="caption">
                        {hiredTalent.resource?.full_name}
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid sm={3}>
                    <Stack sx={{ alignItems: "center" }}>
                      {submittedJob && (
                        <Chip
                          label={"WAITING FOR WORK APPROVAL"}
                          size="small"
                          color={"info"}
                        />
                      )}
                      {approvedJob && (
                        <Chip
                          label={"WAITING FOR WORK COMPLETION"}
                          size="small"
                          color={"success"}
                        />
                      )}

                      <Typography variant="caption" color="text.secondary">
                        {formatWithTimezone(
                          job.start_date,
                          job.timezone,
                          "ddd, MMM D YYYY - h:mmA z"
                        )}
                      </Typography>
                    </Stack>
                  </Grid>
                </ListItem>
                {index < waitingApprovalJobs.length - 1 && (
                  <Divider component="li" />
                )}
              </div>
            );
          });
        })}
      </List>
    </Box>
  );
};
export default ProjectNewsPanel;
