import React from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormControlLabel,
  LinearProgress,
  Link,
  Paper,
  Rating,
  Stack,
  TextField,
  Typography,
  colors,
  Box,
  Tooltip,
} from "@mui/material";
import useDebounce from "../../hooks/useDebounce";
import { useGetTalentsQuery } from "../../services/talent.service";
import { useGetFavoriteTalentsQuery } from "../../services/favoriteTalent.service";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
  GridToolbarContainer,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { Talent, FavoriteTalent, User } from "../../types/users";
import NoResultsOverlay from "../../components/NoResultOverlay";
import { Project } from "../../types/project";
import ModalTalentDetail from "./ModalTalentDetail";
import { useInviteTalentMutation } from "../../services/projectJob.service";
import { toast } from "react-toastify";
import { useGetProjectBidsQuery } from "../../services/projectJob.service";
import { useCompany } from "../../hooks/useCompany";
import { filterPrivateTalent, getPrivateCompanyName } from "../../utils/helper";
import { formatWithTimezone } from "../../utils/dateHelper";
import { getDistanceBetweenPoints } from "../../utils/mapHelper";
import { USStates } from "../../components/SelectState";

interface ModalInviteProps {
  open: boolean;
  onClose: () => void;
  project: Project;
  showFav: boolean;
  setShowFav: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalInvite = ({
  open,
  onClose,
  project,
  showFav,
  setShowFav,
}: ModalInviteProps) => {
  const company = useCompany();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);

  const [talentModal, setTalentModal] = React.useState<{
    visible: boolean;
    talent: Talent | null;
  }>({
    visible: false,
    talent: null,
  });

  const { data: bids, refetch } = useGetProjectBidsQuery(project.id, {
    skip: !open,
  });

  const {
    data: dataTalent,
    isLoading: isLoadingTalent,
    isFetching: isFetchingTalent,
  } = useGetTalentsQuery(
    {
      page,
      pageSize,
      sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
      sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
      search: searchQuery,
      load: "user,user.photo,photo,talent,business_verticals,skills",
      skills: project.skills?.map((it) => it.id),
      state:
        USStates.find((it) => it.label == project.location?.state)?.value ||
        project.location?.state,
      // city: project.location?.city,
    },
    {
      skip: !open,
    }
  );
  const { data: favTalents } = useGetFavoriteTalentsQuery(
    {
      page: 0,
      pageSize: 1000,
    },
    {
      skip: !open,
    }
  );
  const [doInvite, { isLoading: isInviting }] = useInviteTalentMutation();

  const rowTalents: Talent[] | undefined = React.useMemo(() => {
    let data = dataTalent?.data;
    if (!!showFav) {
      data = data?.filter(
        (it) => !!favTalents?.data.find((x) => x.user.id == it.id)
      );
    }
    const sorted = data?.slice().sort((a: User, b: User) => {
      if (
        a.city === project.location?.city &&
        b.city !== project.location?.city
      ) {
        return -1;
      } else if (
        a.city !== project.location?.city &&
        b.city === project.location?.city
      ) {
        return 1;
      } else {
        // For other cities or if both are project city, maintain original order
        return 0;
      }
    });
    return filterPrivateTalent(sorted, company);
  }, [dataTalent, favTalents, showFav, project]);

  const handleInvite = React.useCallback(
    async (resource_id: number) => {
      try {
        await doInvite({ project_id: project.id, resource_id }).unwrap();
      } catch (err: any) {
        toast.error(err);
      }
    },
    [project]
  );

  const columnTalents: GridColDef[] = React.useMemo(
    () => [
      {
        field: "first_name",
        headerName: "Independent Professional",
        flex: 1,
        sortable: true,
        filterable: true,
        renderCell: (params: GridRenderCellParams<undefined, Talent>) => (
          <Stack direction={"row"}>
            <Avatar
              alt={`${params.row.first_name}`}
              src={`${params.row?.photo?.[0]?.original_url}`}
            />
            <Stack
              sx={{ ml: 1 }}
              direction={"column"}
              justifyContent={"center"}
            >
              <Link
                component="button"
                variant="body1"
                onClick={() =>
                  setTalentModal({ visible: true, talent: params.row })
                }
              >
                {params.row.full_name}
              </Link>
              {favTalents?.data?.find((it) => it.user.id == params.row.id) !=
                undefined && (
                <Typography variant="body2">♥️ Favorited</Typography>
              )}
            </Stack>
          </Stack>
        ),
      },
      {
        field: "contact",
        headerName: "Phone / Email",
        minWidth: 250,
        sortable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<undefined, Talent>) => (
          <Box>
            <Typography variant="body2">
              📞 {params.row.phone_number}
            </Typography>
            <Typography variant="caption">{params.row.email}</Typography>
          </Box>
        ),
      },
      {
        field: "rating",
        headerName: "Rating",
        minWidth: 150,
        sortable: true,
        filterable: true,
        renderCell: (params: GridRenderCellParams<undefined, Talent>) => (
          <Rating value={params.row.total_rating} readOnly precision={0.5} />
        ),
      },
      {
        field: "skills",
        headerName: "Matched Skills",
        minWidth: 120,
        sortable: false,
        filterable: false,
        align: "center",
        renderCell: (params: GridRenderCellParams<undefined, Talent>) => {
          const matched = project.skills?.filter(
            (it) => params.row.skills.find((s) => s.id == it.id) != undefined
          );
          return (
            <Tooltip arrow title={matched?.map((it) => it.name).join(", ")}>
              <Typography variant="button">{matched?.length}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "min_pay",
        headerName: "Rate ($)",
        minWidth: 100,
        sortable: true,
        filterable: false,
        valueGetter: (params: GridValueGetterParams<undefined, Talent>) =>
          `${params.row.min_pay || "?"} - ${params.row.max_pay || "?"}`,
      },
      // {
      //   field: "coordinate",
      //   headerName: "Distance to Site",
      //   minWidth: 150,
      //   sortable: true,
      //   filterable: false,
      //   valueGetter: (params: GridValueGetterParams<undefined, Talent>) => {
      //     const dist = getDistanceBetweenPoints(
      //       {
      //         lat: project.location?.coordinate?.coordinates[1] || 0,
      //         lng: project.location?.coordinate?.coordinates[0] || 0,
      //       },
      //       {
      //         lat: params.row.coordinate?.coordinates[1] || 0,
      //         lng: params.row.coordinate?.coordinates[0] || 0,
      //       }
      //     );
      //     return `${Number(dist).toFixed(1)} miles`;
      //   },
      // },
      {
        field: "city",
        headerName: "City",
        minWidth: 150,
        sortable: true,
        filterable: true,
      },
      {
        field: "state",
        headerName: "State",
        minWidth: 100,
        sortable: true,
        filterable: true,
      },
      {
        field: "actions",
        headerName: "Action",
        renderCell: (params: GridValueGetterParams<undefined, Talent>) => {
          // const workDay = formatWithTimezone(
          //   project?.start_date,
          //   project?.timezone,
          //   "ddd"
          // );
          // const notAvailableForWork =
          //   (workDay == "Sun" && !!params.row.talent.avail_sunday == false) ||
          //   (workDay == "Mon" && !!params.row.talent.avail_monday == false) ||
          //   (workDay == "Tue" && !!params.row.talent.avail_tuesday == false) ||
          //   (workDay == "Wed" &&
          //     !!params.row.talent.avail_wednesday == false) ||
          //   (workDay == "Thu" && !!params.row.talent.avail_thursday == false) ||
          //   (workDay == "Fri" && !!params.row.talent.avail_friday == false) ||
          //   (workDay == "Sat" && !!params.row.talent.avail_saturday == false);

          const alreadyBid = bids?.find(
            (it) => it.resource_id == params.row.id
          );
          // if (notAvailableForWork)
          //   return (
          //     <Typography fontStyle={"italic"} variant="body2">
          //       Not Available
          //     </Typography>
          //   );
          // else if (alreadyBid)
          //   return (
          //     <Typography fontStyle={"italic"} variant="body2">
          //       Invited
          //     </Typography>
          //   );
          if (alreadyBid)
            return (
              <Typography fontStyle={"italic"} variant="body2">
                Invited
              </Typography>
            );

          return (
            <Button
              variant="outlined"
              color="info"
              onClick={() => handleInvite(params.row.id)}
            >
              Invite
            </Button>
          );
        },
      },
    ],
    [bids, project, favTalents]
  );

  React.useEffect(() => {
    setSearchQuery(debounceQuery);
  }, [debounceQuery]);

  const TableToolbar = ({
    onChangeFav,
  }: {
    onChangeFav: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }) => (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
      <Stack
        spacing={1}
        style={{ flex: 1 }}
        direction="row"
        justifyContent="flex-end"
      >
        <FormGroup>
          <FormControlLabel
            control={<Checkbox onChange={onChangeFav} />}
            label="Only Favorite Independent Professionals"
          />
        </FormGroup>
      </Stack>
    </GridToolbarContainer>
  );

  const handleChangeFav = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setShowFav(event.target.checked);
    },
    []
  );

  const CustomToolbar = React.useMemo(
    () => () => <TableToolbar onChangeFav={handleChangeFav} />,
    [setQuery, handleChangeFav]
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xl">
      <DialogTitle>Invite Independent Professional</DialogTitle>
      <DialogContent>
        <Paper style={{ height: "70vh" }}>
          <DataGrid
            rows={rowTalents || []}
            columns={columnTalents}
            paginationMode="server"
            // rowCount={rowCount}
            page={page}
            onPageChange={(newPage) => setPage(newPage)}
            pageSize={pageSize}
            rowsPerPageOptions={[1000]}
            onPageSizeChange={(newSize) => setPageSize(newSize)}
            sortModel={sortModel}
            sortingMode="server"
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            loading={isLoadingTalent || isFetchingTalent || isInviting}
            components={{
              LoadingOverlay: LinearProgress,
              NoResultsOverlay: NoResultsOverlay,
              Toolbar: CustomToolbar,
            }}
            disableSelectionOnClick
            disableColumnFilter
            rowHeight={72}
            density="compact"
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#f5f5f5",
                borderTop: "1px solid #ddd",
              },
            }}
          />
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
      <ModalTalentDetail
        open={talentModal.visible}
        onClose={() => setTalentModal({ visible: false, talent: null })}
        talent={talentModal.talent}
      />
    </Dialog>
  );
};
export default ModalInvite;
