import * as React from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridRowsProp,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import Link from "@mui/material/Link";
import AddIcon from "@mui/icons-material/Add";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import NoResultsOverlay from "../../components/NoResultOverlay";
import useDebounce from "../../hooks/useDebounce";
// import { Talent } from "../../types/users";
import {
  NegotiationStatus,
  Project,
  ProjectResource,
  ProjectStatus,
} from "../../types/project";
import { useNavigate } from "react-router-dom";
import { useGetProjectsQuery } from "../../services/project.service";
import {
  Box,
  Chip,
  FormHelperText,
  Icon,
  IconButton,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { OpenInNew, Person, Place, Refresh, Sort } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useCompany } from "../../hooks/useCompany";
import { CompanyStatus } from "../../types/company";
import { getHiredTalentStatus } from "../../utils/projectHelper";
import ModalUploadDocuments from "./ModalUploadDocuments";
import { formatWithTimezone } from "../../utils/dateHelper";
//import  CreateTransform  from "redux-persist/lib/createTransform";

const TableToolbar = ({
  onCreate,
  onRefresh,
  onSearch,
  onShowMap,
}: {
  onSearch: (query?: string) => void;
  onRefresh: () => void;
  onCreate: () => void;
  onShowMap: () => void;
}) => {
  const company = useCompany();
  const [showUploadModal, setShowUploadModal] = React.useState<boolean>(false);
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);
  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
      <Stack
        style={{ flex: 1 }}
        direction="row"
        justifyContent="flex-end"
        spacing={1}
      >
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true, fileName: "Jobs" }}
          sx={{ mr: 2 }}
        />
        <Button variant="contained" onClick={onShowMap}>
          <LocationOnIcon /> Show Map
        </Button>
        <Button
          variant="contained"
          onClick={onCreate}
          disabled={company?.status != CompanyStatus.ACTIVE}
        >
          <AddIcon /> Create New Work
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setShowUploadModal(true);
          }}
          disabled={company?.status != CompanyStatus.ACTIVE}
        >
          <AddIcon /> Upload Documents
        </Button>
        <LoadingButton
          size={"large"}
          //variant="outlined"
          onClick={onRefresh}
          sx={{ ml: 1 }}
          endIcon={<Refresh />}
        >
          Refresh
        </LoadingButton>
      </Stack>
      <ModalUploadDocuments
        open={showUploadModal}
        onClose={() => {
          setShowUploadModal(false);
        }}
      />
    </GridToolbarContainer>
  );
};

interface ProjectGridProps {
  onView: (item: any) => void;
  status: ProjectStatus;
  filter?: "scheduled" | "inProgress";
}

const ProjectGrid = (props: ProjectGridProps) => {
  const { onView, status, filter } = props;

  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );
  const { data, isLoading, isFetching, refetch } = useGetProjectsQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : "start_date",
    sortMode: sortModel.length > 0 ? sortModel[0].sort : "desc",
    search: searchQuery,
    status,
  });
  const rows: GridRowsProp | undefined = React.useMemo(() => {
    if (filter) {
      const newData = data?.data.filter((item) => {
        const hiredList = item.resources?.filter((it) => !!it.hired_at) || [];
        let isInProgress = false;
        hiredList.map((hiredTalent) => {
          if (
            hiredTalent.report_progresses?.length != undefined &&
            hiredTalent.report_progresses?.length > 0
          )
            isInProgress = true;
        });
        if (filter === "inProgress") return !!isInProgress;
        if (filter === "scheduled") return !isInProgress;
      });
      return newData;
    } else {
      return data?.data;
    }
  }, [data]);

  const rowCount = React.useMemo(() => {
    return data?.meta.total;
  }, [data]);

  const onBidClick = (row: Project) => {
    navigate(`/works/${row?.id}?t=1`);
  };

  const columns: GridColumns = React.useMemo(() => {
    const col: GridColumns = [];
    col.push(
      {
        field: "id",
        headerName: "Work Id",
        hide: true,
      },
      {
        field: "title",
        headerName: "Work Name",
        hide: true,
      },
      {
        field: "client_location",
        headerName: "Location",
        hide: true,
        valueGetter: (params: GridValueGetterParams<undefined, Project>) =>
          !!params.row.customer
            ? params.row.customer?.first_name
            : !!params.row.location
            ? params.row.location.name
            : "?",
      },
      {
        field: "job_title",
        headerName: "Work ID# - Work Title",
        flex: 1,
        minWidth: 300,
        sortable: true,
        filterable: true,
        renderCell: (params: GridRenderCellParams<undefined, Project>) => (
          <Box key={params.row.id}>
            <Stack direction={"row"}>
              <Link
                component="button"
                variant="body2"
                onClick={() => onView(params.row)}
              >
                #{params.row.id} - {params.row.title}
              </Link>
              {!!params.row.is_private && (
                <Typography variant="body2" sx={{ ml: 1 }}>
                  (Private)
                </Typography>
              )}
            </Stack>
            {!!params.row.customer ? (
              <Typography variant="body2">
                <Person fontSize="inherit" color="disabled" />{" "}
                {params.row.customer?.first_name}
              </Typography>
            ) : !!params.row.location ? (
              <Typography variant="body2">
                <Place fontSize="inherit" color="disabled" />{" "}
                {params.row.location.name}
              </Typography>
            ) : (
              <Typography>?</Typography>
            )}
          </Box>
        ),
        disableExport: true,
      },
      {
        field: "is_private",
        headerName: "Private",
        hide: true,
      },
      {
        field: "start_date",
        headerName: "Start At",
        headerAlign: "center",
        minWidth: 220,
        sortingOrder: ["desc", "asc", null],
        sortable: true,
        filterable: true,
        align: "left",
        valueGetter: (params: GridValueGetterParams<undefined, Project>) =>
          `${formatWithTimezone(params.row.start_date, params.row.timezone)}`,
      },
      {
        field: "manager_id",
        headerName: "Manager",
        headerAlign: "center",
        minWidth: 150,
        sortable: true,
        filterable: true,
        align: "center",
        valueGetter: (params: GridValueGetterParams<undefined, Project>) =>
          !!params.row.manager
            ? `${params.row.manager.first_name} ${params.row.manager.last_name}`
            : `-`,
      }
    );

    if (status == ProjectStatus.PUBLISHED)
      col.push(
        {
          field: "bid",
          headerName: "Offers",
          headerAlign: "center",
          minWidth: 120,
          sortable: false,
          filterable: false,
          align: "center",
          renderCell: (params: GridRenderCellParams<undefined, Project>) => {
            const bidCount =
              params.row.resources?.filter(
                (resource) =>
                  resource.status !== NegotiationStatus.INVITED &&
                  resource.status !== NegotiationStatus.CANCELED &&
                  resource.status !== NegotiationStatus.REJECTED &&
                  resource.status !== NegotiationStatus.HIRED
              ) || [];
            if (bidCount.length === 0) {
              return (
                <Typography
                  key={params.row.id}
                  variant="body2"
                  style={{ fontWeight: 500, fontSize: 18 }}
                >
                  -
                </Typography>
              );
            }
            return (
              <Box key={params.row.id}>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => onBidClick(params.row)}
                  style={{ fontWeight: 500, fontSize: 18 }}
                >
                  {bidCount.length || "-"}
                </Link>
              </Box>
            );
          },
          disableExport: true,
        },
        {
          field: "invited",
          headerName: "Invites",
          headerAlign: "center",
          minWidth: 120,
          sortable: false,
          filterable: false,
          align: "center",
          renderCell: (params: GridRenderCellParams<undefined, Project>) => {
            const invitesCount =
              params.row.resources?.filter(
                (resource) => resource.status === "invited"
              ) || [];
            if (invitesCount.length === 0) {
              return (
                <Typography
                  key={params.row.id}
                  variant="body2"
                  style={{ fontWeight: 500, fontSize: 18 }}
                >
                  -
                </Typography>
              );
            }
            return (
              <Box key={params.row.id}>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => onBidClick(params.row)}
                  style={{ fontWeight: 500, fontSize: 18 }}
                >
                  {invitesCount.length || "-"}
                </Link>
              </Box>
            );
          },
        }
      );
    else if (
      status == ProjectStatus.SCHEDULED ||
      status == ProjectStatus.IN_PROGRESS
    ) {
      const talentStatuses = (project: Project) => {
        const jobList = project.resources?.filter((it) => !!it.hired_at) || [];
        return (
          <Stack key={project.id}>
            {jobList.map((it) => {
              const status = getHiredTalentStatus(project, it);
              if (
                project.status == ProjectStatus.SCHEDULED ||
                project.status == ProjectStatus.IN_PROGRESS
              )
                return (
                  <>
                    <Stack direction={"row"} alignItems="center">
                      <Chip
                        label={status.label}
                        color={status.color}
                        size="small"
                        sx={{ height: 20, fontSize: 12 }}
                      />
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        {it.resource?.full_name} ($
                        {project.type == "fixed" ? it.price : it.rate})
                      </Typography>
                    </Stack>
                  </>
                );
              else
                return (
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {it.resource?.full_name || "n/a"} ($
                    {project.type == "fixed" ? it.price : it.rate})
                  </Typography>
                );
            })}
          </Stack>
        );
      };

      col.push(
        {
          field: "talents",
          headerName: "Independent Professional (Rate)",
          headerAlign: "center",
          minWidth: 250,
          align: "center",
          sortable: false,
          filterable: false,
          renderCell: (params: GridRenderCellParams<undefined, Project>) => {
            return <div key={params.row.id}>{talentStatuses(params.row)}</div>;
          },
          disableExport: true,
        },
        {
          field: "talents2",
          headerName: "Independent Professional (Rate)",
          hide: true,
          valueGetter: (params: GridValueGetterParams<undefined, Project>) => {
            const jobList =
              params.row.resources?.filter((it) => !!it.hired_at) || [];
            return jobList
              .map(
                (it) =>
                  `${it.resource?.full_name || "n/a"} ($${
                    params.row.type == "fixed" ? it.price : it.rate
                  })`
              )
              .join(";");
          },
        },
        {
          field: "is_photo_required",
          headerName: "Photos Required",
          hide: true,
        }
      );
    } else if (status == ProjectStatus.COMPLETED) {
      col.push(
        {
          field: "talents",
          headerName: "Independent Professional",
          headerAlign: "center",
          minWidth: 250,
          align: "center",
          sortable: false,
          filterable: false,
          valueGetter: (params: GridValueGetterParams<undefined, Project>) => {
            const jobList =
              params.row.resources?.filter((it) => !!it.hired_at) || [];
            return jobList
              .map((it) => `${it.resource?.full_name || "n/a"}`)
              .join(";");
          },
        },
        {
          field: "is_photo_required",
          headerName: "Photos Required",
          hide: true,
        }
      );
    } else if (status == ProjectStatus.CANCELED) {
      col.push({
        field: "canceled_reason",
        headerName: "Cancel Reason",
        headerAlign: "center",
        minWidth: 150,
        sortable: true,
        filterable: false,
        align: "center",
        valueGetter: (params: GridValueGetterParams<undefined, Project>) =>
          params.row.canceled_reason,
      });
    }

    //last one is for control
    col.push({
      field: "actions",
      headerName: "Details",
      headerAlign: "center",
      minWidth: 100,
      type: "actions",
      align: "center",
      width: 50,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key={params.row.id}
          icon={<OpenInNew />}
          onClick={() => navigate(`/works/${params.row.id}`)}
          label="Open Detail"
        />,
      ],
      disableExport: true,
    });
    return col;
  }, [status]);

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const handleCreate = React.useCallback(() => {
    navigate(`/works/create`);
  }, []);

  const handleShowMap = React.useCallback(() => {
    navigate(`/works/map`);
  }, []);

  const CustomToolbar = React.useMemo(
    () => () =>
      (
        <TableToolbar
          onSearch={handleSearch}
          onCreate={handleCreate}
          onShowMap={handleShowMap}
          onRefresh={refetch}
        />
      ),
    [handleSearch]
  );

  return (
    <Paper style={{ height: "80vh" }}>
      <DataGrid
        rows={rows || []}
        columns={columns}
        paginationMode="server"
        rowCount={rowCount}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        pageSize={pageSize}
        rowsPerPageOptions={[100]}
        onPageSizeChange={(newSize) => setPageSize(newSize)}
        sortModel={sortModel}
        sortingMode="server"
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        loading={isLoading || isFetching}
        components={{
          LoadingOverlay: LinearProgress,
          NoResultsOverlay: NoResultsOverlay,
          Toolbar: CustomToolbar,
        }}
        disableSelectionOnClick
        disableColumnFilter
        // rowHeight={42}
        // density="compact"
        // @ts-ignore
        getRowHeight={() =>
          status == ProjectStatus.SCHEDULED || ProjectStatus.IN_PROGRESS
            ? 56
            : undefined
        }
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f5f5f5",
            borderTop: "1px solid #ddd",
          },
        }}
      />
    </Paper>
  );
};
export default ProjectGrid;
