import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Button,
  List,
  ListItem,
  ListItemText,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import StarIcon from "@mui/icons-material/Star";
import { useNavigate } from "react-router-dom";
import { Talent, UserWorkStatus } from "../../types/users";
import { nl2br } from "../../utils/helper";
import { useGetTalentByIdQuery } from "../../services/talent.service";

import { useCompany } from "../../hooks/useCompany";
import { CompanyStatus } from "../../types/company";

// type Anchor = 'top' | 'left' | 'bottom' | 'right';

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 2),
  marginTop: 40,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const containerStyle = {
  width: "100%",
  height: "400px",
};

const options = {
  fullscreenControl: false, // remove the top-right button
  mapTypeControl: false, // remove the top-left buttons
  streetViewControl: false, // remove the pegman
  zoomControl: false, // remove the bottom-right buttons
  scrollwheel: false,
};

interface TalentDrawerProps {
  data: Talent | null;
  forceRefresh?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TalentDrawer(props: TalentDrawerProps) {
  const { data: dataProp, forceRefresh = false, open, setOpen } = props;
  const navigate = useNavigate();
  const [data, setData] = React.useState(dataProp);

  const {
    data: dataApi,
    isError,
    isLoading,
  } = useGetTalentByIdQuery(dataProp?.id!, {
    skip: !forceRefresh || !dataProp,
    refetchOnMountOrArgChange: true,
  });

  React.useEffect(() => {
    if (!!dataApi && !isError) setData(dataApi);
    else setData(dataProp);
  }, [dataApi, dataProp, isError]);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(open);
    };
  const company = useCompany();
  const list = () => (
    <Box sx={{ width: 450, mb: 8 }} role="presentation">
      <DrawerHeader>
        <ClearIcon
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        />
      </DrawerHeader>
      <Stack direction={"row"} sx={{ p: 2 }} alignItems="center">
        <Avatar
          alt={`${data?.first_name}`}
          src={`${data?.photo?.[0]?.original_url}`}
          sx={{ width: 100, height: 100 }}
        />
        <Box sx={{ ml: 2 }}>
          <Typography variant="h6">
            {data?.first_name} {data?.last_name}
          </Typography>
          <Typography variant="body2">
            {data?.city}, {data?.state} {data?.zip}
          </Typography>
          <Rating value={data?.total_rating || 0} readOnly precision={0.5} />
        </Box>
      </Stack>
      <Box sx={{ px: 2, pb: 2 }}>
        <Typography sx={{ fontSize: 12 }}>{nl2br(data?.bio)}</Typography>
      </Box>
      <Divider />

      <List>
        <ListItem>
          <Stack spacing={1} sx={{ mt: 1 }}>
            <Typography sx={{ fontSize: 12 }}>Industries</Typography>
            <Box>
              {data?.business_verticals?.length == 0 ? (
                <Typography sx={{ fontSize: 14 }}>No industry added</Typography>
              ) : (
                data?.business_verticals?.map((it) => (
                  <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                ))
              )}
            </Box>
          </Stack>
        </ListItem>
        <Divider />
        <ListItem>
          <Stack spacing={1} sx={{ mt: 1 }}>
            <Typography sx={{ fontSize: 12 }}>Skills</Typography>
            <Box>
              {data?.skills?.length == 0 ? (
                <Typography sx={{ fontSize: 14 }}>No skill added</Typography>
              ) : (
                data?.skills?.map((it) => (
                  <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                ))
              )}
            </Box>
          </Stack>
        </ListItem>
        <Divider />
        <ListItem>
          <Stack spacing={1} sx={{ mt: 1 }}>
            <Typography sx={{ fontSize: 12 }}>Languages</Typography>
            <Box>
              {data?.languages?.length == 0 ? (
                <Typography sx={{ fontSize: 14 }}>
                  No languages added
                </Typography>
              ) : (
                data?.languages?.map((it) => (
                  <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                ))
              )}
            </Box>
          </Stack>
        </ListItem>
        <Divider />
        <ListItem>
          <Stack spacing={1} sx={{ mt: 1 }}>
            <Typography sx={{ fontSize: 12 }}>Work Status</Typography>
            <Typography sx={{ fontSize: 14 }}>
              {data?.talent?.work_status == UserWorkStatus.SOLE_PROPRIETOR
                ? "Sole Proprietor"
                : data?.talent?.work_status == UserWorkStatus.ENTREPRENEUR
                ? "Entrepreneur"
                : data?.talent?.work_status == UserWorkStatus.PART_TIME
                ? "Part Time"
                : "Not Set"}
            </Typography>
          </Stack>
        </ListItem>
        <Divider />
        <ListItem>
          <Stack spacing={1} sx={{ mt: 1 }}>
            <Typography sx={{ fontSize: 12 }}>Availability</Typography>
            <Stack direction={"row"}>
              {data?.talent?.avail_monday && <Chip label="Mon" />}
              {data?.talent?.avail_tuesday && <Chip label="Tue" />}
              {data?.talent?.avail_wednesday && <Chip label="Wed" />}
              {data?.talent?.avail_thursday && <Chip label="Thu" />}
              {data?.talent?.avail_friday && <Chip label="Fri" />}
              {data?.talent?.avail_saturday && <Chip label="Sat" />}
              {data?.talent?.avail_sunday && <Chip label="Sun" />}
            </Stack>
          </Stack>
        </ListItem>
        <Divider />
        <ListItem>
          <Stack spacing={1} sx={{ mt: 1 }}>
            <Typography sx={{ fontSize: 12 }}>More Info</Typography>
            <Box>
              <ListItemText>
                Rate → {data?.min_pay} - {data?.max_pay} $/hr
              </ListItemText>
              <ListItemText>
                Commute Radius → {data?.commute_radius} miles
              </ListItemText>
              <ListItemText>
                Allow Background Check →
                {!!data?.allow_background_check ? "Yes" : "No"}
              </ListItemText>
              <ListItemText>
                Allow Drug Test → {!!data?.allow_drug_test ? "Yes" : "No"}
              </ListItemText>
            </Box>
          </Stack>
        </ListItem>
        <Divider />
      </List>
      <Box display="flex" justifyContent="center" sx={{ mt: 5 }}>
        <Button
          variant="contained"
          onClick={() => navigate(`/independent-professionals/${data?.id}`)}
          disabled={company?.status != CompanyStatus.ACTIVE}
        >
          More Detail
        </Button>
      </Box>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        {/*<Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>*/}
        <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
