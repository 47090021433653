import * as React from "react";
import { Autocomplete, TextField } from "@mui/material";
// import {
//   useTimezoneSelect,
//   allTimezones,
//   ITimezoneOption,
// } from "react-timezone-select";

const usTimezones = [
  { label: "Eastern Time", value: "America/New_York" },
  { label: "Central Time", value: "America/Chicago" },
  { label: "Mountain Time", value: "America/Denver" },
  { label: "Pacific Standard Time", value: "America/Los_Angeles" },
  { label: "Alaska Standard Time", value: "America/Anchorage" },
  { label: "Hawaii-Aleutian Standard Time", value: "Pacific/Honolulu" },
];

const SelectTimezone = ({
  onChange,
  sx,
  value,
  label = "Client",
  error,
  helperText,
}: {
  onChange: (arg?: string) => void;
  sx?: any;
  value: string;
  label?: string;
  error?: boolean;
  helperText?: React.ReactNode;
}) => {
  // const { options, parseTimezone } = useTimezoneSelect({
  //   labelStyle: "original",
  //   timezones: allTimezones,
  // });

  const selected = React.useMemo(
    () => usTimezones?.find((it) => it.value == value),
    [usTimezones, value]
  );

  return (
    <Autocomplete
      fullWidth
      options={usTimezones || []}
      value={selected || null}
      getOptionLabel={(option) => `${option.label}`}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder="Search..."
          sx={sx}
          error={error}
          helperText={helperText}
        />
      )}
      onChange={(event: any, newValue) => {
        onChange(newValue?.value);
      }}
      // loading={isLoading || isFetching}
    />
  );
};
export default SelectTimezone;
