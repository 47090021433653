import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import { Location } from "../../types/location";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useAddLocationMutation,
  useEditLocationMutation,
  useGetLocationByIdQuery,
} from "../../services/location.service";
import SelectCountry from "../../components/SelectCountry";
import SelectMapAddress from "../../components/SelectMapAddress";
import { AddressComponent } from "../../types/common";
import { useGetCountriesQuery } from "../../services/country.service";
import TitleDivider from "../../components/TitleDivider";
import PinDropIcon from "@mui/icons-material/PinDrop";
import SearchIcon from "@mui/icons-material/Search";
import CoordinateInfo from "../../components/CoordinateInfo";
import SelectState from "../../components/SelectState";

interface LocationModalFormProps {
  open: boolean;
  onClose: () => void;
  onCreated?: (id: number) => void;
  data: Location | null;
}

const LocationModalForm = ({
  open,
  onClose,
  onCreated,
  data,
}: LocationModalFormProps) => {
  const [add, { isLoading: adding }] = useAddLocationMutation();
  const [edit, { isLoading: editing }] = useEditLocationMutation();
  const { data: countries } = useGetCountriesQuery();
  const [showAddress, setShowAddress] = React.useState(false);

  const {
    handleSubmit,
    clearErrors,
    control,
    setValue,
    setError,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      if (!payload.country) {
        setError("country", { type: "custom", message: "Invalid" });
        return;
      }

      if (!data) {
        const loc = await add(payload).unwrap();
        if (onCreated) {
          onCreated(loc.id);
        }
      } else {
        if (!payload.password) delete payload.password;
        payload.id = data?.id!;
        await edit(payload).unwrap();
      }
      onClose();
    } catch (err) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (!open) return;

    setValue("name", data?.name);
    setValue("address_1", data?.address_1);
    setValue("address_2", data?.address_2);
    setValue("city", data?.city);
    setValue("state", data?.state);
    setValue("zip", data?.zip);
    setValue("country", data?.country_id || null);
    const [lng, lat] = data?.coordinate?.coordinates || [];
    setValue("lat", lat || 0);
    setValue("lng", lng || 0);
    setShowAddress(!!lat && !!lng);

    setValue("primary_contact_name", data?.primary_contact_name);
    setValue("primary_contact_title", data?.primary_contact_title);
    setValue(
      "primary_contact_phone_number",
      data?.primary_contact_phone_number
    );
    setValue("primary_contact_email", data?.primary_contact_email);
    setValue("site_number", data?.site_number);

    clearErrors();
  }, [data, open]);

  const handleMapAddressChange = (
    address: AddressComponent,
    lat?: number,
    lng?: number
  ) => {
    setValue("address_1", `${address.street_number} ${address.street}`);
    setValue("city", address.city);
    setValue("state", address.state);
    setValue("zip", address.postal_code);
    setValue("lat", lat);
    setValue("lng", lng);
    const found = countries?.find(
      (it) => it.code.toLowerCase() == address.country.toLowerCase()
    );
    setValue("country", found?.id);
    setShowAddress(true);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">
          {!data ? "Create" : "Edit"} Location
        </DialogTitle>
        <DialogContent>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label="Site Number/Location name"
                InputLabelProps={{ shrink: !!value }}
                value={value}
                fullWidth
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.name}
                helperText={!!errors.name && "This field is required"}
              />
            )}
          />
          <Controller
            name="site_number"
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label="Client Name"
                InputLabelProps={{ shrink: !!value }}
                value={value}
                fullWidth
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
          {!showAddress && (
            <SelectMapAddress
              onChange={handleMapAddressChange}
              error={!!errors.country}
              helperText={!!errors.country && "This field is required"}
            />
          )}
          {showAddress && (
            <Box>
              <TitleDivider title="Address" />
              <Button
                onClick={() => {
                  setValue("country", null); //reset
                  setShowAddress(false);
                }}
                size="small"
                startIcon={<SearchIcon />}
              >
                Search New Address
              </Button>
              <Controller
                name="address_1"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    label="Address 1"
                    InputLabelProps={{ shrink: !!value }}
                    value={value}
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.address_1}
                    helperText={!!errors.address_1 && "This field is required"}
                  />
                )}
              />
              <Controller
                name="address_2"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    label="Address 2"
                    InputLabelProps={{ shrink: !!value }}
                    value={value}
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
              <Stack direction={"row"} spacing={2} sx={{ mt: 2 }}>
                <Controller
                  name="country"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <SelectCountry
                      onChange={onChange}
                      value={value}
                      sx={{ mt: 0 }}
                      error={!!errors.country}
                      helperText={!!errors.country && "This field is required"}
                    />
                  )}
                />
                <Controller
                  name="state"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <SelectState
                      label="State"
                      value={value || ""}
                      sx={{ mt: 0 }}
                      onChange={onChange}
                      error={!!errors.state}
                      helperText={!!errors.state && "This field is required"}
                    />
                  )}
                />
              </Stack>
              <Stack direction={"row"} spacing={2} sx={{ mt: 2 }}>
                <Controller
                  name="city"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      label="City"
                      InputLabelProps={{ shrink: !!value }}
                      value={value}
                      fullWidth
                      sx={{ mt: 0 }}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!errors.city}
                      helperText={!!errors.city && "This field is required"}
                    />
                  )}
                />
                <Controller
                  name="zip"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      label="Zip"
                      InputLabelProps={{ shrink: !!value }}
                      value={value}
                      sx={{ mt: 0 }}
                      fullWidth
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!errors.zip}
                      helperText={!!errors.zip && "This field is required"}
                    />
                  )}
                />
              </Stack>
              <CoordinateInfo lat={watch("lat")} lng={watch("lng")} />
            </Box>
          )}
          <Box>
            <TitleDivider title="Primary Contact" />
            <Stack direction={"row"} spacing={1} sx={{ mt: 2 }}>
              <Controller
                name="primary_contact_name"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    label="Full Name"
                    value={value}
                    fullWidth
                    sx={{ mt: 0 }}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.primary_contact_name}
                    helperText={
                      !!errors.primary_contact_name && "This field is required"
                    }
                  />
                )}
              />
              {/* <Controller
                name="primary_contact_title"
                control={control}
                // rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    label="Title"
                    value={value}
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    // error={!!errors.primary_contact_title}
                    // helperText={
                    //   !!errors.primary_contact_title && "This field is required"
                    // }
                  />
                )}
              /> */}
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{ mt: 2 }}>
              <Controller
                name="primary_contact_phone_number"
                control={control}
                rules={{ required: true, minLength: 6 }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    label="Phone number"
                    value={value}
                    fullWidth
                    sx={{ mt: 0 }}
                    onChange={onChange}
                    onBlur={onBlur}
                    type="number"
                    error={!!errors.primary_contact_phone_number}
                    helperText={
                      !!errors.primary_contact_phone_number &&
                      "This field is required"
                    }
                  />
                )}
              />
              <Controller
                name="primary_contact_email"
                control={control}
                rules={{
                  required: false,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format",
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    label="Email"
                    value={value}
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    type="email"
                    error={!!errors.primary_contact_email}
                    helperText={
                      !!errors.primary_contact_ && "This field is required"
                    }
                  />
                )}
              />
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={adding || editing}
          >
            {!data ? "Create" : "Update"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default LocationModalForm;
