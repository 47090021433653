import * as React from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { Skill } from "../types/skill";
import { useGetSkillsQuery } from "../services/skill.service";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SelectSkills = ({
  onChange,
  values,
  label = "Skills",
  error,
  helperText,
}: {
  onChange: (ids?: number[]) => void;
  values: number[];
  label?: string;
  error?: boolean;
  helperText?: React.ReactNode;
}) => {
  const { data: skills, isLoading } = useGetSkillsQuery({
    page: 0,
    pageSize: 2000,
    sortField: "name",
    sortMode: "asc",
  });

  const initialSkills = React.useMemo(
    () => skills?.data.filter((it) => values?.includes(it.id)),
    [skills, values]
  );

  return (
    <Autocomplete
      multiple
      fullWidth
      id="checkboxes-tags"
      options={skills?.data || []}
      disableCloseOnSelect
      disabled={isLoading}
      ChipProps={{ color: "secondary" }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder="Search..."
          error={error}
          helperText={helperText}
        />
      )}
      loading={isLoading}
      onChange={(event: any, newValue: Skill[] | null) => {
        onChange(newValue?.map((it) => it.id));
      }}
      value={initialSkills || []}
    />
  );
};
export default SelectSkills;
