import * as React from "react";
import { styled } from "@mui/material/styles";
import { Card, CardContent, CardHeader, Grid, Table } from "@mui/material";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { Customer } from "../../types/customer";
import Linkify from "../../components/Linkify";

interface ExpandMorePaymentTermProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMorePaymentTerm = styled((props: ExpandMorePaymentTermProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ClientDetailsPanel = ({ data }: { data?: Customer }) => {
  return (
    <Grid container spacing={1} sx={{ mb: 1 }}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="About" />
          <CardContent>
            <Table sx={{ fontSize: 14 }}>
              <tbody>
                <tr>
                  <td width={"20%"}>Client name</td>
                  <td>{data?.first_name}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{data?.phone_number || "n/a"}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{data?.email || "n/a"}</td>
                </tr>
              </tbody>
            </Table>
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Contact" />
          <CardContent>
            <Table sx={{ fontSize: 14 }}>
              <tbody>
                <tr>
                  <td width={"20%"}>Address 1</td>
                  <td>{data?.physical_address_1}</td>
                </tr>
                <tr>
                  <td>Address 2</td>
                  <td>{data?.physical_address_2}</td>
                </tr>
                <tr>
                  <td>City / Zip</td>
                  <td>
                    {data?.city} / {data?.zip}
                  </td>
                </tr>
                <tr>
                  <td>State</td>
                  <td>{data?.state}</td>
                </tr>
                {/*<tr>
                  <td>Country</td>
                  <td>{data?.country_id}</td>
                </tr>*/}
              </tbody>
            </Table>
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Social Medias" />
          <CardContent>
            <Table sx={{ fontSize: 14 }}>
              <tbody>
                <tr>
                  <td width={"20%"}>LinkedIn</td>
                  <td>
                    <Linkify url={data?.linked_in} />
                  </td>
                </tr>
                <tr>
                  <td>Facebook</td>
                  <td>
                    <Linkify url={data?.facebook} />
                  </td>
                </tr>
                <tr>
                  <td>Instagram</td>
                  <td>
                    <Linkify url={data?.instagram} />
                  </td>
                </tr>
                <tr>
                  <td>Website</td>
                  <td>
                    <Linkify url={data?.website} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export default ClientDetailsPanel;
