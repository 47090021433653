import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  LinearProgress,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  useGetTalentByIdQuery,
  useGetTalentTotalEarnedByIdQuery,
  useGetTalentTotalJobHiredByIdQuery,
  useGetTalentTotalJobSuccessRateByIdQuery,
} from "../../services/talent.service";
import {
  useGetAllFavoriteTalentsQuery,
  useAddFavoriteTalentMutation,
  useDeleteFavoriteTalentMutation,
} from "../../services/favoriteTalent.service";
import { useTitle } from "../../hooks/useTitle";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import FavoriteIcon from "@mui/icons-material/Favorite";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { Talent } from "../../types/users";
import TalentProfilePanel from "./TalentProfilePanel";
import { formatCash } from "../../utils/helper";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import { Favorite, FavoriteOutlined, Reviews } from "@mui/icons-material";
import { useGetTalentReviewsQuery } from "../../services/review.service";
import dayjs from "dayjs";

const TalentReviewsPage = () => {
  const { talentId } = useParams();

  const { data: talent } = useGetTalentByIdQuery(parseInt(talentId!), {
    skip: !talentId,
  });

  const { data, isLoading, isFetching } = useGetTalentReviewsQuery(
    parseInt(talentId!),
    {
      skip: !talentId,
    }
  );

  useTitle(
    `Independent Professional #${talent?.id}`,
    `${talent?.first_name} ${talent?.last_name}`
  );

  return (
    <Box>
      <Typography variant="h5">Reviews For {talent?.full_name}</Typography>
      {(isLoading || isFetching) && <LinearProgress sx={{ mt: 1 }} />}
      {data?.length == 0 && (
        <Box>
          <Typography color={"text.disabled"}>No Review, Yet</Typography>
        </Box>
      )}
      {data?.map((review) => (
        <Card sx={{ mt: 2 }}>
          <CardHeader
            title={`#${review.project?.id} ${review?.project?.title}`}
            subheader={review.project?.company?.name}
          />
          <CardContent>
            <Rating value={review.star} readOnly={true} />
            <Box>
              <Typography sx={{ fontStyle: "italic" }}>
                {review.review}
              </Typography>
              <Typography variant="caption" color={"text.disabled"}>
                {dayjs(review.created_at).format("MMM DD, YYYY hh:mm A z")}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};
export default TalentReviewsPage;
