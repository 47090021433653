import React from "react";
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  Link,
  Stack,
  Chip,
  Grid,
  CardContent,
  colors,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Project, ReportProgressStatus } from "../../types/project";
import { Place } from "@mui/icons-material";
import { getHiredTalentStatus } from "../../utils/projectHelper";
import { formatWithTimezone } from "../../utils/dateHelper";

const TodaysProjects = ({
  data,
  isLoading,
  emptyText,
}: {
  data: Project[];
  isLoading: boolean;
  emptyText?: string;
}) => {
  const navigate = useNavigate();

  const sorted = React.useMemo(() => {
    let inProgress: Array<Project> = [];
    let scheduled: Array<Project> = [];
    let completion: Array<Project> = [];
    data?.forEach((job) => {
      const hiredList = job.resources?.filter((it) => !!it.hired_at) || [];
      hiredList.forEach((hired) => {
        if (
          hired.report_progresses?.length != undefined &&
          hired.report_progresses?.length > 0
        ) {
          let found = hired.report_progresses?.find((progress) => {
            if (
              progress.status === ReportProgressStatus.SUBMITTED ||
              progress.status === ReportProgressStatus.APPROVED
            ) {
              return progress;
            }
          });
          if (found) {
            completion.push(job);
          } else inProgress.push(job);
        } else {
          scheduled.push(job);
        }
      });
    });
    const merged = [...inProgress, ...scheduled];
    return merged;
  }, [data]);

  if (isLoading)
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress />
      </Box>
    );

  if (data && data?.length == 0)
    return (
      <Box>
        <CardContent>
          <Typography>{emptyText ?? "No Projects"}</Typography>
        </CardContent>
      </Box>
    );

  return (
    <Box
      sx={{
        maxHeight: 600,
        overflow: "scroll",
        WebkitOverflowScrolling: "scroll",
      }}
    >
      <Box
        style={{
          flexDirection: "row",
          display: "flex",
          height: 50,
          padding: 16,
          borderBottomColor: colors.green[200],
          borderBottomStyle: "solid",
          borderBottomWidth: 2,
        }}
      >
        <Grid sm={5}>
          <Stack sx={{ alignItems: "flex-start" }}>
            <Typography
              variant="caption"
              sx={{ fontSize: 12, fontStyle: "bold", fontWeight: 600 }}
            >
              {`WORK TITLE`}
            </Typography>
          </Stack>
        </Grid>
        <Grid sm={2}>
          <Stack sx={{ alignItems: "center" }}>
            <Typography
              variant="caption"
              sx={{ fontSize: 12, fontStyle: "bold", fontWeight: 600 }}
            >
              {`MANAGER NAME`}
            </Typography>
          </Stack>
        </Grid>

        <Grid sm={2}>
          <Stack sx={{ alignItems: "center" }}>
            <Typography
              variant="caption"
              sx={{ fontSize: 12, fontStyle: "bold", fontWeight: 600 }}
            >
              {`INDEPENDENT PROFESSIONAL`}
            </Typography>
          </Stack>
        </Grid>
        <Grid sm={3}>
          <Stack sx={{ alignItems: "center" }}>
            <Typography
              variant="caption"
              sx={{ fontSize: 12, fontStyle: "bold", fontWeight: 600 }}
            >
              {`STATUS`}
            </Typography>
          </Stack>
        </Grid>
      </Box>
      <List>
        {sorted?.map((job, index) => {
          const hiredTalents = job.resources?.filter(
            (resource) => !!resource.hired_at
          );
          return hiredTalents?.map((hiredTalent) => {
            const status = getHiredTalentStatus(job, hiredTalent);
            return (
              <>
                <ListItem
                  key={job.id}
                  style={
                    status.label.startsWith("CHECKED")
                      ? { backgroundColor: "#ffff0022" }
                      : undefined
                  }
                >
                  <ListItemText
                    style={{ alignItems: "center" }}
                    primary={
                      <Link
                        style={{
                          textAlign: "left",
                          fontWeight: status.label.startsWith("CHECKED")
                            ? 500
                            : 400,
                        }}
                        onClick={() => navigate(`/works/${job.id}?t=1`)}
                        component="button"
                        variant="body2"
                      >
                        #{job.id} - {job.title}
                      </Link>
                    }
                    secondary={
                      <>
                        <Place fontSize="inherit" color="disabled" />{" "}
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="caption"
                        >
                          {!!job.customer
                            ? `${job.customer.first_name} ${job.customer.last_name}`
                            : !!job.location
                            ? job.location.name
                            : "?"}
                        </Typography>
                      </>
                    }
                  />

                  <Grid sm={2}>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography variant="caption">
                        {job.manager?.full_name}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid sm={2}>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography variant="caption">
                        {hiredTalent.resource?.full_name}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid sm={3}>
                    <Stack sx={{ alignItems: "center" }}>
                      <Chip
                        label={status.label}
                        color={status.color}
                        size="small"
                        sx={{ height: 20, fontSize: 12 }}
                      />
                      <Typography variant="caption" color="text.secondary">
                        {formatWithTimezone(
                          job.start_date,
                          job.timezone,
                          "ddd, MMM D YYYY - h:mmA z"
                        )}
                      </Typography>
                    </Stack>
                  </Grid>
                </ListItem>
                {index < data.length - 1 && <Divider component="li" />}
              </>
            );
          });
        })}
      </List>
    </Box>
  );
};
export default TodaysProjects;
