import * as React from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
  useNavigate,
} from "react-router-dom";
import ErrorNotFound from "./components/ErrorNotFound";
import ProtectedPageLayout from "./components/ProtectedPageLayout";
import LoginPage from "./features/auth/LoginPage";
import SignupPage from "./features/signup/SignupPage";
// import SignupWorkerPage from "./features/signup/SignupWorkerPage";
import SignupCompanyPage from "./features/signup/SignupCompanyPage";
import DashboardPage from "./features/dashboard/DashboardPage";
import ClientsPage from "./features/client/ClientsPage";
import ClientEditPage from "./features/client/ClientEditPage";
import ClientDetailPage from "./features/client/ClientDetailPage";
import LocationsPage from "./features/location/LocationsPage";
import CalendarPage from "./features/schedule/CalendarPage";
import ProjectsPage from "./features/project/ProjectsPage";
import ProjectEditPage from "./features/project/ProjectEditPage";
import ProjectDetailPage from "./features/project/ProjectDetailPage";
import ProjectsMapPage from "./features/project/ProjectsMapPage";
import ProjectTemplatesPage from "./features/project/ProjectTemplatesPage";
import ProjectTemplateEditPage from "./features/project/ProjectTemplateEditPage";
import ProjectTemplateDetailPage from "./features/project/ProjectTemplateDetailPage";
import { useAppSelector } from "./hooks/store";
import { useGetUserQuery, useLoadUserQuery } from "./services/auth.service";
import CircularProgress from "@mui/material/CircularProgress";
import TalentsPage from "./features/talent/TalentsPage";
import TalentsFavPage from "./features/talent/TalentsFavPage";
import TalentsMapPage from "./features/talent/TalentsMapPage";
import TalentDetailPage from "./features/talent/TalentDetailPage";
import { useCurrentUser } from "./hooks/useCurrentUser";
import ManagersPage from "./features/manager/ManagersPage";
import FinancesPage from "./features/finances/FinancesPage";
import InvoicesPage from "./features/finances/InvoicesPage";
import SignupCompleted from "./features/signup/SignupCompleted";
import { isProductionMode } from "./utils/helper";
import CornerRibbon from "./components/CornerRibbon";
import { colors, createTheme, Typography } from "@mui/material";
import TalentReviewsPage from "./features/talent/TalentReviewsPage";
import FinanceReportPage from "./features/report/FinanceReportPage";
import workz360 from "./images/workz360.png";
import ProjectBulkUploadPage from "./features/project/ProjectBulkUploadPage";
import { ThemeProvider } from "@emotion/react";
import ForgotPasswordPage from "./features/auth/ForgotPasswordPage";
import ResetPasswordPage from "./features/auth/ResetPasswordPage";

const Loader = () => {
  // const navigate = useNavigate();
  const token = useAppSelector((state) => state.auth.token);
  const { isLoading, refetch } = useLoadUserQuery(undefined, {
    skip: !token,
  });

  React.useEffect(() => {
    if (!!token) {
      refetch();
    }
  }, [token]);

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <img src={workz360} height={80} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size={18} />
        </div>
      </div>
    );
  return <Outlet />;
};

const PublicLayout = () => {
  const user = useCurrentUser();
  const isProduction = React.useMemo(() => isProductionMode(), []);
  if (!!user) return <Navigate to="/dashboard" replace />;
  return (
    <div>
      {!isProduction && (
        <CornerRibbon backgroundColor={colors.yellow[200]} fontColor="#333">
          STAGING
        </CornerRibbon>
      )}
      <Outlet />
    </div>
  );
};

const IndexPage = () => {
  return <Navigate to="/login" />;
};

const AppRoutes = () => {
  const user = useCurrentUser();

  const theme = React.useMemo(() => {
    let primaryColor = "#17903d";
    let secondaryColor = "#F4A259";

    // if (!!user) {
    //   const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    //   if (hexColorRegex.test(user.company.color_schema)) {
    //     primaryColor = user.company.color_schema;
    //   }
    // }

    return createTheme({
      // shadows: shadows.map(() => "none") as Shadows,
      palette: {
        primary: {
          main: primaryColor,
        },
        secondary: {
          main: secondaryColor,
        },
      },
      // spacing: 4,
      components: {
        MuiButton: {
          defaultProps: {
            // size: "small",
          },
        },
        MuiFilledInput: {
          defaultProps: {
            margin: "dense",
          },
        },
        MuiFormControl: {
          defaultProps: {
            margin: "dense",
          },
        },
        MuiIconButton: {
          defaultProps: {
            size: "small",
          },
        },
        MuiInputBase: {
          defaultProps: {
            margin: "none",
          },
        },
        MuiInputLabel: {
          defaultProps: {
            margin: "dense",
            sx: {
              backgroundColor: "white", //fix outline label overlap
            },
          },
        },
        MuiListItem: {
          defaultProps: {
            dense: true,
          },
        },
        MuiListItemButton: {
          defaultProps: {},
          styleOverrides: {
            root: {
              "&.Mui-selected": {
                backgroundColor: primaryColor + "66",
              },
            },
          },
        },
        MuiOutlinedInput: {
          defaultProps: {
            margin: "dense",
          },
        },
        MuiTable: {
          defaultProps: {
            size: "small",
          },
        },
        MuiTextField: {
          defaultProps: {
            margin: "normal",
            size: "small",
            variant: "outlined",
          },
        },
        MuiToolbar: {
          defaultProps: {
            variant: "dense",
          },
        },
        MuiMenuItem: {
          defaultProps: {
            // divider: true,
          },
        },
        MuiCard: {
          defaultProps: {
            variant: "outlined",
          },
        },
        MuiCardHeader: {
          defaultProps: {
            titleTypographyProps: { fontSize: 18 },
            sx: {
              py: 1,
              borderBottom: 1,
              borderBottomColor: "#eee",
            },
          },
        },
        MuiCardContent: {
          defaultProps: {},
        },
      },
    });
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route element={<Loader />}>
            <Route path="/" element={<IndexPage />} />
            <Route element={<PublicLayout />}>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route
                path="/reset-password/:email"
                element={<ResetPasswordPage />}
              />
              {/* <Route path="/signup/worker" element={<SignupWorkerPage />} /> */}
              <Route path="/signup/company" element={<SignupCompanyPage />} />
              <Route path="/signup/completed" element={<SignupCompleted />} />
            </Route>
            <Route element={<ProtectedPageLayout />}>
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/works">
                <Route index element={<ProjectsPage />} />
                <Route path="create" element={<ProjectEditPage />} />
                <Route
                  path="create/bulk-upload"
                  element={<ProjectBulkUploadPage />}
                />
                <Route path="map" element={<ProjectsMapPage />} />
                <Route path=":projectId" element={<ProjectDetailPage />} />
                <Route path=":projectId/edit" element={<ProjectEditPage />} />
                <Route
                  path=":projectId/template/create"
                  element={<ProjectTemplateEditPage />}
                />
                <Route path="templates">
                  <Route index element={<ProjectTemplatesPage />} />
                  <Route path="create" element={<ProjectTemplateEditPage />} />
                  <Route
                    path=":templateId"
                    element={<ProjectTemplateDetailPage />}
                  />
                  <Route
                    path=":templateId/edit"
                    element={<ProjectTemplateEditPage />}
                  />
                </Route>
              </Route>
              <Route path="/clients">
                <Route index element={<ClientsPage />} />
                <Route path="create" element={<ClientEditPage />} />
                <Route path=":customerId" element={<ClientDetailPage />} />
                <Route path=":customerId/edit" element={<ClientEditPage />} />
              </Route>
              <Route path="/calendar">
                <Route index element={<CalendarPage />} />
              </Route>
              <Route path="/locations">
                <Route index element={<LocationsPage />} />
              </Route>
              <Route path="/independent-professionals">
                <Route index element={<TalentsPage />} />
                {/* <Route path="create" element={<TalentEditPage />} /> */}
                <Route path="favorites" element={<TalentsFavPage />} />
                <Route path="map" element={<TalentsMapPage />} />
                <Route path=":talentId" element={<TalentDetailPage />} />
                {/* <Route path=":talentId/edit" element={<TalentEditPage />} /> */}
                <Route
                  path=":talentId/reviews"
                  element={<TalentReviewsPage />}
                />
              </Route>
              <Route path="/finances">
                <Route index element={<FinancesPage />} />
                <Route path="invoices" element={<InvoicesPage />} />
              </Route>
              <Route path="/managers">
                <Route index element={<ManagersPage />} />
              </Route>
              <Route path="/reports">
                <Route
                  index
                  element={
                    <Typography>
                      ← Please select report page from the sidebar menu
                    </Typography>
                  }
                />
                <Route path="finance-report" element={<FinanceReportPage />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<ErrorNotFound />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};
export default AppRoutes;
