import * as React from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { useGetCertificationsQuery } from "../services/certification.service";
import { Certification } from "../types/certification";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SelectCertifications = ({
  onChange,
  values,
  label = "Certificates",
  error,
  helperText,
}: {
  onChange: (ids?: number[]) => void;
  values: number[];
  label?: string;
  error?: boolean;
  helperText?: React.ReactNode;
}) => {
  const { data: certs, isLoading } = useGetCertificationsQuery();

  const initial = React.useMemo(
    () => certs?.filter((it) => values?.includes(it.id)),
    [certs, values]
  );

  return (
    <Autocomplete
      multiple
      fullWidth
      id="checkboxes-tags"
      options={certs || []}
      disableCloseOnSelect
      disabled={isLoading}
      ChipProps={{ color: "secondary" }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder="Search..."
          error={error}
          helperText={helperText}
        />
      )}
      loading={isLoading}
      onChange={(event: any, newValue: Certification[] | null) => {
        onChange(newValue?.map((it) => it.id));
      }}
      value={initial || []}
    />
  );
};
export default SelectCertifications;
