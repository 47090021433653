import React from "react";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useTitle } from "../../hooks/useTitle";
import { UploadFile } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useFilePicker } from "use-file-picker";
import { useAddProjectMutation } from "../../services/project.service";
import { toast } from "react-toastify";
import Papa from "papaparse";
import { useAddLocationMutation } from "../../services/location.service";
import { getAddressComponentFromGeocoder } from "../../utils/helper";
import { useGetCountriesQuery } from "../../services/country.service";
import { Project } from "../../types/project";
import { GMAP_API_KEY } from "../../configs/api";

const myTimezone = dayjs.tz.guess();

interface CreateProjectData {
  project_title: string;
  description: string;
  confidential_information: string;
  manager: string;
  start_date: string;
  timezone: string;
  is_private: string;
  type: string;
  hourly_rate: string;
  total_pay: string;
  approximate_hours_to_complete: string;
  mile_radius: string;
  skills: string;
  num_of_talent: string;

  location_id: string;
  address: string;
  primary_contact_name: string;
  primary_contact_phone_number: string;
  primary_contact_email: string;
  location_name: string;
  site_number: string;

  is_photo_required: string;
  tool_required: string;
  //covid_vaccine_required: string;
  background_check_required: string;
  drug_test_required: string;
}

const ProjectBulkUploadPage = () => {
  useTitle("Bulk Upload Work");

  const [loading, setLoading] = React.useState(false);
  const [addProject, { isLoading: addingProject }] = useAddProjectMutation();
  const [addLocation, { isLoading: addingLocation }] = useAddLocationMutation();
  const { data: countries } = useGetCountriesQuery();
  const [resultStrings, setResultStrings] = React.useState("");

  const [openFileSelector, { plainFiles, errors, clear }] = useFilePicker({
    multiple: false,
    accept: [".csv"],
    maxFileSize: 50,
    readFilesContent: false,
  });
  const [file, setFile] = React.useState<File | null>();

  React.useEffect(() => {
    errors?.[0]?.fileSizeTooSmall && toast.error("File size is too small!");
    errors?.[0]?.fileSizeToolarge && toast.error("File size is too large!");
    errors?.[0]?.readerError &&
      toast.error("Problem occured while reading file!");
    errors?.[0]?.maxLimitExceeded && toast.error("Too many files");
    errors?.[0]?.minLimitNotReached && toast.error("Not enought files");
  }, [errors]);

  const addLocationService = async (payload: any) => {
    const loc = await addLocation(payload).unwrap();
    return loc;
  };
  const addProjectService = async (payload: any) => {
    const project = await addProject(payload).unwrap();
    return project;
  };

  const doParse = (file: File) => {
    if (!file) return;
    // @ts-ignore
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      //The callback to execute when parsing is complete.
      complete: async function (results: {
        data: CreateProjectData[];
        errors: [];
        meta: {};
      }) {
        doUpload(results.data);
      },
    });
  };

  const doUpload = async (data: CreateProjectData[]) => {
    setLoading(true);
    setResultStrings("Loading..please wait");

    const results = await Promise.all(
      data.map(async (row, index) => {
        try {
          const {
            location_name,
            address,
            primary_contact_email,
            primary_contact_name,
            primary_contact_phone_number,
            site_number,
          } = row;

          const skillsArray = row.skills.split(",").map((skill) => {
            return Number(skill);
          });

          const {
            project_title,
            description,
            start_date,
            confidential_information,
            // num_of_talent,
            is_private,
            type,
            hourly_rate,
            approximate_hours_to_complete,
            mile_radius,
            manager,
            is_photo_required,
            tool_required,
            //covid_vaccine_required,
            background_check_required,
            drug_test_required,
            total_pay,
            timezone,
          } = row;

          let location_id = Number(row.location_id);

          // if location_id not defined, create new location based on google map
          if (!row.location_id) {
            const resp = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                address
              )}&key=${GMAP_API_KEY}`
            );
            const geoResult = await resp.json();
            //console.log("geoResult", geoResult);

            if (geoResult.status == "OK") {
              const res1 = geoResult.results[0];
              const { lat, lng } = res1.geometry.location;
              //console.log("latlng", res1.geometry.location);

              const addressComp = getAddressComponentFromGeocoder(
                res1.address_components
              );
              //console.log("addressComp", addressComp);
              const {
                city,
                country,
                postal_code,
                state,
                street,
                street_number,
              } = addressComp;

              const address_1 = `${street_number} ${street} `;
              const found = countries?.find(
                (it) => it.code.toLowerCase() == country.toLowerCase()
              );

              const addLocationRequest = {
                name: location_name,
                site_number,
                address_1,
                city,
                state,
                zip: postal_code,
                country: found?.id,
                lat,
                lng,
                primary_contact_name,
                primary_contact_phone_number,
                primary_contact_email,
              };
              const location = await addLocationService(addLocationRequest);
              location_id = location.id;
            } else {
              return `[row-${
                index + 1
              }] Get location FAILED. response from google: ${
                geoResult.status
              }`;
            }
          }

          const createProjectPayload = {
            title: project_title,
            description,
            start_date: dayjs(start_date).format("YYYY-MM-DD HH:mm"),
            timezone: timezone,
            manager: Number(manager),
            type,
            is_private: Number(is_private),
            num_of_talent: 1, //Number(num_of_talent),
            confidential_information,

            location: location_id,

            hourly_rate,
            total_pay: Number(total_pay),
            approximate_hours_to_complete: Number(
              approximate_hours_to_complete
            ),
            mile_radius: Number(mile_radius),
            skills: skillsArray,

            is_photo_required: is_photo_required?.toUpperCase() === "TRUE",
            tool_required: tool_required?.toUpperCase() === "TRUE",
            //covid_vaccine_required:
            //covid_vaccine_required?.toUpperCase() === "TRUE",
            background_check_required:
              background_check_required?.toUpperCase() === "TRUE",
            drug_test_required: drug_test_required?.toUpperCase() === "TRUE",
          };

          const project: Project = await addProjectService(
            createProjectPayload
          );
          return `[row-${index + 1}] Project #${project.id} - ${
            project.title
          } successfully created`;
        } catch (err) {
          return `[row-${index + 1}] Project creation failed. Error:${err}`;
        }
      })
    );
    setFile(null);
    clear(); //clear file
    toast.info("Upload completed.");
    setResultStrings(results.join("\n"));
    setLoading(false);
  };

  React.useEffect(() => {
    if (plainFiles?.length > 0) {
      const file = plainFiles[0];
      doParse(file);
    }
  }, [plainFiles]);

  return (
    <Box>
      <Card>
        <CardHeader title="" />
        <CardContent>
          <Typography variant="body1">
            Sample CSV file :{" "}
            <a
              target="_blank"
              href="https://firebasestorage.googleapis.com/v0/b/the-method-329619.appspot.com/o/template-bulk-upload-csv.csv?alt=media&token=35c95ccb-3b7a-4c2a-b59c-4ad595b50357"
            >
              Download
            </a>
          </Typography>
          <LoadingButton
            variant="contained"
            size="large"
            onClick={() => openFileSelector()}
            startIcon={<UploadFile />}
            sx={{ ml: 1, mt: 1 }}
            loading={loading}
          >
            Upload csv file
          </LoadingButton>

          {resultStrings.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography>{resultStrings}</Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};
export default ProjectBulkUploadPage;
