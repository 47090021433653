import { OAUTH_CLIENT_ID, OAUTH_CLIENT_SECRET } from "../configs/api";
import { Company } from "../types/company";
import { Manager, User } from "../types/users";
import { baseApi } from "./base";

export interface LoginResponse {
  token_type: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (req) => {
        return {
          url: "oauth/token",
          method: "POST",
          body: {
            username: req.username,
            password: req.password,
            grant_type: "password",
            client_id: OAUTH_CLIENT_ID,
            client_secret: OAUTH_CLIENT_SECRET,
            scope: "*",
          },
        };
      },
    }),
    loadUser: builder.query<Manager, void>({
      query: () => ({
        url: "api/me",
        method: "GET",
        params: {
          load: "photo,company,company.logo",
        },
      }),
    }),
    getUser: builder.query<Manager, void>({
      query: () => ({
        url: "api/me",
        method: "GET",
        params: {
          load: "photo,company,company.logo",
        },
      }),
    }),
    logout: builder.mutation<{ message: string }, void>({
      query: () => ({
        url: "logout",
        method: "POST",
      }),
    }),
    registerAsCompany: builder.mutation<
      User,
      Partial<Company> & {
        first_name: string;
        last_name: string;
        email: string;
        password: string;
      }
    >({
      query: (body) => ({
        url: "api/registers-as-company",
        method: "POST",
        body,
      }),
    }),
    forgotPassword: builder.mutation<{ message: string }, { email: string }>({
      query: (body) => ({
        url: "api/forgot-password",
        method: "POST",
        body,
      }),
    }),
    resetPassword: builder.mutation<
      User,
      { email: string; reset_code: string; password: string }
    >({
      query: (body) => ({
        url: "api/reset-password",
        method: "POST",
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useLoadUserQuery,
  useGetUserQuery,
  useLogoutMutation,
  useRegisterAsCompanyMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApi;
