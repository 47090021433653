import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import { Project, ProjectTask } from "../../types/project";
import LoadingButton from "@mui/lab/LoadingButton";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

interface ModalTaskFormProps {
  open: boolean;
  onClose: () => void;
  data: ProjectTask | null;
  onAdd?: (task: ProjectTask) => void;
}

const ModalTaskForm = ({ open, onClose, data, onAdd }: ModalTaskFormProps) => {
  const {
    handleSubmit,
    clearErrors,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (payload: any) => {
    if (!data) {
      payload.id = Date.now();
      if (onAdd) onAdd(payload);
      return;
    }
    // try {
    //   if (!data) await add(payload).unwrap();
    //   else {
    //     if (!payload.password) delete payload.password;
    //     payload.id = data?.id!;
    //     await edit(payload).unwrap();
    //   }
    //   onClose();
    // } catch (err) {
    //   onClose();
    // }
  };

  React.useEffect(() => {
    if (open) {
      setValue("name", data?.name);
      setValue("description", data?.description);
      // setValue("start_date", data?.start_date || null);
      clearErrors();
    }
  }, [data, open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">
          {!data ? "Create" : "Edit"} Task
        </DialogTitle>
        <DialogContent>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label="Task Name"
                value={value}
                fullWidth
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.name}
                helperText={!!errors.name && "This field is required"}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            rules={{ required: false }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label="Task Description"
                value={value}
                fullWidth
                minRows={3}
                multiline={true}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.description}
                helperText={!!errors.description && "This field is required"}
              />
            )}
          />
          {/* <Controller
            name="start_date"
            control={control}
            rules={{ required: false }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DesktopDateTimePicker
                sx={{ mt: 1, width: "100%" }}
                onChange={onChange}
                value={dayjs(value)}
                label="Start Time"
              />
            )}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton type="submit" variant="contained" loading={false}>
            {!data ? "Create" : "Update"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalTaskForm;
