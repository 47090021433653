import * as React from "react";
import {
  Avatar,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Stack,
  TextField,
  FormHelperText,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { MultiCheckbox } from "../../components/forms";
import { useForm, Controller } from "react-hook-form";
import {
  useLoginMutation,
  useRegisterAsCompanyMutation,
} from "../../services/auth.service";
import { Navigate, useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import workz360 from "../../images/workz360.png";
import { useGetBusinessVerticalsQuery } from "../../services/vertical.service";
import { toast } from "react-toastify";
import SelectCountry from "../../components/SelectCountry";
import SelectVertical from "../../components/SelectVertical";
import { geocode } from "../../utils/mapHelper";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://workz360.com/">
        Workz360
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function LoginLink(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Already have a Workz360 account? "}
      <Link variant="body2" href="/login">
        Log in
      </Link>
      {"."}
    </Typography>
  );
}

export default function SignupCompanyPage() {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = React.useState(false);
  const [doRegister, { isLoading }] = useRegisterAsCompanyMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (payload: any) => {
    if (payload.password != payload.confirm_password) {
      toast.error("Password and confirm password doesnt match!");
      return;
    }

    setSubmitting(true);
    // locate on map
    const address = `${payload.city}, ${payload.state} ${payload.zip}`;
    try {
      const resp = await geocode(address);
      if (resp.data.status != "OK") {
        toast.error("Unknown address");
        return;
      }
      const first = resp.data.results[0];
      const { lat, lng } = first.geometry.location;
      payload.lat = lat;
      payload.lng = lng;
    } catch (err: any) {
      toast.error(err.message);
      return;
    }

    //sanitize
    delete payload.confirm_password;
    delete payload.agree;
    payload.billing_country = payload.country;
    payload.billing_city = payload.city;
    payload.billing_state = payload.state;
    payload.billing_zip = payload.zip;
    payload.billing_email = payload.email;
    payload.primary_contact_name =
      `${payload.first_name} ${payload.last_name}`.trim();
    payload.primary_contact_phone_number = payload.phone_number;
    payload.primary_contact_email = payload.email;

    try {
      await doRegister(payload).unwrap();
      setSubmitting(false);

      navigate("/signup/completed", { replace: true });
    } catch (err: any) {
      setSubmitting(false);
      toast.error(err.message);
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(https://source.unsplash.com/744oGeqpxPQ)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            p: 2,
          }}
        >
          <Typography variant="h5" sx={{ mb: 2, textAlign: "center" }}>
            <img src={workz360} height={50} />
            <br />
            Register as Company
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 1 }}
          >
            <Controller
              name="name"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur } }) => (
                <TextField
                  fullWidth
                  size="medium"
                  label="Company Name"
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.name}
                  helperText={!!errors.name && "This field is required"}
                />
              )}
            />
            <Controller
              name="business_vertical"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <SelectVertical
                  onChange={onChange}
                  value={value}
                  size="medium"
                  sx={{ mt: 1 }}
                  error={!!errors.business_vertical}
                  helperText={
                    !!errors.business_vertical && "This field is required"
                  }
                />
              )}
            />
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Controller
                name="country"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <SelectCountry
                    onChange={onChange}
                    value={value}
                    size="medium"
                    sx={{ mt: 0 }}
                    error={!!errors.country}
                    helperText={!!errors.country && "This field is required"}
                  />
                )}
              />
              <Controller
                name="state"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur } }) => (
                  <TextField
                    fullWidth
                    size="medium"
                    label="State"
                    sx={{ mt: 0 }}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.state}
                    helperText={!!errors.state && "This field is required"}
                  />
                )}
              />
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Controller
                name="city"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur } }) => (
                  <TextField
                    fullWidth
                    size="medium"
                    label="City"
                    sx={{ mt: 0 }}
                    autoFocus
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.city}
                    helperText={!!errors.city && "This field is required"}
                  />
                )}
              />
              <Controller
                name="zip"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur } }) => (
                  <TextField
                    fullWidth
                    size="medium"
                    label="Zip Code"
                    sx={{ mt: 0 }}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.zip}
                    helperText={!!errors.zip && "This field is required"}
                  />
                )}
              />
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Controller
                name="first_name"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur } }) => (
                  <TextField
                    fullWidth
                    size="medium"
                    label="First Name"
                    sx={{ mt: 0 }}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.first_name}
                    helperText={!!errors.first_name && "This field is required"}
                  />
                )}
              />
              <Controller
                name="last_name"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur } }) => (
                  <TextField
                    fullWidth
                    size="medium"
                    label="Last Name"
                    sx={{ mt: 0 }}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.last_name}
                    helperText={!!errors.last_name && "This field is required"}
                  />
                )}
              />
              <Controller
                name="phone_number"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur } }) => (
                  <TextField
                    fullWidth
                    size="medium"
                    label="Phone number"
                    sx={{ mt: 0 }}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.phone_number}
                    helperText={
                      !!errors.phone_number && "This field is required"
                    }
                  />
                )}
              />
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Controller
                name="email"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur } }) => (
                  <TextField
                    fullWidth
                    size="medium"
                    label="Email Address"
                    sx={{ mt: 0 }}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.email}
                    helperText={!!errors.email && "This field is required"}
                  />
                )}
              />
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Controller
                name="password"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    fullWidth
                    label="Password"
                    type="password"
                    id="password"
                    size="medium"
                    sx={{ mt: 0 }}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.password}
                    helperText={!!errors.password && "This field is required"}
                  />
                )}
              />
              <Controller
                name="confirm_password"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    fullWidth
                    label="Confirm Password"
                    type="password"
                    size="medium"
                    sx={{ mt: 0 }}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.confirm_password}
                    helperText={
                      !!errors.confirm_password && "This field is required"
                    }
                  />
                )}
              />
            </Stack>
            <Controller
              name="agree"
              control={control}
              rules={{ required: true }}
              defaultValue={false}
              render={({ field }) => (
                <>
                  <FormControlLabel
                    control={<Checkbox {...field} />}
                    label="I agree with Workz360's Terms and Agreements"
                  />
                  {errors.agree && (
                    <FormHelperText style={{ color: "red" }}>
                      This field is required
                    </FormHelperText>
                  )}
                </>
              )}
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              loading={submitting}
            >
              Submit
            </LoadingButton>
            <LoginLink sx={{ mt: 3 }} />
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
