import React, { useEffect } from "react";
import { TextField, Autocomplete, InputAdornment } from "@mui/material";
import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete";
import { getAddressComponent } from "../utils/helper";
import { AddressComponent } from "../types/common";
import MapIcon from "@mui/icons-material/Map";
import SearchIcon from "@mui/icons-material/Search";

interface SelectMapAddressProps {
  label?: string;
  onChange: (address: AddressComponent, lat?: number, lng?: number) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  countryRestrictions?: string | Array<string>;
}

const SelectMapAddress = ({
  label = "Search Address",
  onChange,
  error,
  helperText,
}: SelectMapAddressProps): JSX.Element => {
  const { ready, suggestions, setValue, init } = usePlacesAutocomplete({
    callbackName: "initMap",
    requestOptions: {
      componentRestrictions: {
        country: "us",
      },
    },
    initOnMount: false,
    cache: 24 * 60 * 60,
    debounce: 300,
  });

  useEffect(() => {
    if (ready === false) {
      // console.log("init useplaces");
      init();
    }
  }, [ready]);

  useEffect(() => {
    if (
      suggestions.status === "INVALID_REQUEST" ||
      suggestions.status === "REQUEST_DENIED"
    ) {
      init();
    }
  }, [suggestions]);
  const handleSelect = async (
    address: google.maps.places.AutocompletePrediction
  ) => {
    const details = await getDetails({
      placeId: address.place_id,
      fields: [
        "address_components",
        "geometry",
        "types",
        "url",
        "formatted_address",
      ],
    });
    if (typeof details == "string") {
      alert(details);
      return;
    }

    const addrComponent = getAddressComponent(details);
    const lat = details?.geometry?.location?.lat();
    const lng = details?.geometry?.location?.lng();
    onChange(addrComponent, lat, lng);
  };

  //console.log("error", error);

  return (
    <Autocomplete
      freeSolo
      disableClearable
      options={suggestions.data}
      disabled={!ready}
      onChange={(event, value) => {
        if (typeof value == "string") return;
        handleSelect(value);
      }}
      onInputChange={(event, value) => {
        setValue(value);
      }}
      getOptionLabel={(prediction) => {
        // @ts-ignore
        const { description } = prediction;
        if (description) return `${description}`;
        return "?";
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          // value={value}
          label={label}
          InputProps={{
            ...params.InputProps,
            type: "search",
            startAdornment: (
              <InputAdornment position="start">
                <MapIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          error={error}
          helperText={helperText}
          color="warning"
        />
      )}
    />
  );
};
export default SelectMapAddress;
