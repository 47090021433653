import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material/Select";
import { useTitle } from "../../hooks/useTitle";
import HourLineChart from "./HourLineChart";
import SelectSimple from "../../components/SelectSimple";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import dayjs, { Dayjs } from "dayjs";
import isToday from "dayjs/plugin/isToday";
import {
  useGetTotalClientQuery,
  useGetTotalHourQuery,
  useGetTotalProjectQuery,
  useGetTotalSpentQuery,
} from "../../services/report.service";
import { formatMoney } from "../../utils/helper";
import {
  ArrowRightAltSharp,
  CheckCircleOutline,
  WorkOutline,
} from "@mui/icons-material";
import { useCompany } from "../../hooks/useCompany";
import { CompanyStatus } from "../../types/company";
import { useGetProjectsQuery } from "../../services/project.service";
import { Project, ProjectStatus } from "../../types/project";
import { DatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import ProjectNewsPanel from "./ProjectNewsPanel";
import TodaysProjects from "./TodaysProjects";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin

const initStartDate = dayjs().startOf("month").toDate();
const initEndDate = dayjs().toDate();

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);

interface StatisticCardProps {
  title: string;
  value: string;
  url?: string;
  color?: string;
}

const StatisticCard = (props: StatisticCardProps) => {
  const { title, value, url, color } = props;
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        background: `linear-gradient(${colors.green[100]}44, #fff)`,
      }}
    >
      <CardContent>
        <Typography
          sx={{ fontSize: 16 }}
          color={color ?? "text.secondary"}
          gutterBottom
        >
          {title}
        </Typography>
        <Typography variant="h4" component="div">
          {value}
        </Typography>
      </CardContent>
      {url && (
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button
            size="small"
            onClick={() => navigate(url)}
            endIcon={<ArrowRightAltSharp />}
          >
            More detail
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

const periodOptions = [
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
];

const DashboardPage = () => {
  const user = useCurrentUser();
  const company = useCompany();
  const navigate = useNavigate();
  useTitle(company?.name || "Dashboard");
  dayjs.tz.guess();
  const [hourPeriod, setHourPeriod] = React.useState("daily");
  const [costPeriod, setCostPeriod] = React.useState("daily");
  const [start, setStart] = React.useState<Dayjs | Date>(initStartDate);
  const [end, setEnd] = React.useState<Dayjs | Date>(initEndDate);
  // const { data: dataTotalClient } = useGetTotalClientQuery();
  const { data: dataTotalProject } = useGetTotalProjectQuery();
  const { data: dataTotalHour, isLoading: isLoadingTotalHour } =
    useGetTotalHourQuery(hourPeriod);
  const { data: dataTotalCost, isLoading: isLoadingTotalCost } =
    useGetTotalSpentQuery(costPeriod);
  const { data: dataProjects, isLoading: isLoadingDataProjects } =
    useGetProjectsQuery({
      pageSize: 200,
      sortField: `start_date`,
      sortMode: `desc`,
      statuses: "publish,scheduled,in-progress,completed,canceled",
    });

  const inProgressCount =
    dataProjects?.data.filter(
      (project) => project.status === ProjectStatus.IN_PROGRESS
    ).length || 0;

  const publishedCount =
    dataProjects?.data.filter(
      (project) => project.status === ProjectStatus.PUBLISHED
    ).length || 0;

  const scheduledCount =
    dataProjects?.data.filter(
      (project) => project.status === ProjectStatus.SCHEDULED
    ).length || 0;

  const canceledCount =
    dataProjects?.data.filter(
      (project) => project.status === ProjectStatus.CANCELED
    ).length || 0;

  const completedCount =
    dataProjects?.data.filter(
      (project) => project.status === ProjectStatus.COMPLETED
    ).length || 0;

  const handleHourPeriodChange = (event: SelectChangeEvent) => {
    setHourPeriod(event.target.value as string);
  };

  const handleCostPeriodChange = (event: SelectChangeEvent) => {
    setCostPeriod(event.target.value as string);
  };

  const dataHour = React.useMemo(() => {
    const newData: any[] = [];
    const data = dataTotalHour?.list || [];
    const typeData = hourPeriod;
    if (data && data?.length > 0) {
      if (typeData === "weekly") {
        data?.map((item) => {
          newData.push({
            name: `W${item.week}`,
            total: Number(item.total_hour || 0),
            week: `${dayjs()
              .set("month", item.month - 1)
              .set("year", item.year)
              .format("MMM YYYY")}`,
          });
        });
      } else if (typeData === "monthly") {
        data?.map((item) => {
          newData.push({
            name: `${dayjs()
              .set("month", item.month - 1)
              .format("MMM")}`,
            total: +Number(item.total_hour || 0),
            week: `${item.year}`,
          });
        });
      } else {
        data?.map((item) => {
          newData.push({
            name: dayjs(item.date).date().toString(),
            total: +Number(item.total_hour || 0),
            week: `W${item.week} - ${dayjs(item.date).format("MMM YYYY")}`,
          });
        });
      }
    }
    return newData;
  }, [dataTotalHour, hourPeriod]);

  const dataCost = React.useMemo(() => {
    const newData: any[] = [];
    const data = dataTotalCost?.list || [];
    const typeData = costPeriod;
    if (data && data?.length > 0) {
      if (typeData === "weekly") {
        data?.map((item) => {
          newData.push({
            name: `W${item.week}`,
            total: Number(item.total_spent || 0),
            week: `${dayjs()
              .set("month", item.month - 1)
              .set("year", item.year)
              .format("MMM YYYY")}`,
          });
        });
      } else if (typeData === "monthly") {
        data?.map((item) => {
          newData.push({
            name: `${dayjs()
              .set("month", item.month - 1)
              .format("MMM")}`,
            total: +Number(item.total_spent || 0),
            week: `${item.year}`,
          });
        });
      } else {
        data?.map((item) => {
          newData.push({
            name: dayjs(item.date).date().toString(),
            total: +Number(item.total_spent || 0),
            week: `W${item.week} - ${dayjs(item.date).format("MMM YYYY")}`,
          });
        });
      }
    }
    return newData;
  }, [dataTotalCost, costPeriod]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", mb: 0 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h5">
                Welcome Back, {user?.full_name} 👋
              </Typography>
              <Typography variant="caption">
                {user?.company?.name} - {dayjs().format("ddd, MMMM DD YYYY z")}
              </Typography>
              {/* <Typography variant="h5">Welcome to your Dashboard</Typography> */}
            </Box>
            <Button
              variant="contained"
              onClick={() => navigate(`/works/create`)}
              disabled={company?.status != CompanyStatus.ACTIVE}
            >
              + Create New Work
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {/* changed lg from 4 to 6 */}
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <StatisticCard
                title={`Balances`}
                value={`$${formatMoney(user?.company?.balance_decrypt || 0)}`}
                url="/finances"
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                      <Box sx={{ display: "flex", mb: 3 }}>
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography sx={{ fontSize: 20 }}>
                            Job Count
                          </Typography>
                        </Box>
                        {/* <LoadingButton
                          sx={{ height: 39, mt: 1 }}
                          variant="contained"
                          onClick={() => {
                            setStart(initStartDate);
                            setEnd(initEndDate);
                          }}
                        >
                          Reset Dates
                        </LoadingButton> */}
                        <Box sx={{ width: 16 }} />
                        {/* <DatePicker
                          label="Start"
                          format="MMM DD, YYYY"
                          value={dayjs(start)}
                          sx={{ mt: 1, width: 200 }}
                          onChange={(value) => setStart(value || new Date())}
                        />

                        <Box sx={{ ml: 1, mr: 1 }}>to</Box>
                        <DatePicker
                          label="End"
                          format="MMM DD, YYYY"
                          value={dayjs(end)}
                          sx={{ mt: 1, width: 200 }}
                          onChange={(value) => setEnd(value || new Date())}
                        /> */}
                      </Box>
                      <Box sx={{ display: "flex", mb: 3 }}>
                        <Grid item lg={5} md={5} sm={10} xs={10}>
                          <StatisticCard
                            title={`Open Jobs`}
                            value={`${publishedCount}`}
                            url="/works?t=1"
                            color="#0288D1"
                          />
                        </Grid>
                        <Box width={16} />
                        <Grid item lg={3} md={3} sm={6} xs={6}>
                          <StatisticCard
                            title={`Scheduled`}
                            value={`${scheduledCount}`}
                            url="/works?t=2"
                            color="#ED6C02"
                          />
                        </Grid>
                        <Box width={16} />
                        <Grid item lg={3} md={3} sm={6} xs={6}>
                          <StatisticCard
                            title={`In-Progress`}
                            value={`${inProgressCount}`}
                            url="/works?t=3"
                            color="#ED6C02"
                          />
                        </Grid>
                        <Box width={16} />
                        <Grid item lg={5} md={5} sm={10} xs={10}>
                          <StatisticCard
                            title={`Completed`}
                            value={`${completedCount}`}
                            url="/works?t=4"
                            color="#2E7D32"
                          />
                        </Grid>
                        <Box width={16} />
                        <Grid item lg={5} md={5} sm={10} xs={10}>
                          <StatisticCard
                            title={`Canceled`}
                            value={`${canceledCount}`}
                            url="/works?t=5"
                            color="#D32F2F"
                          />
                        </Grid>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            {/* changed lg from 4 to 6 */}
            <Grid item xs={12}>
              <Card
                style={{
                  border: "1px solid green",
                  boxShadow: "3px 3px 5px rgba (0, 0, 0, 0.2",
                }}
              >
                <CardHeader
                  title="Work Management Quick View"
                  avatar={<WorkOutline />}
                />
                <TodaysProjects
                  data={dataProjects?.data || []}
                  isLoading={isLoadingDataProjects}
                  emptyText={"No work for today"}
                />
              </Card>
            </Grid>
            {/* this is the card for uplift, uncomment it and fix spacing values on top, speciallu LG to 4 */}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card
            style={{
              border: "1px solid green",
              boxShadow: "3px 3px 5px rgba (0, 0, 0, 0.2",
            }}
          >
            <CardHeader
              title="Approval and Completion Statuses"
              avatar={<CheckCircleOutline />}
            />
            <ProjectNewsPanel
              data={dataProjects?.data || []}
              isLoading={isLoadingDataProjects}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h5">Chart Metrics</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container /*spacing={3*/>
            <Grid item /*lg={6} md={6} sm={6}*/ xs={12}>
              <Card
                style={{
                  border: "1px solid green",
                  boxShadow: "3px 3px 5px rgba (0, 0, 0, 0.2",
                }}
                sx={{ minWidth: 275 }}
              >
                <CardContent>
                  <Box sx={{ display: "flex", mb: 3 }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography sx={{ fontSize: 20 }}>Total Hours</Typography>
                    </Box>
                    <Box sx={{ minWidth: 120, mt: 0 }}>
                      <SelectSimple
                        title="Period"
                        data={periodOptions}
                        value={hourPeriod}
                        onChange={handleHourPeriodChange}
                      />
                    </Box>
                  </Box>
                  <HourLineChart
                    id="chartHour"
                    typeData={hourPeriod}
                    data={dataHour}
                    color={colors.green[500]}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container /*spacing={3*/>
            <Grid item /*lg={6} md={6} sm={6}*/ xs={12}>
              <Card
                style={{
                  border: "1px solid green",
                  boxShadow: "3px 3px 5px rgba (0, 0, 0, 0.2",
                }}
                sx={{ minWidth: 275 }}
              >
                <CardContent>
                  <Box sx={{ display: "flex", mb: 3 }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography sx={{ fontSize: 20 }}>Total Cost</Typography>
                    </Box>
                    <Box sx={{ minWidth: 120, mt: 0 }}>
                      <SelectSimple
                        title="Period"
                        data={periodOptions}
                        value={costPeriod}
                        onChange={handleCostPeriodChange}
                      />
                    </Box>
                  </Box>
                  <HourLineChart
                    id="chartCost"
                    typeData={costPeriod}
                    data={dataCost}
                    color={colors.lightBlue[500]}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default DashboardPage;
