import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridRowClassNameParams,
  GridValueGetterParams,
  GridToolbarContainer,
  GridToolbarExport,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
// import { Stack, Paper, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import NoResultsOverlay from "../../components/NoResultOverlay";
import { useTitle } from "../../hooks/useTitle";
import { useNavigate } from "react-router-dom";
import { useGetBalanceHistoriesQuery } from "../../services/finance.service";
import { BalanceHistory } from "../../types/finance";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import TopupModalForm from "./TopupModalForm";
import { capitalizeFirstLetter, formatMoney } from "../../utils/helper";
import { ArrowUpward } from "@mui/icons-material";
import { useCompany } from "../../hooks/useCompany";
import { CompanyStatus } from "../../types/company";
import useDebounce from "../../hooks/useDebounce";

const TableToolbar = ({ onSearch }: { onSearch: (query?: string) => void }) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);

  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <Box flex={1}>
        <TextField
          margin="dense"
          label="Enter to search.."
          value={query || ""}
          onChange={(e) => setQuery(e.target.value)}
        />
        {!!query && (
          <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
            Reset
          </Button>
        )}
      </Box>
      <Box>
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true, fileName: "Finances" }}
        />
      </Box>
    </GridToolbarContainer>
  );
};

const FinancesPage = () => {
  useTitle("Finances");
  const navigate = useNavigate();
  const user = useCurrentUser();
  const company = useCompany();

  const [topupFormModal, setTopupFormModal] = React.useState<{
    open: boolean;
  }>({
    open: false,
  });

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );

  const { data, isLoading, isFetching } = useGetBalanceHistoriesQuery({
    page,
    pageSize,
    load: "referenceModel",
    search: searchQuery,
  });
  const rowCount = React.useMemo(() => data?.meta.total, [data]);

  const rows: BalanceHistory[] | undefined = React.useMemo(
    () => data?.data,
    [data]
  );

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "created_at",
        headerName: "Time",
        minWidth: 250,
        sortingOrder: ["desc", "asc", null],
        sortable: true,
        filterable: true,
        valueGetter: (
          params: GridValueGetterParams<undefined, BalanceHistory>
        ) =>
          `${dayjs(params.row?.created_at).format("MMM DD, YYYY hh:mm A z")}`,
      },
      {
        field: "tx_notes",
        headerName: "Live Report",
        flex: 1,
        minWidth: 300,
        sortable: false,
        filterable: false,
        renderCell: (
          params: GridRenderCellParams<undefined, BalanceHistory>
        ) => (
          <Box>
            <Typography variant="body2">
              {capitalizeFirstLetter(params.row.notes)}
            </Typography>
            {!!params.row.user_comment && (
              <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                Reason: {params.row.user_comment}
              </Typography>
            )}
          </Box>
        ),
        disableExport: true,
      },
      {
        field: "notes",
        headerName: "Live Report",
        hide: true,
      },
      {
        field: "debit",
        headerName: "Debit",
        headerAlign: "center",
        type: "number",
        minWidth: 120,
        filterable: false,
        align: "right",
      },
      {
        field: "credit",
        headerName: "Credit",
        headerAlign: "center",
        type: "number",
        minWidth: 120,
        filterable: false,
        align: "right",
      },
      // {
      //   field: "balance",
      //   headerName: "Balance",
      //   type: "number",
      //   minWidth: 120,
      //   filterable: false,
      // },
      {
        field: "user_comment",
        headerName: "Comment",
        hide: true,
      },
    ],
    []
  );

  const getRowClassName = (params: GridRowClassNameParams) => {
    return !!params.row.deleted_at ? "deleted" : "";
  };

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const CustomToolbar = React.useMemo(
    () => () => <TableToolbar onSearch={handleSearch} />,
    [handleSearch]
  );

  return (
    <Box>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Card>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Current Balance
                </Typography>
                <Typography variant="h4" component="div">
                  {`$${formatMoney(user?.company?.balance_decrypt || 0)}`}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <Button onClick={() => navigate(`/finances/invoices`)}>
                  Invoice History
                </Button>
                <Button
                  onClick={() => setTopupFormModal({ open: true })}
                  variant="contained"
                  disabled={company?.status != CompanyStatus.ACTIVE}
                  startIcon={<ArrowUpward />}
                >
                  Top Up
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Paper sx={{ height: "70vh", mt: 2 }}>
        <DataGrid
          rows={rows || []}
          columns={columns}
          paginationMode="server"
          rowCount={rowCount}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          pageSize={pageSize}
          rowsPerPageOptions={[1000]}
          onPageSizeChange={(newSize) => setPageSize(newSize)}
          loading={isLoading || isFetching}
          components={{
            LoadingOverlay: LinearProgress,
            NoResultsOverlay: NoResultsOverlay,
            Toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          disableColumnFilter
          density="compact"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              borderTop: "1px solid #ddd",
            },
          }}
          getRowClassName={getRowClassName}
          rowHeight={64}
        />
        <TopupModalForm
          open={topupFormModal.open}
          onClose={() => setTopupFormModal({ open: false })}
        />
      </Paper>
    </Box>
  );
};
export default FinancesPage;
