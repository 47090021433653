import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from "@mui/material";
import { ServerDocument } from "../../../types/common";
import dayjs from "dayjs";
import { formatWithTimezone } from "../../../utils/dateHelper";

const PhotoModal = ({
  open,
  onClose,
  documents,
}: {
  open: boolean;
  onClose: () => void;
  documents: Array<ServerDocument>;
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">Work Completion Photos</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            flexWrap: "wrap",
          }}
        >
          {documents.map((it) => (
            <Paper>
              <a href={it.original_url} target="_blank">
                <img src={it.original_url} width="250" />
              </a>
              <Box textAlign={"center"}>
                <Typography variant="caption">
                  {formatWithTimezone(it.created_at)}
                </Typography>
              </Box>
            </Paper>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
export default PhotoModal;
