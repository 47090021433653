import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingIcon from "@mui/icons-material/Settings";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PeopleIcon from "@mui/icons-material/People";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AddchartOutlined,
  AddCircle,
  ApprovalSharp,
  ArticleOutlined,
  CreateRounded,
  CalendarMonthOutlined,
  RequestQuoteOutlined,
  ViewListOutlined,
} from "@mui/icons-material";
import { useCompany } from "../hooks/useCompany";
import { CompanyStatus } from "../types/company";

const SidebarMenu = () => {
  const navigate = useNavigate();
  const [jobExpanded, setJobExpanded] = React.useState(false);
  const [talentExpanded, setTalentExpanded] = React.useState(false);
  const [reportExpanded, setReportExpanded] = React.useState(false);
  const [settingsExpanded, setSettingsExpanded] = React.useState(false);
  const company = useCompany();

  const { pathname } = useLocation();
  const page = React.useMemo(() => {
    const parts = pathname.split("/");
    let page = "?";
    if (parts.length >= 3) page = parts[1] + "/" + parts[2];
    else if (parts.length >= 2) page = parts[1];
    return page;
  }, [pathname]);

  // console.log("page", page);
  return (
    <List component="nav">
      {/* <ListItemText
        primary="Main Menu"
        primaryTypographyProps={{
          fontWeight: 200,
          fontSize: 28,
          align: "center",
          style: { color: "green" },
        }}
      /> */}
      <ListItemButton
        onClick={() => navigate("/dashboard")}
        selected={page == "dashboard"}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton onClick={() => setJobExpanded(!jobExpanded)}>
        <ListItemIcon>
          <HomeRepairServiceIcon />
        </ListItemIcon>
        <ListItemText
          primary="Work Menu"
          primaryTypographyProps={{ fontWeight: 600 }}
        />
        {jobExpanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={jobExpanded} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{ backgroundColor: "#eee" }}
        >
          <ListItemButton
            onClick={() => navigate("/works/create")}
            disabled={company?.status != CompanyStatus.ACTIVE}
            selected={page == "works/create"}
          >
            <ListItemIcon>
              <CreateRounded />
            </ListItemIcon>
            <ListItemText primary="Create New Work" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/calendar")}
            selected={page == "calendar"}
          >
            <ListItemIcon>
              <CalendarMonthOutlined />
            </ListItemIcon>
            <ListItemText primary="Work Calendar" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/works")}
            selected={page == "works"}
          >
            <ListItemIcon>
              <ApprovalSharp />
            </ListItemIcon>
            <ListItemText primary="Work Management" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/locations")}
            selected={page == "locations"}
          >
            <ListItemIcon>
              <LocationOnIcon />
            </ListItemIcon>
            <ListItemText primary="Locations" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/works/templates")}
            selected={page == "works/templates"}
          >
            <ListItemIcon>
              <ArticleOutlined />
            </ListItemIcon>
            <ListItemText primary="Templates" />
          </ListItemButton>
        </List>
      </Collapse>
      {/* <ListItemButton
        onClick={() => navigate("/clients")}
        selected={page == "clients"}
      >
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText primary="Customer Clients" />
      </ListItemButton> */}
      <ListItemButton onClick={() => setTalentExpanded(!talentExpanded)}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <div>
              Independent <br />
              Professionals <br />
            </div>
          }
          primaryTypographyProps={{ fontWeight: 600 }}
        />
        {talentExpanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={talentExpanded} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{ backgroundColor: "#eee" }}
        >
          <ListItemButton
            onClick={() => navigate("/independent-professionals")}
            selected={page == "independent-professionals"}
          >
            <ListItemIcon>
              <ViewListOutlined />
            </ListItemIcon>
            <ListItemText primary="Show List" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/independent-professionals/map")}
            selected={page == "independent-professionals/map"}
          >
            <ListItemIcon>
              <LocationOnIcon />
            </ListItemIcon>
            <ListItemText primary="Show Map" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/independent-professionals/favorites")}
            selected={page == "independent-professionals/favorites"}
          >
            <ListItemIcon>
              <FavoriteIcon />
            </ListItemIcon>
            <ListItemText primary="Favorites" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton onClick={() => setReportExpanded(!reportExpanded)}>
        <ListItemIcon>
          <AddchartOutlined />
        </ListItemIcon>
        <ListItemText
          primary="Finances"
          primaryTypographyProps={{ fontWeight: 600 }}
        />
        {reportExpanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={reportExpanded} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{ backgroundColor: "#eee" }}
        >
          <ListItemButton
            onClick={() => navigate("/finances")}
            selected={page.startsWith("finances")}
          >
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText primary="General Finances" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/reports/finance-report")}
            selected={page == "reports/finance-report"}
          >
            <ListItemIcon>
              <RequestQuoteOutlined />
            </ListItemIcon>
            <ListItemText primary="Financial Report" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton onClick={() => setSettingsExpanded(!settingsExpanded)}>
        <ListItemIcon>
          <SettingIcon />
        </ListItemIcon>
        <ListItemText
          primary="Settings"
          primaryTypographyProps={{ fontWeight: 600 }}
        />
        {settingsExpanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={settingsExpanded} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{ backgroundColor: "#eee" }}
        >
          <ListItemButton
            onClick={() => navigate("/managers")}
            selected={page == "managers"}
          >
            <ListItemIcon>
              <AddCircle />
            </ListItemIcon>
            <ListItemText primary="Add Managers" />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
};
export default SidebarMenu;
