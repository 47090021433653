import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  LinearProgress,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import NoResultsOverlay from "../../components/NoResultOverlay";
import useDebounce from "../../hooks/useDebounce";
import { useTitle } from "../../hooks/useTitle";
import { useGetFinanceReportQuery } from "../../services/report.service";
import { FinancialReport } from "../../types/report";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { useGetProjectsQuery } from "../../services/project.service";

const initStartDate = dayjs().startOf("month").toDate();
const initEndDate = dayjs().toDate();

const TableToolbar = ({
  onFilter,
}: {
  onFilter: (startDate: Dayjs | Date, endDate: Dayjs | Date) => void;
}) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const [start, setStart] = React.useState<Dayjs | Date>(initStartDate);
  const [end, setEnd] = React.useState<Dayjs | Date>(initEndDate);

  React.useEffect(() => {
    onFilter(start, end);
  }, [start, end]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <DatePicker
        label="Start"
        format="MMM DD, YYYY"
        value={dayjs(start)}
        sx={{ mt: 1, width: 200 }}
        onChange={(value) => setStart(value || new Date())}
      />

      <Box sx={{ ml: 1, mr: 1 }}>to</Box>
      <DatePicker
        label="End"
        format="MMM DD, YYYY"
        value={dayjs(end)}
        sx={{ mt: 1, width: 200 }}
        onChange={(value) => setEnd(value || new Date())}
      />

      {!!query && (
        <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
      <Stack style={{ flex: 1 }} direction="row" justifyContent="flex-end">
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true, fileName: "Finance Report" }}
        />
      </Stack>
    </GridToolbarContainer>
  );
};

const FinanceReportPage = () => {
  useTitle("Finance Report");
  const { data: dataProjects, isLoading: isLoadingDataProjects } =
    useGetProjectsQuery({
      pageSize: 1000,
    });
  const [pageSize, setPageSize] = React.useState(100);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [filterDate, setFilterDate] = React.useState<
    [Dayjs | Date, Dayjs | Date]
  >([initStartDate, initEndDate]);

  const { data, isLoading, isFetching } = useGetFinanceReportQuery({
    start_date: dayjs(filterDate[0]).format("YYYY-MM-DD"),
    end_date: dayjs(filterDate[1]).format("YYYY-MM-DD"),
  });
  const rowCount = React.useMemo(() => data?.length, [data]);

  const rows: GridRowsProp | undefined = React.useMemo(
    () =>
      data?.map((it, _id) => ({
        ...it,
        id: _id,
      })),
    [data]
  );

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "job",
        headerName: "Work ID# - Work Title",
        minWidth: 300,
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `#${params.row.job_id} ${params.row.title}`,
      },
      {
        field: "location",
        headerName: "Work Location",
        minWidth: 200,
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `${params.row.location_name}`,
      },
      // {
      //   field: "site_number",
      //   headerName: "Site Name/#",
      //   minWidth: 150,
      //   valueGetter: (
      //     params: GridValueGetterParams<undefined, FinancialReport>
      //   ) => `#${params.row?.site_number}`,
      // },
      {
        field: "start_date",
        headerName: "Start",
        minWidth: 230,
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) =>
          `${dayjs(params.row?.start_date).format("MMM DD, YYYY hh:mm A z")}`,
      },
      // {
      //   field: "percentage_uplift",
      //   headerName: "Company Uplift(%)",
      // },
      {
        field: "type",
        headerName: "Payment Type",
        minWidth: 120,
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `${params.row.type?.toUpperCase()}`,
      },
      {
        field: "talent_name",
        headerName: "Independent Professional",
        minWidth: 260,
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `#${params.row.talent_id} ${params.row.talent_name}`,
      },
      {
        field: "manager_name",
        headerName: "Manager",
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => {
          const project = dataProjects?.data.find(
            (dataProject) => dataProject.id === params.row.job_id
          );
          if (project?.manager === null) {
            // console.log(project);
            return "-";
          }
          return isLoadingDataProjects
            ? "loading..."
            : `${project?.manager?.full_name}`;
        },
      },
      {
        field: "price",
        headerName: "Rate/Price",
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) =>
          `${
            params.row.type == "hourly"
              ? `$${params.row.rate_hour || 0}/hr`
              : `$${params.row.fix_price || 0}`
          }`,
      },
      {
        field: "hours",
        headerName: "Est. Hours",
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `${params.row.approximate_hours_to_complete}`,
      },
      {
        field: "total_working",
        headerName: "Hours On Site",
        headerAlign: "center",
        align: "center",
      },
      {
        field: "total_earning",
        headerName: "Labor Cost",
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `$${Number(params.row.total_earning)?.toFixed(2) || "0"}`,
      },
      // {
      //   field: "total_project_uplift",
      //   headerName: "Ticket Fee",
      //   align: "right",
      //   valueGetter: (
      //     params: GridValueGetterParams<undefined, FinancialReport>
      //   ) => `$${params.row.total_project_uplift?.toFixed(2) || ""}`,
      // },
      {
        field: "total_additional_hours",
        headerName: "Additional Hours",
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `$${params.row.total_additional_hours?.toFixed(2) || "0"}`,
      },
      // {
      //   field: "total_additional_hours_uplift",
      //   headerName: "AH-Fee",
      //   align: "right",
      //   valueGetter: (
      //     params: GridValueGetterParams<undefined, FinancialReport>
      //   ) => `$${params.row.total_additional_hours_uplift?.toFixed(2) || ""}`,
      // },
      {
        field: "total_reimbursement",
        headerName: "Reimbursement",
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `$${params.row.total_reimbursement?.toFixed(2) || "0"}`,
      },
      // {
      //   field: "total_reimbursement_uplift",
      //   headerName: "R-Fee",
      //   align: "right",
      //   valueGetter: (
      //     params: GridValueGetterParams<undefined, FinancialReport>
      //   ) => `$${params.row.total_reimbursement_uplift?.toFixed(2) || ""}`,
      // },
      {
        field: "total_bonus",
        headerName: "Bonus",
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `$${params.row.total_bonus?.toFixed(2) || "0"}`,
      },
      {
        field: "income",
        headerName: "P-Fees",
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `$${Number(params.row.fixed_price_fee)?.toFixed(2) || ""}`,
      },
      // {
      //   field: "total_bonus_uplift",
      //   headerName: "B-Fee",
      //   align: "right",
      //   valueGetter: (
      //     params: GridValueGetterParams<undefined, FinancialReport>
      //   ) => `$${params.row.total_bonus_uplift?.toFixed(2) || ""}`,
      // },
      // {
      //   field: "payroll",
      //   headerName: "Payments",
      //   align: "right",
      //   minWidth: 120,
      //   valueGetter: (
      //     params: GridValueGetterParams<undefined, FinancialReport>
      //   ) =>
      //     `$${
      //       Number(
      //         (params.row.total_earning || 0) +
      //           (params.row.total_bonus || 0) +
      //           (params.row.total_reimbursement || 0) +
      //           (params.row.total_additional_hours || 0)
      //       )?.toFixed(2) || ""
      //     }`,
      // },
      {
        field: "payroll",
        headerName: "Total Cost",
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => {
          const totalEarning = Number(params.row.total_earning) || 0;
          const totalBonus = Number(params.row.total_bonus) || 0;
          const totalReimbursement =
            Number(params.row.total_reimbursement) || 0;
          const totalAdditionalHours =
            Number(params.row.total_additional_hours) || 0;
          const total_project_uplift = Number(params.row.fixed_price_fee) || 0;

          const total =
            totalEarning +
            totalBonus +
            totalReimbursement +
            totalAdditionalHours +
            total_project_uplift;

          return `$${total.toFixed(2)}`;
        },
      },
      // {
      //   field: "gross_revenue",
      //   headerName: "G-Revenue",
      //   align: "right",
      //   minWidth: 120,
      //   valueGetter: (
      //     params: GridValueGetterParams<undefined, FinancialReport>
      //   ) =>
      //     `$${
      //       Number(
      //         (params.row.total_earning || 0) +
      //           (params.row.total_bonus || 0) +
      //           (params.row.total_reimbursement || 0) +
      //           (params.row.total_additional_hours || 0) +
      //           (params.row.fixed_price_fee || 0)
      //       )?.toFixed(2) || ""
      //     }`,
      // },
      // {
      //   field: "income",
      //   headerName: "Platform Fee",
      //   align: "right",
      //   minWidth: 100,
      //   valueGetter: (
      //     params: GridValueGetterParams<undefined, FinancialReport>
      //   ) =>
      //     `${
      //       Number(
      //         params.row.total_project_uplift +
      //           params.row.total_bonus_uplift +
      //           params.row.total_reimbursement_uplift +
      //           params.row.total_additional_hours_uplift
      //       )?.toFixed(2) || ""
      //     }`,
      // },
    ],
    [dataProjects]
  );

  const handleFilter = React.useCallback(
    (start: Dayjs | Date, end: Dayjs | Date) => {
      setFilterDate([start, end]);
    },
    []
  );

  const CustomToolbar = React.useMemo(
    () => () => <TableToolbar onFilter={handleFilter} />,
    [handleFilter]
  );

  return (
    <Box sx={{ bgcolor: "background.paper", width: "auto" }}>
      <Paper style={{ height: "85vh" }}>
        <DataGrid
          rows={rows || []}
          columns={columns}
          // paginationMode="server"
          rowCount={rowCount}
          // page={page}
          // onPageChange={(newPage) => setPage(newPage)}
          pageSize={pageSize}
          rowsPerPageOptions={[100]}
          onPageSizeChange={(newSize) => setPageSize(newSize)}
          sortModel={sortModel}
          // sortingMode="server"
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          loading={isLoading || isFetching || isLoadingDataProjects}
          components={{
            LoadingOverlay: LinearProgress,
            NoResultsOverlay: NoResultsOverlay,
            Toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          disableColumnFilter
          density="compact"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              borderTop: "1px solid #ddd",
            },
          }}
        />
      </Paper>
    </Box>
  );
};
export default FinanceReportPage;
