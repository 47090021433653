import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import {
  useForgotPasswordMutation,
  useResetPasswordMutation,
} from "../../services/auth.service";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import workz360 from "../../images/workz360.png";
import { toast } from "react-toastify";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://workz360.com/">
        Workz360
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function ResetPasswordPage() {
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const user = useCurrentUser();
  const navigate = useNavigate();
  const { email } = useParams();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: { reset_code: "", password: "" },
  });

  // @ts-ignore
  const onSubmit = async (data: any) => {
    try {
      const { reset_code, password } = data;
      await resetPassword({
        email: email || "",
        reset_code,
        password,
      }).unwrap();
      toast.success("Password succesfully resetted. Please try re-login");
      navigate("/login");
    } catch (err: any) {
      toast.error(err);
    }
  };

  if (!!user) return <Navigate to="/dashboard" replace={true} />;

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(https://source.unsplash.com/sMKUYIasyDM)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={workz360} height={48} />
          <Typography
            variant="body1"
            sx={{ letterSpacing: 0.5, textAlign: "center", mt: 2 }}
          >
            Enter the code sent to your email:
          </Typography>
          <hr />
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              fullWidth
              id="email"
              size="medium"
              label="Email Address"
              autoComplete="email"
              value={email}
              disabled={true}
            />
            <Controller
              name="reset_code"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  id="email"
                  size="medium"
                  label="Reset Code"
                  autoFocus
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.reset_code}
                  helperText={!!errors.reset_code && "This field is required"}
                />
              )}
            />{" "}
            <Typography variant="caption">
              Enter the code sent to your email at {email}
            </Typography>
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  id="email"
                  size="medium"
                  label="New Password"
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.password}
                  helperText={!!errors.password && "This field is required"}
                />
              )}
            />
            <Typography variant="caption">
              The password must have at least 8 characters, at least 1 digit(s),
              at least 1 upper case letter(s)
            </Typography>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              loading={isLoading}
            >
              Reset My Password
            </LoadingButton>
            <Grid container>
              <Grid item xs>
                <Link href="/login" variant="body2">
                  Back to Login
                </Link>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
