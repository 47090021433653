import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import { useLoginMutation } from "../../services/auth.service";
import { Navigate, useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import workz360 from "../../images/workz360.png";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://workz360.com/">
        Workz360
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function SignupLink(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Don't have an account? "}
      <Link variant="body2" href="/signup" sx={{ fontWeight: "600" }}>
        Sign Up
      </Link>
    </Typography>
  );
}

export default function LoginPage() {
  const [login, { isLoading }] = useLoginMutation();
  const user = useCurrentUser();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm();

  // @ts-ignore
  const onSubmit = async (data: any) => {
    const { email, password } = data;
    await login({ username: email, password });
  };

  if (!!user) return <Navigate to="/dashboard" replace={true} />;

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(https://source.unsplash.com/sMKUYIasyDM)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Workz360 Portal
          </Typography> */}
          <img src={workz360} height={48} />
          <Typography component="h1" variant="h6" sx={{ letterSpacing: 0.5 }}>
            Customer Portal
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 1 }}
          >
            <Controller
              name="email"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  id="email"
                  size="medium"
                  label="Email Address"
                  autoComplete="email"
                  autoFocus
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.email}
                  helperText={!!errors.email && "This field is required"}
                />
              )}
            />
            <Controller
              name="password"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="Password"
                  type="password"
                  id="password"
                  size="medium"
                  autoComplete="current-password"
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.password}
                  helperText={!!errors.password && "This field is required"}
                />
              )}
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              loading={isLoading}
            >
              Sign In
            </LoadingButton>
            <Grid container>
              <Grid item xs>
                <Link href="/forgot-password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
            <SignupLink sx={{ mt: 3 }} />
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
