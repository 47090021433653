import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { RootState } from "../../app/store";
import { setTalentFilter } from "../../app/uiSlice";
import SelectSkills from "../../components/SelectSkills";
import useDebounce from "../../hooks/useDebounce";
import SelectVerticals from "../../components/SelectVerticals";
import SelectSimple from "../../components/SelectSimple";
import { UserWorkStatus } from "../../types/users";
import SelectState from "../../components/SelectState";
import SelectCertifications from "../../components/SelectCertification";
interface ModalTalentFilterProps {
  open: boolean;
  onClose: () => void;
}

const workStatuses = [
  { value: UserWorkStatus.PART_TIME, label: "Part Time Professional" },
  { value: UserWorkStatus.SOLE_PROPRIETOR, label: "Sole Proprietor" },
  { value: UserWorkStatus.ENTREPRENEUR, label: "Entrepreneur" },
];

const ModalTalentFilter = ({ open, onClose }: ModalTalentFilterProps) => {
  const filter = useAppSelector((state: RootState) => state.ui.talentFilter);
  const dispatch = useAppDispatch();

  const [city, setCity] = React.useState(filter.city);
  const [state, setState] = React.useState(filter.state);
  const [workStatus, setWorkStatus] = React.useState(filter.work_status);

  const handleReset = React.useCallback(() => {
    // TODO: still triggered double refresh
    setCity("");
    setState("");
    setWorkStatus("");
    dispatch(setTalentFilter({}));
  }, [dispatch]);

  const debounceCity = useDebounce(city, 500);

  React.useEffect(() => {
    dispatch(setTalentFilter({ ...filter, city: debounceCity }));
  }, [dispatch, debounceCity]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <DialogTitle id="alert-dialog-title">Filter By</DialogTitle>
      <DialogContent>
        <Box>
          <SelectVerticals
            onChange={(ids) =>
              dispatch(setTalentFilter({ ...filter, business_verticals: ids }))
            }
            values={filter.business_verticals || []}
          />
          <SelectCertifications
            onChange={(ids) =>
              dispatch(setTalentFilter({ ...filter, certificates: ids }))
            }
            values={filter.certificates || []}
          />
          <SelectSkills
            onChange={(ids) =>
              dispatch(setTalentFilter({ ...filter, skills: ids }))
            }
            values={filter.skills || []}
          />
          <SelectSimple
            title="Work Status"
            data={workStatuses}
            value={workStatus || ""}
            onChange={(e) => {
              setWorkStatus(e.target.value);
              dispatch(
                setTalentFilter({ ...filter, work_status: e.target.value })
              );
            }}
            sx={{ mt: 2 }}
          />
          <SelectState
            label="State"
            value={filter.state || ""}
            onChange={(value) =>
              dispatch(setTalentFilter({ ...filter, state: value }))
            }
          />
          <TextField
            InputLabelProps={{ style: { fontSize: 14 } }}
            //InputProps={{ style: {fontSize: 14}}}
            label="City"
            value={city}
            fullWidth
            onChange={(e) => setCity(e.target.value)}
          />
          {/* <TextField
            InputLabelProps={{ style: { fontSize: 14 } }}
            //InputProps={{ style: {fontSize: 14}}}
            label="State"
            value={state}
            fullWidth
            onChange={(e) => setState(e.target.value)}
          /> */}
          {/* <SelectCountry
            onChange={(id) =>
              dispatch(setTalentFilter({ ...filter, country: id }))
            }
            value={filter.country || 0}
          /> */}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset}>Reset</Button>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalTalentFilter;
