import { useState } from "react";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
} from "date-fns";
import "./styles.css";
import ClockIcon from "@mui/icons-material/Timer";
import { Project, ProjectStatus } from "../../../types/project";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import { Timer, TimerOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
const WeeklyCalendar = ({ data }: { data: Project[] | undefined }) => {
  const navigate = useNavigate();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const weekStartIndex = 0;
  const changeMonthHandle = (btnType: "prev" | "next") => {
    if (btnType === "prev") {
      setCurrentMonth(subMonths(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentMonth(addMonths(currentMonth, 1));
    }
  };

  const changeWeekHandle = (btnType: "prev" | "next") => {
    //console.log("current week", currentWeek);
    if (btnType === "prev") {
      //console.log(subWeeks(currentMonth, 1));
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      //console.log(addWeeks(currentMonth, 1));
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };

  const onDateClickHandle = (day: any) => {
    setSelectedDate(day);
    // showDetailsHandle(dayStr);
  };

  const renderHeader = () => {
    const dateFormat = "MMM yyyy";
    // console.log("selected day", selectedDate);
    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <div className="icon" onClick={() => changeWeekHandle("prev")}>
            chevron_left
          </div>
        </div>
        <div className="col col-center">
          Week {currentWeek} - <span>{format(currentMonth, dateFormat)}</span>
        </div>
        <div className="col col-end">
          <div className="icon" onClick={() => changeWeekHandle("next")}>
            chevron_right
          </div>
        </div>
      </div>
    );
  };
  const renderDays = () => {
    const dateFormat = "EEE";
    const days = [];
    let startDate = startOfWeek(currentMonth, { weekStartsOn: weekStartIndex });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };
  const getTodaysjobs = (todaysDate: Date): Project[] => {
    const list = data?.filter((project: Project) => {
      const startDate = dayjs(project.start_date);
      const projectDate = startDate.get("date");
      const projectYear = startDate.get("year");
      const projectMonth = startDate.get("month");
      return (
        (project.status === ProjectStatus.SCHEDULED ||
          project.status === ProjectStatus.IN_PROGRESS ||
          project.status === ProjectStatus.PUBLISHED) &&
        todaysDate.getDate() == projectDate &&
        todaysDate.getMonth() == projectMonth &&
        todaysDate.getFullYear() == projectYear
      );
    });
    return list || [];
  };
  function renderJobs(day: Date) {
    return getTodaysjobs(day).map((project) => {
      let bgColor;
      if (project.status === ProjectStatus.PUBLISHED) {
        bgColor = "#0288D1";
      } else if (project.status === ProjectStatus.SCHEDULED) {
        bgColor = "#ED6C02";
      } else if (project.status === ProjectStatus.IN_PROGRESS) {
        bgColor = "#ED6C02";
      }
      // if (selectedJob === project.id) {
      return (
        <div
          onClick={() => navigate(`/works/${project?.id}`)}
          style={{
            top: 24,
            width: "100%",
            cursor: "pointer",
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 8,
            paddingRight: 8,
            margin: 2,
            borderRadius: 8,
            backgroundColor: bgColor,
            color: "white",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: 14,
              fontWeight: "500",
              flexDirection: "row",
              display: "flex",
            }}
            onClick={() => {
              // if (selectedJob === project.id) {
              //   setSelectedJob(-1);
              // } else {
              //   setSelectedJob(project.id);
              // }
            }}
          >
            <ClockIcon />
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                lineHeight: 1.8,
                marginLeft: 4,
              }}
            >
              {dayjs(project.start_date).format("hh mm A z")}
            </div>
          </div>
          <div style={{ fontWeight: "bold" }} onClick={() => {}}>
            {project.title}
          </div>
        </div>
      );
      // }
      return (
        <div
          style={{
            zIndex: 99,
            cursor: "pointer",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 8,
            paddingRight: 8,
            margin: 2,
            borderRadius: 8,
            backgroundColor: bgColor,
            color: "white",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            // if (selectedJob === project.id) {
            //   setSelectedJob(-1);
            // } else {
            //   setSelectedJob(project.id);
            // }
          }}
        >
          {project.title}
        </div>
      );
    });
  }
  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, {
      weekStartsOn: weekStartIndex,
    });
    const endDate = lastDayOfWeek(currentMonth, {
      weekStartsOn: weekStartIndex,
    });
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`col cell ${
              isSameDay(day, new Date())
                ? "today"
                : isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            key={day.toString()}
            // onClick={() => {
            //   const dayStr = format(cloneDay, "ccc dd MMM yy");
            //   // onDateClickHandle(cloneDay, dayStr);
            //   onDateClickHandle(cloneDay);
            // }}
          >
            <span className="number">{formattedDate}</span>
            {/* <span className="bg">{formattedDate}</span> */}
            <Box sx={{ height: 40 }} />
            {/* <span className="bg">{formattedDate}</span> */}
            {renderJobs(day)}
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="row" key={day.toString()}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };
  const renderFooter = () => {
    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <div className="icon" onClick={() => changeWeekHandle("prev")}>
            prev week
          </div>
        </div>
        <div>{currentWeek}</div>
        <div className="col col-end" onClick={() => changeWeekHandle("next")}>
          <div className="icon">next week</div>
        </div>
      </div>
    );
  };
  return (
    <div className="weekly_calendar">
      {renderHeader()}
      {renderDays()}
      {renderCells()}
      {/* {renderFooter()} */}
    </div>
  );
};

export default WeeklyCalendar;
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */
