import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import { Customer } from "../../types/customer";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddCustomerMutation,
  useEditCustomerMutation,
  useGetCustomerByIdQuery,
} from "../../services/customer.service";
import { useTitle } from "../../hooks/useTitle";
import { Controller, useForm } from "react-hook-form";
import useScroll from "../../hooks/useScroll";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import SelectCountry from "../../components/SelectCountry";
import SelectMapAddress from "../../components/SelectMapAddress";
import { AddressComponent } from "../../types/common";
import { useGetCountriesQuery } from "../../services/country.service";
import CoordinateInfo from "../../components/CoordinateInfo";

const ClientEditPage = () => {
  const { customerId } = useParams();
  const navigate = useNavigate();
  const [showAddress, setShowAddress] = React.useState(false);

  const [scrollToBasic, basicRef] = useScroll();
  const [scrollToAddress, addressRef] = useScroll();
  const [scrollToSocial, socialRef] = useScroll();

  const { data, isLoading } = useGetCustomerByIdQuery(parseInt(customerId!), {
    skip: !customerId,
  });
  const [add, { isLoading: adding }] = useAddCustomerMutation();
  const [edit, { isLoading: updating }] = useEditCustomerMutation();
  const { data: countries } = useGetCountriesQuery();

  useTitle(!!data ? "Edit Client" : "Create Client");

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      if (!data) {
        const customer: Customer = await add(payload).unwrap();
        toast.success("Client created");
        navigate(`/clients/${customer.id}`, { replace: true });
      } else {
        payload.id = data.id;
        await edit(payload).unwrap();
        toast.success("Client updated");
        navigate(`/clients/${data.id}`, { replace: true });
      }
    } catch (err) {}
  };

  const handleMapAddressChange = (
    address: AddressComponent,
    lat?: number,
    lng?: number
  ) => {
    setValue(
      "physical_address_1",
      `${address.street} ${address.street_number}`
    );
    setValue("city", address.city);
    setValue("state", address.state);
    setValue("zip", address.postal_code);
    setValue("lat", lat);
    setValue("lng", lng);
    const found = countries?.find(
      (it) => it.code.toLowerCase() == address.country.toLowerCase()
    );
    setValue("country", found?.id);
    setShowAddress(true);
  };

  React.useEffect(() => {
    setValue("first_name", data?.first_name || "");
    setValue("phone_number", data?.phone_number || "");
    setValue("email", data?.email || "");
    setValue("physical_address_1", data?.physical_address_1 || "");
    setValue("physical_address_2", data?.physical_address_2 || "");
    setValue("country", data?.country_id || 0);
    setValue("city", data?.city || "");
    setValue("state", data?.state || "");
    setValue("zip", data?.zip || "");
    setValue("linked_in", data?.linked_in || "");
    setValue("facebook", data?.facebook || "");
    setValue("instagram", data?.instagram || "");
    setValue("website", data?.website || "");

    const [lng, lat] = data?.coordinate?.coordinates || [];
    setValue("lat", lat || 0);
    setValue("lng", lng || 0);
    setShowAddress(!!lat && !!lng);
  }, [data]);

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <List>
            <ListItem>
              <ListItemButton onClick={scrollToBasic as any}>
                <ListItemText>General</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={scrollToAddress as any}>
                <ListItemText>Address</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={scrollToSocial as any}>
                <ListItemText>Social Media</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={9}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Card sx={{ mt: 1 }} ref={basicRef}>
                <CardHeader title="General" />
                <CardContent>
                  <Controller
                    name="first_name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Client name"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.first_name}
                        helperText={
                          !!errors.first_name && "This field is required"
                        }
                      />
                    )}
                  />
                  <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                    <Controller
                      name="phone_number"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="Phone number"
                          value={value}
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                          sx={{ mt: 0 }}
                          error={!!errors.phone_number}
                          helperText={
                            !!errors.phone_number && "This field is required"
                          }
                        />
                      )}
                    />
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="Email"
                          value={value}
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                          sx={{ mt: 0 }}
                          error={!!errors.email}
                          helperText={
                            !!errors.email && "This field is required"
                          }
                        />
                      )}
                    />
                  </Stack>
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }} ref={addressRef}>
                <CardHeader title="Address" />
                <CardContent>
                  {!showAddress ? (
                    <SelectMapAddress onChange={handleMapAddressChange} />
                  ) : (
                    <Box>
                      <Button onClick={() => setShowAddress(false)}>
                        Search New Address
                      </Button>
                      <Controller
                        name="physical_address_1"
                        control={control}
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <TextField
                            InputLabelProps={{ shrink: !!value }}
                            label="Address 1"
                            value={value}
                            fullWidth
                            onChange={onChange}
                            onBlur={onBlur}
                            error={!!errors.physical_address_1}
                            helperText={
                              !!errors.physical_address_1 &&
                              "This field is required"
                            }
                          />
                        )}
                      />
                      <Controller
                        name="physical_address_2"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <TextField
                            InputLabelProps={{ shrink: !!value }}
                            label="Address 2 (optional)"
                            value={value}
                            fullWidth
                            onChange={onChange}
                            onBlur={onBlur}
                          />
                        )}
                      />
                      <Stack direction={"row"} spacing={1} sx={{ mt: 2 }}>
                        <Controller
                          name="city"
                          control={control}
                          rules={{ required: true }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              InputLabelProps={{ shrink: !!value }}
                              label="City"
                              value={value}
                              fullWidth
                              onChange={onChange}
                              onBlur={onBlur}
                              sx={{ mt: 0 }}
                              error={!!errors.city}
                              helperText={
                                !!errors.city && "This field is required"
                              }
                            />
                          )}
                        />
                        <Controller
                          name="state"
                          control={control}
                          rules={{ required: true }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              InputLabelProps={{ shrink: !!value }}
                              label="State"
                              value={value}
                              fullWidth
                              onChange={onChange}
                              onBlur={onBlur}
                              error={!!errors.state}
                              helperText={
                                !!errors.state && "This field is required"
                              }
                            />
                          )}
                        />
                        <Controller
                          name="zip"
                          control={control}
                          rules={{ required: true }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TextField
                              InputLabelProps={{ shrink: !!value }}
                              label="Zip"
                              value={value}
                              fullWidth
                              onChange={onChange}
                              onBlur={onBlur}
                              error={!!errors.zip}
                              helperText={
                                !!errors.zip && "This field is required"
                              }
                            />
                          )}
                        />
                      </Stack>
                      <Controller
                        name="country"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <SelectCountry
                            onChange={onChange}
                            value={value}
                            error={!!errors.country}
                            helperText={
                              !!errors.country && "This field is required"
                            }
                          />
                        )}
                      />
                      <CoordinateInfo lat={watch("lat")} lng={watch("lng")} />
                    </Box>
                  )}
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }} ref={socialRef}>
                <CardHeader title="Social Medias" />
                <CardContent>
                  <Controller
                    name="linked_in"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="LinkedIn URL (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <Controller
                    name="facebook"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Facebook URL (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <Controller
                    name="instagram"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Instagram URL (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <Controller
                    name="website"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Website URL (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </CardContent>
              </Card>
              <Stack direction="row" justifyContent={"flex-end"} sx={{ mt: 2 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={isLoading}
                  loading={adding || updating}
                  // sx={{ width: "50%" }}
                >
                  {!!data ? "Update" : "Create"}
                </LoadingButton>
              </Stack>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ClientEditPage;
