import * as React from "react";
import {
  Box,
  Button,
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import NoResultsOverlay from "../../components/NoResultOverlay";
import { useTitle } from "../../hooks/useTitle";
import { useNavigate } from "react-router-dom";
import { useGetInvoicesQuery } from "../../services/invoice.service";
import { Invoice } from "../../types/invoice";
import dayjs from "dayjs";
import { useCurrentUser } from "../../hooks/useCurrentUser";

const InvoicesPage = () => {
  useTitle("Company Invoices");
  const navigate = useNavigate();
  const user = useCurrentUser();

  const [topupFormModal, setTopupFormModal] = React.useState<{
    open: boolean;
  }>({
    open: false,
  });

  const [startingAfter, setStartingAfter] = React.useState("");

  const { data, isLoading, isFetching, refetch } = useGetInvoicesQuery({
    startingAfter,
  });

  const hasMore = React.useMemo(() => data?.has_more, [data]);

  const loadFirstPage = () => {
    // navigate(0);
    setStartingAfter("");
  };

  const loadNextPage = () => {
    // data = useGetProjectByIdQuery(parseInt(projectId!), {
    //   skip: !projectId,
    // });
    if (data?.data && data?.data.length > 0) {
      setStartingAfter(data?.data[data?.data.length - 1].id);
    }
  };

  const getChipColor = (status: string) => {
    switch (status) {
      case "open":
        return "warning";
      case "paid":
        return "success";
      default:
        return "secondary";
    }
  };

  // const onFetchMore = () => {
  //   refetch({
  //     variables: { startingAfter: data?.data[data?.data.length - 1].id },
  //     merge: (prev, next) => ({
  //       ...next,
  //       // Merge the new todos with the existing ones
  //       data?.data: [...prev.data?.data, ...next.data?.data],
  //     }),
  //   });
  // };

  return (
    <Box>
      <Paper sx={{ height: "70vh", mt: 2 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Number</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Due Date</TableCell>
                <TableCell>Currency</TableCell>
                <TableCell align="right">Total</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.data.map((row) => (
                <TableRow
                  key={row.number}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.number}
                  </TableCell>
                  <TableCell>
                    {dayjs(row.created * 1000).format("MM/DD/YYYY H:m:s")}
                  </TableCell>
                  <TableCell>
                    {dayjs(row.due_date * 1000).format("MM/DD/YYYY H:m:s")}
                  </TableCell>
                  <TableCell>{row.currency.toUpperCase()}</TableCell>
                  <TableCell align="right">
                    {`$${(row.total / 100)?.toFixed(2)}`}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={row.status.toUpperCase()}
                      color={getChipColor(row.status)}
                      size="small"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    {row.status === "paid" ? (
                      <Button
                        variant="contained"
                        size="small"
                        href={row.invoice_pdf}
                      >
                        Download
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        href={row.hosted_invoice_url}
                      >
                        Checkout
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box display="flex" sx={{ mt: 4, mr: 4 }}>
          <Box sx={{ flexGrow: 1 }}>&nbsp;</Box>
          <Stack direction="row" spacing={2}>
            {startingAfter && (
              <Button
                variant="contained"
                onClick={() => {
                  loadFirstPage();
                }}
              >
                First Page
              </Button>
            )}
            {hasMore && (
              <Button
                variant="contained"
                onClick={() => {
                  loadNextPage();
                }}
              >
                Next Page
              </Button>
            )}
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
};
export default InvoicesPage;
