import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import { Talent, UserWorkStatus } from "../../types/users";
import { nl2br } from "../../utils/helper";

interface ModalTalentDetailProps {
  open: boolean;
  onClose: () => void;
  talent: Talent | null;
}

const ModalTalentDetail = ({
  open,
  onClose,
  talent,
}: ModalTalentDetailProps) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
      {/* <DialogTitle>{talent?.full_name}</DialogTitle> */}
      <DialogContent>
        <Stack direction={"row"} sx={{ p: 2 }} alignItems="center">
          <Avatar
            alt={`${talent?.first_name}`}
            src={`${talent?.photo?.[0]?.original_url}`}
            sx={{ width: 100, height: 100 }}
          />
          <Box sx={{ ml: 2 }}>
            <Typography variant="h6">{talent?.full_name}</Typography>
            <Typography variant="body2">
              {talent?.city}, {talent?.state} {talent?.zip}
            </Typography>
            <Rating
              value={talent?.total_rating || 0}
              readOnly
              precision={0.5}
            />
          </Box>
        </Stack>
        <Box sx={{ px: 2, pb: 2 }}>
          <Typography sx={{ fontSize: 12 }}>{nl2br(talent?.bio)}</Typography>
        </Box>
        <Divider />

        <List>
          <ListItem>
            <Stack spacing={1} sx={{ mt: 1 }}>
              <Typography sx={{ fontSize: 12 }}>Industries</Typography>
              <Box>
                {talent?.business_verticals?.length == 0 ? (
                  <Typography sx={{ fontSize: 14 }}>
                    No industry added
                  </Typography>
                ) : (
                  talent?.business_verticals?.map((it) => (
                    <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                  ))
                )}
              </Box>
            </Stack>
          </ListItem>
          <Divider />
          <ListItem>
            <Stack spacing={1} sx={{ mt: 1 }}>
              <Typography sx={{ fontSize: 12 }}>Skills</Typography>
              <Box>
                {talent?.skills?.length == 0 ? (
                  <Typography sx={{ fontSize: 14 }}>No skill added</Typography>
                ) : (
                  talent?.skills?.map((it) => (
                    <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                  ))
                )}
              </Box>
            </Stack>
          </ListItem>
          <Divider />
          {/* <ListItem>
            <Stack spacing={1} sx={{ mt: 1 }}>
              <Typography sx={{ fontSize: 12 }}>Languages</Typography>
              <Box>
                {talent?.languages?.length == 0 ? (
                  <Typography sx={{ fontSize: 14 }}>
                    No languages added
                  </Typography>
                ) : (
                  talent?.languages?.map((it) => (
                    <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                  ))
                )}
              </Box>
            </Stack>
          </ListItem>
          <Divider /> */}
          <ListItem>
            <Stack spacing={1} sx={{ mt: 1 }}>
              <Typography sx={{ fontSize: 12 }}>Work Status</Typography>
              <Typography sx={{ fontSize: 14 }}>
                {talent?.talent?.work_status == UserWorkStatus.SOLE_PROPRIETOR
                  ? "Sole Proprietor"
                  : talent?.talent?.work_status == UserWorkStatus.ENTREPRENEUR
                  ? "Entrepreneur"
                  : talent?.talent?.work_status == UserWorkStatus.PART_TIME
                  ? "Part Time"
                  : "Not Set"}
              </Typography>
            </Stack>
          </ListItem>
          <Divider />
          <ListItem>
            <Stack spacing={1} sx={{ mt: 1 }}>
              <Typography sx={{ fontSize: 12 }}>Availability</Typography>
              <Stack direction={"row"}>
                {talent?.talent?.avail_monday && <Chip label="Mon" />}
                {talent?.talent?.avail_tuesday && <Chip label="Tue" />}
                {talent?.talent?.avail_wednesday && <Chip label="Wed" />}
                {talent?.talent?.avail_thursday && <Chip label="Thu" />}
                {talent?.talent?.avail_friday && <Chip label="Fri" />}
                {talent?.talent?.avail_saturday && <Chip label="Sat" />}
                {talent?.talent?.avail_sunday && <Chip label="Sun" />}
              </Stack>
            </Stack>
          </ListItem>
          <Divider />
          <ListItem>
            <Stack spacing={1} sx={{ mt: 1 }}>
              <Typography sx={{ fontSize: 12 }}>More Info</Typography>
              <Box>
                <ListItemText>
                  Rate → {talent?.min_pay} - {talent?.max_pay} $/hr
                </ListItemText>
                <ListItemText>
                  Commute Radius → {talent?.commute_radius} miles
                </ListItemText>
                <ListItemText>
                  Allow Background Check →
                  {!!talent?.allow_background_check ? "Yes" : "No"}
                </ListItemText>
                <ListItemText>
                  Allow Drug Test → {!!talent?.allow_drug_test ? "Yes" : "No"}
                </ListItemText>
              </Box>
            </Stack>
          </ListItem>
          <Divider />
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalTalentDetail;
