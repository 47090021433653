import * as React from "react";
import {
  Button,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Link,
  Table,
  Typography,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Talent } from "../../types/users";
import { useNavigate } from "react-router-dom";
// import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";

const TalentProfilePanel = ({ data }: { data?: Talent }) => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={1} sx={{ mt: 4 }}>
      <Grid item lg={8} sm={12}>
        <Card>
          <CardHeader title="About" />
          <CardContent>{data?.bio}</CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Industries" />
          <CardContent>
            {data?.business_verticals?.length == 0
              ? "n/a"
              : data?.business_verticals?.map((it) => (
                  <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                ))}
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Skills" />
          <CardContent>
            {data?.skills?.length == 0
              ? "No industries added"
              : data?.skills?.map((it) => (
                  <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                ))}
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Work Experience" />
          <CardContent>
            <Grid container>
              {data?.work_experiences.map((item) => (
                <Grid item xs={12} sm={6}>
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      sx={{ fontSize: 14 }}
                      fontWeight="fontWeightMedium"
                    >
                      {item?.company || "n/a"}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }}>
                      <Box sx={{ fontStyle: "italic" }}>
                        {`${dayjs()
                          .set("month", item?.start_period_month - 1)
                          .set("year", item?.start_period_year)
                          .format("MMM YYYY")} to ${
                          item?.end_period_month && item?.end_period_year
                            ? dayjs()
                                .set("month", item?.end_period_month - 1)
                                .set("year", item?.end_period_year)
                                .format("MMM YYYY")
                            : "present"
                        }`}
                      </Box>
                      <Box>{item?.description || "n/a"}</Box>
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Education" />
          <CardContent>
            <Grid container>
              {data?.educations.map((item) => (
                <Grid item xs={12} sm={6}>
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      sx={{ fontSize: 14 }}
                      fontWeight="fontWeightMedium"
                    >
                      {item?.school}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }}>
                      <Box sx={{ fontStyle: "italic" }}>
                        {`${dayjs(item?.start_date).format("MM/DD/YYYY")} to ${
                          item?.end_date
                            ? dayjs(item?.end_date).format("MM/DD/YYYY")
                            : "present"
                        }`}
                      </Box>
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} component="div">
                      <Box fontWeight="fontWeightMedium" display="inline">
                        Degree
                      </Box>
                      : {item?.degree || "n/a"}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} component="div">
                      <Box fontWeight="fontWeightMedium" display="inline">
                        Field of study
                      </Box>
                      : {item?.field_of_study || "n/a"}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="License & Certificates" />
          <CardContent>
            <Grid container>
              {data?.certifications.map((item) => (
                <Grid item xs={12} sm={6}>
                  <Box sx={{ mb: 2 }}>
                    <Link href={item?.certification_url} target="blank">
                      {item?.certification?.name}
                    </Link>
                    <Typography sx={{ fontSize: 14 }} component="div">
                      <Box fontWeight="fontWeightMedium" display="inline">
                        Number #
                      </Box>
                      : {`${item?.certification_number || "n/a"}`}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }}>
                      <Box fontWeight="fontWeightMedium" display="inline">
                        Expires
                      </Box>
                      :{" "}
                      {item?.expiration_date
                        ? dayjs(item?.expiration_date).format("MM/DD/YYYY")
                        : "n/a"}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Languages" />
          <CardContent>
            {data?.languages?.length == 0
              ? "No languages added"
              : data?.languages?.map((it) => (
                  <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                ))}
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={4} sm={12}>
        <Card sx={{ mt: 0 }}>
          <CardHeader title="Contact" />
          <CardContent>
            <Table sx={{ fontSize: 14 }} size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Address 1</TableCell>
                  <TableCell>{data?.physical_address_1}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Address 2</TableCell>
                  <TableCell>{data?.physical_address_2}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>City / Zip</TableCell>
                  <TableCell>
                    {data?.city} / {data?.zip}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>State</TableCell>
                  <TableCell>{data?.state}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Country</TableCell>
                  <TableCell>{data?.country?.name || "n/a"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Availabilty" />
          <CardContent>
            <Table sx={{ fontSize: 14 }} size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Monday</TableCell>
                  <TableCell align="right">
                    {!!data?.talent?.avail_monday ? "✅" : "❌"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tuesday</TableCell>
                  <TableCell align="right">
                    {!!data?.talent?.avail_tuesday ? "✅" : "❌"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Wednesday</TableCell>
                  <TableCell align="right">
                    {!!data?.talent?.avail_wednesday ? "✅" : "❌"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Thursday</TableCell>
                  <TableCell align="right">
                    {!!data?.talent?.avail_thursday ? "✅" : "❌"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Friday</TableCell>
                  <TableCell align="right">
                    {!!data?.talent?.avail_friday ? "✅" : "❌"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Saturday</TableCell>
                  <TableCell align="right">
                    {!!data?.talent?.avail_saturday ? "✅" : "❌"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sunday</TableCell>
                  <TableCell align="right">
                    {!!data?.talent?.avail_sunday ? "✅" : "❌"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="More Info" />
          <CardContent>
            <Table sx={{ fontSize: 14 }} size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Background Check</TableCell>
                  <TableCell align="right">
                    {!!data?.allow_background_check ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Drug Test</TableCell>
                  <TableCell align="right">
                    {!!data?.allow_drug_test ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Valid U.S Citizen or Have a Work Visa</TableCell>
                  <TableCell align="right">
                    {!!data?.talent?.is_valid_us ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export default TalentProfilePanel;
