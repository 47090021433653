import * as React from "react";
import {
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  Link,
  Paper,
  Rating,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Talent } from "../../../types/users";
import TalentDrawer from "../../talent/TalentDrawer";
import {
  useApproveTalentWorkMutation,
  useForceCheckoutMutation,
  useGetReportProgressQuery,
} from "../../../services/projectJob.service";
import dayjs from "dayjs";
import {
  Project,
  ProjectResource,
  ProjectReview,
  ProjectStatus,
  ReportProgress,
  ReportProgressStatus,
} from "../../../types/project";
import ConfirmModal from "../../../components/ConfirmModal";
import { toast } from "react-toastify";
import {
  getHiredTalentStatus,
  getLastProgress,
} from "../../../utils/projectHelper";
import { useSubmitProjectReviewMutation } from "../../../services/review.service";
import Star from "@mui/icons-material/Star";
import ModalBonusForm from "../ModalBonusForm";
import ModalCancelTalent from "../ModalCancelTalent";
import ForceCheckoutModal from "./ForceCheckoutModal";
import GiveReviewModal from "./GiveReviewModal";
import LogModal from "./LogModal";
import PhotoModal from "./PhotoModal";
import { History, NotesOutlined, PhotoCamera } from "@mui/icons-material";
import ModalRejectJob from "../ModalRejectJob";
import { formatWithTimezone } from "../../../utils/dateHelper";

const FinalNotesModal = ({
  open,
  onClose,
  notes,
}: {
  open: boolean;
  onClose: () => void;
  notes: string | null;
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={false} maxWidth="sm">
      <DialogTitle id="alert-dialog-title">Final Notes</DialogTitle>
      <DialogContent>
        <Typography>{notes || "n/a"}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const ButtonPhotos = ({
  projectId,
  progressId,
  shouldRefresh,
}: {
  projectId: number;
  progressId: number;
  shouldRefresh?: boolean;
}) => {
  const [photoVisible, setPhotoVisible] = React.useState(false);
  const { data, isLoading, isFetching, isError, refetch } =
    useGetReportProgressQuery(
      {
        projectId,
        progressId,
      },
      {
        skip: !progressId,
      }
    );

  React.useEffect(() => {
    if (!!shouldRefresh) refetch();
  }, [shouldRefresh]);

  if (isLoading || isFetching || isError) return null;
  if (!data?.documents || data?.documents?.length == 0) return null;

  return (
    <>
      <Button
        color="info"
        onClick={() => setPhotoVisible(true)}
        startIcon={<PhotoCamera />}
      >
        Photos
      </Button>
      <PhotoModal
        open={photoVisible}
        documents={data?.documents}
        onClose={() => setPhotoVisible(false)}
      />
    </>
  );
};

const TableHireds = ({
  project,
  bids,
  reviews,
  loading,
}: {
  project: Project;
  bids: Array<ProjectResource>;
  reviews: Array<ProjectReview>;
  loading: boolean;
}) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [selectedTalent, setSelectedTalent] = React.useState<Talent | null>(
    null
  );
  const [approveModal, setApproveModal] = React.useState<{
    visible: boolean;
    progress: ReportProgress | null;
  }>({
    visible: false,
    progress: null,
  });
  const [rejectModal, setRejectModal] = React.useState<{
    visible: boolean;
    progress: ReportProgress | null;
  }>({
    visible: false,
    progress: null,
  });
  const [logModal, setLogModal] = React.useState<{
    visible: boolean;
    job: ProjectResource | null;
  }>({
    visible: false,
    job: null,
  });
  const [reviewModal, setReviewModal] = React.useState<{
    visible: boolean;
    job: ProjectResource | null;
  }>({
    visible: false,
    job: null,
  });
  const [checkoutModal, setCheckoutModal] = React.useState<{
    visible: boolean;
    progress: ReportProgress | null;
  }>({
    visible: false,
    progress: null,
  });
  const [bonusModal, setBonusModal] = React.useState<{
    visible: boolean;
    job: ProjectResource | null;
  }>({
    visible: false,
    job: null,
  });
  const [cancelTalentModal, setCancelTalentModal] = React.useState<{
    visible: boolean;
    job: ProjectResource | null;
  }>({
    visible: false,
    job: null,
  });
  const [finalNotesModal, setFinalNotesModal] = React.useState<{
    visible: boolean;
    notes: string | null;
  }>({
    visible: false,
    notes: null,
  });

  const isHourly = React.useMemo(() => project?.type == "hourly", [project]);

  const [doApprove] = useApproveTalentWorkMutation();
  const [doSubmitReview] = useSubmitProjectReviewMutation();
  const [doForceCheckout] = useForceCheckoutMutation();

  const handleApprove = async () => {
    try {
      const progress = approveModal.progress;
      if (!progress) {
        toast.error("Invalid");
        return;
      }
      await doApprove({
        projectId: project.id,
        progressId: progress.id,
      }).unwrap();
      toast.success("Independent Professional's work approved!");
    } catch (err: any) {
      toast.error(err);
    }
  };

  const handleReview = async (
    resource_id: number,
    star: number,
    message: string
  ) => {
    try {
      await doSubmitReview({
        resource_id,
        project_id: project.id,
        star,
        review: message,
      }).unwrap();
    } catch (err: any) {
      toast.error(err);
    }
  };

  const handleForceCheckout = async (
    progress_id: number,
    checkout_date: Date
  ) => {
    try {
      await doForceCheckout({
        projectId: project.id,
        progressId: progress_id,
        checkoutDate: checkout_date,
      }).unwrap();
      toast.success("Force checkout success");
    } catch (err: any) {
      toast.error(err);
    }
  };

  return (
    <Box>
      <Typography sx={{ fontSize: 16, fontWeight: "500", mb: 1 }}>
        Hired IPs
      </Typography>
      {loading && <p>Loading...</p>}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#eee" }}>
              <TableCell>Independent Professional</TableCell>
              <TableCell>Hired At</TableCell>
              <TableCell>Price</TableCell>
              {isHourly && <TableCell>Max Hour</TableCell>}
              <TableCell>Start</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bids?.length == 0 && (
              <TableRow>
                <TableCell colSpan={6}>
                  No Independent Professional hired, yet
                </TableCell>
              </TableRow>
            )}
            {bids?.map((job) => {
              const status = getHiredTalentStatus(project, job);
              const progress = getLastProgress(job);
              const reviewForTalent = reviews?.find(
                (it) =>
                  it.model_type == "user" && it.model_id == job.resource_id
              );
              const reviewForClient = reviews?.find(
                (it) =>
                  it.model_type == "company" && it.created_by == job.resource_id
              );
              return (
                <>
                  <TableRow
                    key={job.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell width={250}>
                      <Stack direction={"row"} alignItems="center">
                        <Box>
                          <Stack direction={"row"} alignItems="center">
                            <Link
                              onClick={() => {
                                setSelectedTalent(job.resource || null);
                                setDrawerOpen(true);
                              }}
                              sx={{ fontSize: 16 }}
                            >
                              {job.resource?.full_name}
                            </Link>
                            <Rating
                              value={job.resource?.total_rating}
                              readOnly
                              precision={0.5}
                              size={"small"}
                              sx={{ ml: 1 }}
                            />
                          </Stack>
                          <Typography variant="caption">
                            {job.resource?.city}, {job.resource?.state}{" "}
                            {job.resource?.zip}
                          </Typography>
                        </Box>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {dayjs(job.hired_at).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell>
                      {isHourly ? `${job.rate} $/hr` : `${job.price} $`}
                    </TableCell>
                    {isHourly && <TableCell>{job.max_hour} hr</TableCell>}
                    <TableCell width={150}>
                      🕗 {formatWithTimezone(job.start_date, job.timezone)}
                    </TableCell>
                    <TableCell width={300}>
                      <Chip
                        label={status.label}
                        color={status.color}
                        size="small"
                      />
                      {!!status.helperText && (
                        <FormHelperText>{status.helperText}</FormHelperText>
                      )}
                      {/* {progress?.status == ReportProgressStatus.SUBMITTED &&
                        !!job.notes && (
                          <Paper sx={{ p: 1, mt: 1 }}>
                            <Typography>
                              Final Note→ <br />
                              {job.notes}
                            </Typography>
                          </Paper>
                        )} */}
                      {progress?.status == ReportProgressStatus.REJECTED &&
                        !!progress.reason && (
                          <FormHelperText>
                            Reason → {progress.reason}
                          </FormHelperText>
                        )}
                    </TableCell>
                    <TableCell align="right">
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                      >
                        {progress?.status == ReportProgressStatus.SUBMITTED && (
                          <>
                            <Button
                              color="success"
                              variant="outlined"
                              sx={{ mr: 1 }}
                              onClick={() =>
                                setApproveModal({ visible: true, progress })
                              }
                            >
                              Work Approved
                            </Button>
                            <Button
                              color="error"
                              variant="outlined"
                              sx={{ mr: 1 }}
                              onClick={() =>
                                setRejectModal({ visible: true, progress })
                              }
                            >
                              Work not Approved
                            </Button>
                          </>
                        )}
                        {progress?.status != ReportProgressStatus.APPROVED &&
                          progress?.status !=
                            ReportProgressStatus.SUBMITTED && (
                            <Button
                              color="error"
                              variant="outlined"
                              size="small"
                              onClick={() =>
                                setCancelTalentModal({ visible: true, job })
                              }
                            >
                              Cancel
                            </Button>
                          )}
                        {!!progress?.check_in && !progress?.check_out && (
                          <Button
                            color="info"
                            variant="text"
                            onClick={() =>
                              setCheckoutModal({ visible: true, progress })
                            }
                          >
                            Force Checkout
                          </Button>
                        )}
                        <Stack>
                          {!!job.notes && (
                            <Button
                              startIcon={<NotesOutlined />}
                              color="info"
                              variant="text"
                              onClick={() =>
                                setFinalNotesModal({
                                  visible: true,
                                  notes: job.notes || null,
                                })
                              }
                              sx={{ ml: 1 }}
                            >
                              Final Notes
                            </Button>
                          )}
                          <ButtonPhotos
                            projectId={project.id}
                            progressId={progress?.id!}
                            shouldRefresh={loading}
                          />
                          <Button
                            color="info"
                            variant="text"
                            onClick={() => setLogModal({ visible: true, job })}
                            sx={{ ml: 1 }}
                            startIcon={<History />}
                          >
                            Log
                          </Button>
                        </Stack>
                        {progress?.status == ReportProgressStatus.APPROVED && (
                          <Button
                            color="info"
                            variant="outlined"
                            size="small"
                            onClick={() =>
                              setBonusModal({ visible: true, job })
                            }
                            sx={{ ml: 1 }}
                          >
                            $ Extra Spending
                          </Button>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                  {project.status == ProjectStatus.COMPLETED && (
                    <TableRow>
                      <TableCell />
                      <TableCell colSpan={2} sx={{ alignItems: "flex-start" }}>
                        <Typography variant="subtitle2">
                          Your Review for Independent Professional:
                        </Typography>
                        {!!reviewForTalent ? (
                          <Box>
                            <Rating
                              value={reviewForTalent.star}
                              readOnly={true}
                              precision={0.5}
                            />
                            <Typography variant="body2">
                              {reviewForTalent.review}
                            </Typography>
                          </Box>
                        ) : (
                          <Box>
                            <Typography variant="body2" sx={{ color: "#999" }}>
                              No review, yet
                            </Typography>
                            <Button
                              variant="outlined"
                              startIcon={<Star />}
                              sx={{ mt: 1 }}
                              onClick={() =>
                                setReviewModal({ visible: true, job })
                              }
                            >
                              Give Review
                            </Button>
                          </Box>
                        )}
                      </TableCell>
                      <TableCell colSpan={3} sx={{ verticalAlign: "top" }}>
                        <Typography variant="subtitle2">
                          Indepenedent's Professional Review for You:
                        </Typography>
                        {!!reviewForClient ? (
                          <Box>
                            <Rating
                              value={reviewForClient.star}
                              readOnly={true}
                              precision={0.5}
                            />
                            <Typography variant="body2">
                              {reviewForClient.review}
                            </Typography>
                          </Box>
                        ) : (
                          <Box>
                            <Typography variant="body2" sx={{ color: "#999" }}>
                              No review, yet
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TalentDrawer
        forceRefresh={true}
        data={selectedTalent}
        open={drawerOpen}
        setOpen={setDrawerOpen}
      />
      <ConfirmModal
        open={approveModal.visible}
        title={`Approve work done by this Independent Professional?`}
        onClose={() => setApproveModal({ visible: false, progress: null })}
        onConfirm={handleApprove}
      />
      <LogModal
        open={logModal.visible}
        job={logModal.job || null}
        onClose={() => setLogModal({ visible: false, job: null })}
      />
      <GiveReviewModal
        open={reviewModal.visible}
        job={reviewModal.job}
        onClose={() => setReviewModal({ visible: false, job: null })}
        onSubmit={handleReview}
      />
      <ForceCheckoutModal
        open={checkoutModal.visible}
        progress={checkoutModal.progress}
        onClose={() => setCheckoutModal({ visible: false, progress: null })}
        onSubmit={handleForceCheckout}
      />
      <ModalBonusForm
        open={bonusModal.visible}
        job={bonusModal.job}
        onClose={() => setBonusModal({ visible: false, job: null })}
      />
      <ModalCancelTalent
        open={cancelTalentModal.visible}
        onClose={() => setCancelTalentModal({ visible: false, job: null })}
        job={cancelTalentModal.job}
      />
      <ModalRejectJob
        open={rejectModal.visible}
        onClose={() => setRejectModal({ visible: false, progress: null })}
        progress={rejectModal.progress}
        projectId={project.id}
      />
      <FinalNotesModal
        open={finalNotesModal.visible}
        onClose={() => setFinalNotesModal({ visible: false, notes: null })}
        notes={finalNotesModal.notes}
      />
    </Box>
  );
};
export default TableHireds;
