import { FinancialReport } from "../types/report";
import { baseApi, QueryResponse } from "./base";
import { TAG_TYPE_REPORT } from "./tags";

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_REPORT],
});

export const reportApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getTotalClient: builder.query<any, void>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: () => ({
        url: `reports/total-client`,
        method: "GET",
      }),
      providesTags: (result, error) => [
        { type: TAG_TYPE_REPORT, id: "total-client" },
      ],
    }),
    getTotalHour: builder.query<
      {
        list: Array<{
          date: string;
          week: number;
          month: number;
          year: number;
          total_hour: number;
        }>;
      },
      string
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (show) => ({
        url: `reports/total-hour`,
        method: "GET",
        params: {
          show,
        },
      }),
      providesTags: (result, error) => [
        { type: TAG_TYPE_REPORT, id: "total-hour" },
      ],
    }),
    getTotalProject: builder.query<any, void>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: () => ({
        url: `reports/total-project`,
        method: "GET",
      }),
      providesTags: (result, error) => [
        { type: TAG_TYPE_REPORT, id: "total-project" },
      ],
    }),
    getTotalSpent: builder.query<
      {
        list: Array<{
          date: string;
          week: number;
          month: number;
          year: number;
          total_spent: number;
        }>;
      },
      string
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (show) => ({
        url: `reports/total-spent`,
        method: "GET",
        params: {
          show,
        },
      }),
      providesTags: (result, error) => [
        { type: TAG_TYPE_REPORT, id: "total-spent" },
      ],
    }),
    getFinanceReport: builder.query<
      Array<FinancialReport>,
      { start_date: string; end_date: string }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ start_date, end_date }) => ({
        url: `reports/finances`,
        method: "GET",
        params: {
          start_date,
          end_date,
        },
      }),
      transformResponse: (response: Array<FinancialReport>, meta, arg) => {
        return response.map((it) => {
          it.total_earning =
            it.type == "fixed"
              ? it.fix_price
              : it.rate_hour * it.total_working || 0;
          return {
            ...it,
          };
        });
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTotalClientQuery,
  useGetTotalHourQuery,
  useGetTotalProjectQuery,
  useGetTotalSpentQuery,
  useGetFinanceReportQuery,
} = reportApi;
