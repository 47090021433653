import * as React from "react";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PersonIcon from "@mui/icons-material/Person";
import { useTitle } from "../../hooks/useTitle";
import ProjectDetailsPanel from "./ProjectDetailsPanel";
import ProjectHistoriesPanel from "./ProjectLogsPanel";
import ProjectMessagesPanel from "./ProjectMessagesPanel";
import ProjectTalentsPanel from "./ProjectTalentsPanel";
import {
  NegotiationStatus,
  Project,
  ProjectStatus,
  ReportProgress,
  ReportProgressStatus,
} from "../../types/project";
import {
  useCancelProjectMutation,
  useCompleteProjectMutation,
  useGetProjectByIdQuery,
  usePublishProjectMutation,
} from "../../services/project.service";
import {
  Alert,
  Backdrop,
  ButtonGroup,
  CircularProgress,
  Divider,
  LinearProgress,
} from "@mui/material";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { toast } from "react-toastify";
import { AttachMoney, Check, Close, Edit, Send } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import ConfirmModal from "../../components/ConfirmModal";
import { getLastProgress } from "../../utils/projectHelper";
import ModalCancelProject from "./ModalCancelProject";
import qs from "qs";
import ModalInvite from "./ModalInvite";
import { formatWithTimezone } from "../../utils/dateHelper";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/*<Typography>{children}</Typography>*/}
          {children}
        </Box>
      )}
    </div>
  );
}

const ProjectMeta = ({
  icon,
  value,
}: {
  icon: React.ReactNode;
  value: string;
}) => (
  <Button
    variant="text"
    startIcon={icon}
    size="small"
    sx={{ mr: 2, textTransform: "unset", color: "text.secondary" }}
  >
    {value}
  </Button>
);

const StatusChip = ({ status }: { status?: string }) => {
  const style = { fontWeight: 500 };

  if (status == ProjectStatus.DRAFT)
    return <Chip label={"DRAFT"} sx={style} color="default" />;
  if (status == ProjectStatus.PUBLISHED)
    return <Chip label={"PUBLISHED"} sx={style} color="secondary" />;
  if (status == ProjectStatus.SCHEDULED)
    return <Chip label={"SCHEDULED"} sx={style} color="primary" />;
  if (status == ProjectStatus.IN_PROGRESS)
    return <Chip label={"IN PROGRESS"} sx={style} color="primary" />;
  if (status == ProjectStatus.COMPLETED)
    return <Chip label={"COMPLETED"} sx={style} color="info" />;
  if (status == ProjectStatus.CANCELED)
    return <Chip label={"CANCELLED"} sx={style} color="error" />;
  else return <Chip label={status} sx={style} color="default" />;
};

const ProjectHeader = ({ data }: { data?: Project }) => {
  const navigate = useNavigate();

  const [confirmPublishModalOpen, setConfirmPublishModalOpen] =
    React.useState(false);
  const [cancelModal, setCancelModal] = React.useState<{
    visible: boolean;
    project: Project | null;
  }>({
    visible: false,
    project: null,
  });
  const [confirmCompleteModalOpen, setConfirmCompleteModalOpen] =
    React.useState(false);

  const [publish, { isLoading: publishing }] = usePublishProjectMutation();
  const [cancel, { isLoading: canceling }] = useCancelProjectMutation();
  const [complete, { isLoading: completing }] = useCompleteProjectMutation();
  const [showModalInvite, setShowModalInvite] = React.useState(false);
  const [showFav, setShowFav] = React.useState(false);

  const canCancelProject = React.useMemo(
    () =>
      [
        ProjectStatus.DRAFT,
        ProjectStatus.PUBLISHED,
        ProjectStatus.BUDGET_APPROVAL,
      ].includes(data?.status!),
    [data]
  );

  const canCompleteProject = React.useMemo(() => {
    // check that all progress is approved
    const hired = data?.resources?.filter((it) => !!it.hired_at) || [];
    if (hired.length > 0) {
      const approved = hired.filter((it) => {
        const progress = getLastProgress(it);
        return progress?.status == ReportProgressStatus.APPROVED;
      });
      return approved.length == hired.length;
    } else {
      // no one hired yet
      return false;
    }
  }, [data]);

  const { city, state, zip } = React.useMemo(() => {
    if (!!data?.customer) {
      return data?.customer;
    } else if (!!data?.location) {
      return data?.location;
    } else
      return {
        city: "?",
        state: "?",
        zip: "?",
      };
  }, [data]);

  const handlePublish = React.useCallback(async () => {
    try {
      if (!data?.id) return;
      await publish(data?.id).unwrap();
      toast.success("Work published");
    } catch (err: any) {
      toast.error(err);
    }
  }, [data]);

  // const handleCancel = React.useCallback(async () => {
  //   try {
  //     if (!data?.id) return;
  //     await cancel(data?.id).unwrap();
  //     toast.success("Work canceled");
  //   } catch (err: any) {
  //     toast.error(err);
  //   }
  // }, [data]);
  const isAcceptingBid = React.useMemo(
    () => data?.status == ProjectStatus.PUBLISHED,
    [data]
  );

  const resource = React.useMemo(() => {
    const res = data?.resources?.filter(
      (it) =>
        it.status == NegotiationStatus.HIRED ||
        it.status == NegotiationStatus.COMPLETED
    )?.[0];
    return res;
  }, [data]);

  const handleComplete = React.useCallback(async () => {
    try {
      if (!data?.id) return;
      await complete(data?.id).unwrap();
      toast.success("Work completed!");
    } catch (err: any) {
      toast.error(err);
    }
  }, [data]);

  return (
    <>
      <Stack direction={"row"} alignItems="flex-start">
        <Stack direction="column" sx={{ mb: 4 }} flex={1}>
          <Stack direction="row">
            <Box sx={{ mr: 1 }}>
              <StatusChip status={data?.status} />
            </Box>
            <Typography variant="h5">{`#${data?.id || ""} ${
              data?.title || ""
            }`}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <ProjectMeta
              icon={<BusinessCenterIcon />}
              value={!!data?.is_private ? "Private Posting" : "Public Posting"}
            />
            <ProjectMeta
              icon={<AttachMoney />}
              value={data?.type == "hourly" ? "Hourly" : "Fixed Rate"}
            />
            <ProjectMeta
              icon={<CalendarMonthIcon />}
              value={
                !!resource &&
                (data?.status == ProjectStatus.IN_PROGRESS ||
                  data?.status == ProjectStatus.SCHEDULED ||
                  data?.status == ProjectStatus.COMPLETED)
                  ? formatWithTimezone(resource?.start_date, resource?.timezone)
                  : formatWithTimezone(data?.start_date, data?.timezone)
              }
            />
            {/* <ProjectMeta
              icon={<LocationCityIcon />}
              value={`${city || "City"}, ${state || "State"} ${zip || "00000"}`}
            /> */}
            <ProjectMeta
              icon={<PersonIcon />}
              value={`${data?.num_of_talent || "#"} IP(s)`}
            />
          </Stack>
          {data?.status == ProjectStatus.CANCELED && (
            <Alert severity="error" sx={{ mt: 1 }}>
              Cancel Reason: {data?.canceled_reason || "n/a"}
            </Alert>
          )}
        </Stack>
        <Stack direction={"row"} sx={{ mt: 4 }}>
          {isAcceptingBid && (
            <Button
              variant="contained"
              color="info"
              onClick={() => setShowModalInvite(true)}
              sx={{ ml: 1 }}
            >
              + Invite Independent Professional
            </Button>
          )}
          {[
            ProjectStatus.DRAFT,
            ProjectStatus.PUBLISHED,
            ProjectStatus.SCHEDULED,
            ProjectStatus.IN_PROGRESS,
          ].includes(data?.status!) && (
            <Button
              variant="contained"
              color="success"
              size="large"
              sx={{ ml: 1, height: 42 }}
              onClick={() => navigate(`/works/${data?.id}/edit`)}
              startIcon={<Edit />}
            >
              {data?.status === ProjectStatus.IN_PROGRESS ||
              data?.status === ProjectStatus.SCHEDULED
                ? "Change Work Manager"
                : "Edit"}
            </Button>
          )}
          {[ProjectStatus.DRAFT].includes(data?.status!) && (
            <LoadingButton
              variant="contained"
              color="info"
              size="large"
              sx={{ ml: 1 }}
              onClick={() => setConfirmPublishModalOpen(true)}
              startIcon={<Send />}
              loading={publishing}
            >
              Publish Work
            </LoadingButton>
          )}

          {canCancelProject && (
            <LoadingButton
              variant="contained"
              size="large"
              sx={{ ml: 1 }}
              onClick={() =>
                setCancelModal({ visible: true, project: data || null })
              }
              startIcon={<Close />}
              color="error"
              loading={canceling}
            >
              Cancel Work
            </LoadingButton>
          )}
          {[ProjectStatus.IN_PROGRESS].includes(data?.status!) && (
            <Stack>
              <LoadingButton
                variant="contained"
                size="large"
                sx={{ ml: 1 }}
                onClick={() => setConfirmCompleteModalOpen(true)}
                startIcon={<Check />}
                disabled={!canCompleteProject}
                loading={completing}
              >
                Complete Work
              </LoadingButton>
              {!canCompleteProject && (
                <Typography variant="caption">
                  Please approve all Independent Professional's work before
                  completing.
                </Typography>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
      <ConfirmModal
        open={confirmPublishModalOpen}
        title={"Publish Work?"}
        onClose={() => setConfirmPublishModalOpen(false)}
        onConfirm={handlePublish}
      />
      <ModalCancelProject
        open={cancelModal.visible}
        onClose={() => setCancelModal({ visible: false, project: null })}
        project={cancelModal.project}
      />
      <ConfirmModal
        open={confirmCompleteModalOpen}
        title={"Complete & Finish the Work?"}
        onClose={() => setConfirmCompleteModalOpen(false)}
        onConfirm={handleComplete}
      />
      {data && (
        <ModalInvite
          open={showModalInvite}
          onClose={() => {
            setShowFav(false);
            setShowModalInvite(false);
          }}
          project={data!}
          showFav={showFav}
          setShowFav={setShowFav}
        />
      )}
    </>
  );
};

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const ProjectDetailPage = () => {
  const { projectId } = useParams();

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { data, isLoading, isFetching } = useGetProjectByIdQuery(
    parseInt(projectId!),
    {
      skip: !projectId,
    }
  );

  useTitle(`Work ID#${data?.id}`, `${data?.title}`);

  React.useEffect(() => {
    const params = qs.parse(window.location.href.split("?")[1]);
    console.log(params);
    if (params.t) {
      setValue(Number(params.t));
    }
  }, []);
  return (
    <Box>
      <ProjectHeader data={data} />
      {(isLoading || isFetching) && <LinearProgress />}
      <Box sx={{ bgcolor: "background.paper", width: "auto", boxShadow: 1 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="fullWidth"
        >
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab label="Independent Professionals" {...a11yProps(1)} />
          <Tab label="Chats" {...a11yProps(2)} />
          {/* <Tab label="Log" {...a11yProps(3)} /> */}
        </Tabs>
        <Divider />
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ProjectDetailsPanel data={data} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {!!data && <ProjectTalentsPanel project={data} />}
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          {!!data && <ProjectMessagesPanel project={data} />}
        </TabPanel>
        {/* <TabPanel value={value} index={3} dir={theme.direction}>
          <ProjectHistoriesPanel />
        </TabPanel> */}
      </Box>
    </Box>
  );
};
export default ProjectDetailPage;
