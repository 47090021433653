import * as React from "react";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  MessageSeparator,
  Avatar,
} from "@chatscope/chat-ui-kit-react";
import { NegotiationStatus, Project, ProjectStatus } from "../../types/project";
import {
  useGetChatMessagesQuery,
  useJoinChatMutation,
  useSendChatMessageMutation,
} from "../../services/chat.service";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import { ChatOutlined, Refresh } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useFilePicker } from "use-file-picker";
import { ROLE_TALENT_ID } from "../../configs/api";

const ProjectMessagesPanel = ({ project }: { project: Project }) => {
  const navigate = useNavigate();
  const user = useCurrentUser();
  const chatId = project.chats?.[0]?.id || 0;
  const [openFileSelector, { plainFiles, loading, errors, clear }] =
    useFilePicker({
      multiple: false,
      accept: [
        ".doc",
        ".docx",
        ".pdf",
        ".txt",
        ".jpeg",
        ".jpg",
        ".png",
        ".gif",
        ".pptx",
        ".zip",
        ".xls",
        "xlsx",
        ".csv",
      ],
      maxFileSize: 50,
      readFilesContent: false,
    });

  const { data, isLoading, isFetching, refetch, isError, error } =
    useGetChatMessagesQuery(
      {
        projectId: project.id,
        chatId,
      },
      { skip: chatId == 0 }
    );
  const [doSend, { isLoading: sending }] = useSendChatMessageMutation();
  const [doJoin, { isLoading: joining }] = useJoinChatMutation();

  const _send = async (text: string, file?: File) => {
    try {
      if (text?.length == 0) return;
      await doSend({
        body: text,
        chat_id: chatId,
        project_id: project.id,
        file,
      }).unwrap();
      clear();
    } catch (err: any) {
      toast.error(err.message);
      clear();
    }
  };
  // @ts-ignore
  const onSend = React.useCallback((innerHtml, text, innerText: any) => {
    if (innerText?.length == 0) return;
    _send(innerText);
  }, []);

  const handleJoinChat = async () => {
    try {
      //get one resource_id before joining (API requirement)
      const res_id = project?.resources?.find((it) =>
        [NegotiationStatus.HIRED, NegotiationStatus.COMPLETED].includes(
          it.status
        )
      )?.resource_id;
      if (!res_id) {
        toast.error("Unable to find hired/completed talent on this project!");
        return;
      }
      await doJoin({ project_id: project?.id!, resource_id: res_id! }).unwrap();
      toast.success("You've joined the chat room");
      refetch();
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  React.useEffect(() => {
    if (plainFiles?.length > 0) {
      const file = plainFiles[0];
      _send(file.name, file);
    }
  }, [plainFiles]);

  React.useEffect(() => {
    errors?.[0]?.fileSizeTooSmall && toast.error("File size is too small!");
    errors?.[0]?.fileSizeToolarge && toast.error("File size is too large!");
    errors?.[0]?.readerError &&
      toast.error("Problem occured while reading file!");
    errors?.[0]?.maxLimitExceeded && toast.error("Too many files");
    errors?.[0]?.minLimitNotReached && toast.error("Not enought files");
  }, [errors]);

  const Messages = React.useMemo(() => {
    let lastDate = "";
    return data?.data
      ?.slice(0)
      .reverse()
      .map((it) => {
        let curDate = dayjs(it.created_at).format("MMM DD, YYYY");
        let isNewDay = curDate != lastDate;
        if (isNewDay) lastDate = curDate;

        const isImage =
          it.documents?.[0]?.mime_type?.startsWith("image") || false;
        const isFile = !!it.documents?.[0]?.mime_type && !isImage; //anything but image

        return (
          <>
            {isNewDay && <MessageSeparator>{curDate}</MessageSeparator>}
            <Message
              key={it.id}
              model={{
                direction:
                  it.user?.role_id == ROLE_TALENT_ID
                    ? "incoming"
                    : it.user_id == user?.id
                    ? "outgoing"
                    : "incoming",
                position: "single",
              }}
            >
              {isImage ? (
                // <Message.ImageContent
                //   src={it.documents?.[0]?.original_url}
                //   width={200}
                //   onClick={() => console.log(it.documents?.[0]?.original_url)}
                // />
                <Message.HtmlContent
                  html={`<a href='${it.documents?.[0]?.original_url}' target='_blank'><img src='${it.documents?.[0]?.original_url}' width='250'/></a>`}
                />
              ) : isFile ? (
                <Message.HtmlContent
                  html={`${it.body}<br/><a href='${it.documents?.[0]?.original_url}' target='_blank'>⬇️ Open File</a>`}
                />
              ) : (
                <Message.HtmlContent html={it.body} />
              )}
              <Message.Footer
                sender={`${it.user?.role_id == 5 ? `[👷]` : `[Manager]`} ${
                  it.user?.full_name || "?"
                }`}
                sentTime={dayjs(it.created_at).format("hh:mm A")}
              />
            </Message>
          </>
        );
      });
  }, [data, user]);

  if (isError) {
    return (
      <Box>
        <Alert color="error" title="Error">
          {/* @ts-ignore */}
          {error?.data?.message}
        </Alert>
        {/* @ts-ignore */}
        {error.status == 403 && (
          <LoadingButton
            variant="contained"
            onClick={handleJoinChat}
            sx={{ mt: 2 }}
            loading={joining}
          >
            Join Chat
          </LoadingButton>
        )}
      </Box>
    );
  }

  return (
    <Box>
      <Stack sx={{ alignItems: "flex-end" }}>
        <LoadingButton
          onClick={refetch}
          size="large"
          loading={isFetching}
          startIcon={<Refresh />}
        >
          Refresh
        </LoadingButton>
      </Stack>
      {chatId == 0 && (
        <Typography sx={{ textAlign: "center", mt: 4, fontSize: 16 }}>
          Chat not available yet
        </Typography>
      )}
      <div style={{ position: "relative", height: "55vh", overflow: "hidden" }}>
        <MainContainer style={{ borderWidth: 1, borderRadius: 5 }}>
          <ChatContainer>
            <MessageList loading={isLoading}>{Messages}</MessageList>
            {[
              // ProjectStatus.PUBLISHED,
              ProjectStatus.SCHEDULED,
              ProjectStatus.IN_PROGRESS,
            ].includes(project.status) && (
              <MessageInput
                placeholder="Type message here"
                attachButton={true}
                onAttachClick={() => openFileSelector()}
                onSend={onSend}
                sendDisabled={sending}
              />
            )}
          </ChatContainer>
        </MainContainer>
      </div>
    </Box>
  );
};
export default ProjectMessagesPanel;
