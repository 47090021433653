import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
  GridSortModel,
  GridRowModel,
  GridToolbarContainer,
  GridRowClassNameParams,
  GridValueGetterParams,
  GridToolbarExport,
} from "@mui/x-data-grid";
import StarIcon from "@mui/icons-material/Star";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Rating,
  Stack,
  TextField,
} from "@mui/material";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import NoResultsOverlay from "../../components/NoResultOverlay";
import { toast } from "react-toastify";
import useDebounce from "../../hooks/useDebounce";
import { useTitle } from "../../hooks/useTitle";
import { Talent, FavoriteTalent } from "../../types/users";
import { useNavigate } from "react-router-dom";
import TalentDrawer from "./TalentDrawer";
import {
  useGetFavoriteTalentsQuery,
  useDeleteFavoriteTalentMutation,
} from "../../services/favoriteTalent.service";

const TableToolbar = ({ onSearch }: { onSearch: (query?: string) => void }) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);

  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
      <Stack style={{ flex: 1 }} direction="row" justifyContent="flex-end">
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{
            allColumns: true,
            fileName: "Favorite Independent Professionals",
          }}
        />
      </Stack>
    </GridToolbarContainer>
  );
};

const TalentsFavPage = () => {
  useTitle("Favorite Independent Professionals");

  const [deleteModal, setDeleteModal] = React.useState<{
    open: boolean;
    row: GridRowModel<FavoriteTalent> | null;
  }>({
    open: false,
    row: null,
  });
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );
  const [open, setOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState<Talent | null>(null);

  const { data, isLoading, isFetching } = useGetFavoriteTalentsQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
    sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
    search: searchQuery,
  });
  const rowCount = React.useMemo(() => data?.meta.total, [data]);

  const [doDelete, { isLoading: deleting }] = useDeleteFavoriteTalentMutation();

  const handleDeleteRow = (row: GridRowModel<FavoriteTalent>) => {
    setDeleteModal({ open: true, row });
  };

  const rows: FavoriteTalent[] | undefined = React.useMemo(
    () => data?.data,
    [data]
  );

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "avatar",
        headerName: "",
        width: 50,
        sortable: false,
        filterable: false,
        renderCell: (
          params: GridRenderCellParams<undefined, FavoriteTalent>
        ) => (
          <Avatar
            alt={`${params.row.user?.first_name}`}
            src={`${params.row?.user?.photo?.[0]?.original_url}`}
          />
        ),
        disableExport: true,
      },
      {
        field: "talent_name",
        headerName: "Independent Professional",
        flex: 1,
        minWidth: 150,
        // sortingOrder: ["desc", "asc", null],
        sortable: false,
        filterable: true,
        renderCell: (
          params: GridRenderCellParams<undefined, FavoriteTalent>
        ) => (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              setCurrentItem(params.row.user);
              setOpen(true);
            }}
          >
            {params.row.user?.full_name}
          </Link>
        ),
        disableExport: true,
      },
      {
        field: "full_name",
        headerName: "Full Name",
        hide: true,
        valueGetter: (
          params: GridValueGetterParams<undefined, FavoriteTalent>
        ) => `${params.row.user.full_name}`,
      },
      {
        field: "rating",
        headerName: "Rating",
        headerAlign: "center",
        minWidth: 150,
        sortable: false,
        filterable: true,
        align: "center",
        renderCell: (
          params: GridRenderCellParams<undefined, FavoriteTalent>
        ) => (
          <Rating
            value={params.row.user?.total_rating}
            readOnly
            precision={0.5}
          />
        ),
        disableExport: true,
      },
      {
        field: "user.email",
        headerName: "Email",
        headerAlign: "center",
        minWidth: 200,
        sortable: false,
        filterable: true,
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FavoriteTalent>
        ) => `${params.row.user.email}`,
      },
      {
        field: "phone_number",
        headerName: "Phonenumber",
        hide: true,
        valueGetter: (
          params: GridValueGetterParams<undefined, FavoriteTalent>
        ) => `${params.row.user.phone_number}`,
      },
      {
        field: "physical_address_1",
        headerName: "Address",
        hide: true,
        valueGetter: (
          params: GridValueGetterParams<undefined, FavoriteTalent>
        ) => `${params.row.user.physical_address_1}`,
      },
      {
        field: "user.city",
        headerName: "City",
        headerAlign: "center",
        minWidth: 150,
        sortable: false,
        filterable: true,
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FavoriteTalent>
        ) => `${params.row.user.city}`,
      },
      {
        field: "user.state",
        headerName: "State",
        headerAlign: "center",
        minWidth: 150,
        sortable: false,
        filterable: true,
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FavoriteTalent>
        ) => `${params.row.user.state}`,
      },
      {
        field: "zip",
        headerName: "Zip",
        hide: true,
        valueGetter: (
          params: GridValueGetterParams<undefined, FavoriteTalent>
        ) => `${params.row.user.zip}`,
      },
      {
        field: "control",
        headerName: "Delete",
        headerAlign: "center",
        minWidth: 130,
        sortable: false,
        filterable: false,
        align: "center",
        renderCell: (
          params: GridRenderCellParams<undefined, FavoriteTalent>
        ) => (
          <Stack direction={"row"}>
            <IconButton onClick={() => handleDeleteRow(params.row)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Stack>
        ),
        disableExport: true,
      },
      {
        field: "facebook",
        headerName: "Facebook",
        hide: true,
        valueGetter: (
          params: GridValueGetterParams<undefined, FavoriteTalent>
        ) => `${params.row.user.facebook}`,
      },
      {
        field: "instagram",
        headerName: "Instagram",
        hide: true,
        valueGetter: (
          params: GridValueGetterParams<undefined, FavoriteTalent>
        ) => `${params.row.user.instagram}`,
      },
      {
        field: "linkedin",
        headerName: "LinkedIn",
        hide: true,
        valueGetter: (
          params: GridValueGetterParams<undefined, FavoriteTalent>
        ) => `${params.row.user.linkedin}`,
      },
    ],
    []
  );

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const closeDeleteModal = () =>
    setDeleteModal({ ...deleteModal, open: false });

  const handleDelete = async () => {
    if (deleteModal.row?.id) {
      try {
        await doDelete(deleteModal.row.id).unwrap();
        closeDeleteModal();
        toast.success("Successfully deleted");
      } catch (err) {
        closeDeleteModal();
      }
    }
  };

  const CustomToolbar = React.useMemo(
    () => () => <TableToolbar onSearch={handleSearch} />,
    [handleSearch]
  );

  return (
    <Box sx={{ bgcolor: "background.paper", width: "auto" }}>
      <Paper style={{ height: "85vh" }}>
        <DataGrid
          rows={rows || []}
          columns={columns}
          paginationMode="server"
          rowCount={rowCount}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          pageSize={pageSize}
          rowsPerPageOptions={[1000]}
          onPageSizeChange={(newSize) => setPageSize(newSize)}
          sortModel={sortModel}
          sortingMode="server"
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          loading={isLoading || isFetching}
          components={{
            LoadingOverlay: LinearProgress,
            NoResultsOverlay: NoResultsOverlay,
            Toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          disableColumnFilter
          rowHeight={72}
          density="compact"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              borderTop: "1px solid #ddd",
            },
          }}
        />
      </Paper>
      <ConfirmDeleteModal
        open={deleteModal.open}
        onClose={closeDeleteModal}
        onDelete={handleDelete}
        message={`Delete '${deleteModal.row?.user.first_name} ${deleteModal.row?.user.last_name}' from favorite ?`}
      />
      <TalentDrawer data={currentItem!} open={open} setOpen={setOpen} />
    </Box>
  );
};
export default TalentsFavPage;
