import * as React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetProjectBidsQuery } from "../../services/projectJob.service";
import { Project } from "../../types/project";
import { LoadingButton } from "@mui/lab";
import { Refresh } from "@mui/icons-material";
import { useGetProjectReviewsQuery } from "../../services/review.service";
import TableHireds from "./talentsPanel/TableHired";
import TableBids from "./talentsPanel/TableBids";
import TableInvites from "./talentsPanel/TableInvites";
import TableCancel from "./talentsPanel/TableCancel";
import { useGetProjectByIdQuery } from "../../services/project.service";
import TableMatched from "./talentsPanel/TableMatched";

const ProjectTalentsPanel = ({ project }: { project: Project }) => {
  const navigate = useNavigate();
  const { refetch: refetchProject } = useGetProjectByIdQuery(project.id);
  const {
    data: jobs,
    isFetching,
    refetch,
  } = useGetProjectBidsQuery(project.id);

  const {
    data: reviews,
    isFetching: fetchingReviews,
    refetch: refetchReviews,
  } = useGetProjectReviewsQuery(project.id);

  const handleRefresh = () => {
    refetch();
    refetchProject();
    refetchReviews();
  };

  const hiredList = React.useMemo(
    () => jobs?.filter((it) => !!it.hired_at),
    [jobs]
  );
  const bidList = React.useMemo(
    () => jobs?.filter((it) => !it.hired_at),
    [jobs]
  );

  return (
    <Box sx={{ bgcolor: "background.paper", width: "auto" }}>
      <Stack sx={{ alignItems: "flex-end" }}>
        <LoadingButton
          onClick={handleRefresh}
          size="large"
          loading={isFetching}
          startIcon={<Refresh />}
        >
          Refresh
        </LoadingButton>
      </Stack>
      <TableHireds
        bids={hiredList || []}
        project={project}
        reviews={reviews || []}
        loading={isFetching}
      />
      <Box sx={{ mt: 5 }} />
      <TableBids bids={bidList || []} project={project} />
      <Box sx={{ mt: 5 }} />
      <TableInvites bids={bidList || []} project={project} />
      <Box sx={{ mt: 5 }} />
      <TableMatched bids={bidList || []} project={project} />
      <Box sx={{ mt: 5 }} />
      <TableCancel bids={bidList || []} project={project} />
    </Box>
  );
};
export default ProjectTalentsPanel;
