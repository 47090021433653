import { useNavigate } from "react-router-dom";
import { useTitle } from "../../hooks/useTitle";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import workz360 from "../../images/workz360.png";

const SignupPage = () => {
  const navigate = useNavigate();
  useTitle("Signup");

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid item xs={12} />
      {/* <Item>
          <h3>Get started on Workz360</h3>
        </Item>
      </Grid> */}
      <Grid item xs={12}>
        <Typography variant={"h5"} sx={{ textAlign: "center", m: 2 }}>
          <img src={workz360} height={50} />
          <br />
          Get started on Workz360 !
        </Typography>
        <Grid container justifyContent="center" spacing={4} padding={1}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="300"
                image={"https://source.unsplash.com/744oGeqpxPQ?w=300"}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  I'm looking for Workers
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Find workers that work for you.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="large"
                  onClick={() => navigate("/signup/company")}
                >
                  Signup as Company
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="300"
                image={"https://source.unsplash.com/sMKUYIasyDM?w=300"}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  I'm looking for work
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Find job that work for you.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="large"
                  href="https://play.google.com/store/search?q=workz360&c=apps"
                >
                  Signup as Independent Professional
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default SignupPage;
