import { ROLE_TALENT_ID } from "../configs/api";
import { FavoriteTalent } from "../types/users";
import { baseApi, QueryResponse } from "./base";
import { TAG_TYPE_FAVORITE_TALENT } from "./tags";

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_FAVORITE_TALENT],
});

export const favoriteTalentApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getFavoriteTalents: builder.query<
      QueryResponse<FavoriteTalent>,
      {
        page: number;
        pageSize: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
        load?: string;
      }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({
        page,
        pageSize,
        sortField,
        sortMode,
        search,
        load = "user,user.photo,user.talent,user.business_verticals,user.skills,user.languages",
      }) => ({
        url: "favorite-talents",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `user.first_name|asc`,
          // withTrashed: true,
          role: ROLE_TALENT_ID,
          search,
          load,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data)
          return [{ type: TAG_TYPE_FAVORITE_TALENT, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE_FAVORITE_TALENT, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE_FAVORITE_TALENT, id })),
        ];
      },
    }),
    getAllFavoriteTalents: builder.query<
      Array<FavoriteTalent>,
      { load?: string }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ load }) => ({
        url: "favorite-talents",
        method: "GET",
        params: {
          load: "user,user.photo",
        },
      }),
      providesTags: (result) => {
        const data = result;
        if (!data)
          return [{ type: TAG_TYPE_FAVORITE_TALENT, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE_FAVORITE_TALENT, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE_FAVORITE_TALENT, id })),
        ];
      },
    }),
    isFavoriteTalent: builder.query<Array<FavoriteTalent>, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (userId) => ({
        url: "favorite-talents",
        method: "GET",
        params: {
          user: userId,
        },
      }),
      providesTags: (result, error, id) => {
        return [{ type: TAG_TYPE_FAVORITE_TALENT, id }];
      },
    }),
    addFavoriteTalent: builder.mutation<void, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (userId) => {
        return {
          url: `favorite-talents`,
          method: "POST",
          body: {
            user: userId,
          },
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE_FAVORITE_TALENT, id: "PARTIAL-LIST" },
      ],
    }),
    deleteFavoriteTalent: builder.mutation<void, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (id) => ({
        url: `favorite-talents/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE_FAVORITE_TALENT, id: "PARTIAL-LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetFavoriteTalentsQuery,
  useGetAllFavoriteTalentsQuery,
  useIsFavoriteTalentQuery,
  useAddFavoriteTalentMutation,
  useDeleteFavoriteTalentMutation,
} = favoriteTalentApi;
