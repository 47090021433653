import * as React from "react";
import {
  Box,
  Button,
  LinearProgress,
  Link,
  Paper,
  Rating,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Talent, User } from "../../../types/users";
import TalentDrawer from "../../talent/TalentDrawer";
import { useInviteTalentMutation } from "../../../services/projectJob.service";
import { Project, ProjectResource } from "../../../types/project";
import { toast } from "react-toastify";
import { useGetFavoriteTalentsQuery } from "../../../services/favoriteTalent.service";
import { useGetTalentsQuery } from "../../../services/talent.service";
import { USStates } from "../../../components/SelectState";

const TableMatched = ({
  project,
  bids,
}: {
  project: Project;
  bids: Array<ProjectResource>;
}) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [selectedTalent, setSelectedTalent] = React.useState<Talent | null>(
    null
  );
  const [showTable, setShowTable] = React.useState(true);

  const {
    data: dataTalent,
    isLoading: isLoadingTalent,
    isFetching: isFetchingTalent,
  } = useGetTalentsQuery(
    {
      page: 0,
      pageSize: 100,
      load: "user,user.photo,photo,talent,business_verticals,skills",
      skills: project.skills?.map((it) => it.id),
      state:
        USStates.find((it) => it.label == project.location?.state)?.value ||
        project.location?.state,
      // city: project.location?.city,
    },
    {
      skip: !showTable,
    }
  );
  const { data: favTalents } = useGetFavoriteTalentsQuery(
    {
      page: 0,
      pageSize: 1000,
    },
    {
      skip: !showTable,
    }
  );

  const rowTalents: Talent[] | undefined = React.useMemo(() => {
    let data = dataTalent?.data;

    const sorted = data?.slice().sort((a: User, b: User) => {
      if (
        a.city === project.location?.city &&
        b.city !== project.location?.city
      ) {
        return -1;
      } else if (
        a.city !== project.location?.city &&
        b.city === project.location?.city
      ) {
        return 1;
      } else {
        // For other cities or if both are project city, maintain original order
        return 0;
      }
    });
    return sorted;
  }, [dataTalent, favTalents, project]);

  const [doInvite, { isLoading: isInviting }] = useInviteTalentMutation();

  const handleInvite = React.useCallback(
    async (resource_id: number) => {
      try {
        await doInvite({ project_id: project.id, resource_id }).unwrap();
      } catch (err: any) {
        toast.error(err);
      }
    },
    [project]
  );

  return (
    <Box>
      <Stack direction={"row"} alignItems="center" sx={{ mb: 1 }}>
        <Typography sx={{ fontSize: 16, fontWeight: "500", flex: 1 }}>
          Matched IPs{" "}
          <Button onClick={() => setShowTable(!showTable)} size="small">
            {showTable ? "Hide" : "Show"}
          </Button>
        </Typography>
        {/* {isAcceptingBid && (
          <Button variant="outlined" onClick={() => setShowModalInvite(true)}>
            + Invite Talent
          </Button>
        )} */}
      </Stack>
      {(isInviting || isFetchingTalent) && <LinearProgress />}
      {showTable && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#eee" }}>
                <TableCell>Independent Professional</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Rating</TableCell>
                <TableCell>Matched Skills</TableCell>
                <TableCell>City</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowTalents?.length == 0 && (
                <TableRow>
                  <TableCell colSpan={6}>No matched IPs found</TableCell>
                </TableRow>
              )}
              {rowTalents?.map((talent) => {
                const matched = project.skills?.filter(
                  (it) => talent.skills.find((s) => s.id == it.id) != undefined
                );
                const alreadyBid = !!bids?.find(
                  (it) => it.resource_id == talent.id
                );

                return (
                  <TableRow
                    key={talent.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell width={250}>
                      <Box>
                        <Link
                          onClick={() => {
                            setSelectedTalent(talent);
                            setDrawerOpen(true);
                          }}
                          sx={{ fontSize: 16 }}
                        >
                          {talent?.full_name}
                        </Link>
                        {favTalents?.data?.find(
                          (it) => it.user.id == talent?.id
                        ) != undefined && (
                          <Typography variant="body2">♥️ Favorited</Typography>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {talent?.phone_number}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{talent?.email}</Typography>
                    </TableCell>
                    <TableCell width={150}>
                      <Rating
                        value={talent?.total_rating}
                        readOnly
                        precision={0.5}
                        size={"small"}
                        sx={{ ml: 1 }}
                      />
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        arrow
                        title={matched?.map((it) => it.name).join(", ")}
                      >
                        <Typography variant="button">
                          {matched?.length}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{talent?.city}</Typography>
                    </TableCell>
                    <TableCell align="right" width={225}>
                      {alreadyBid ? (
                        <Typography variant="body2">Invited</Typography>
                      ) : (
                        <Button
                          variant="outlined"
                          color="info"
                          onClick={() => handleInvite(talent?.id)}
                        >
                          Invite
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TalentDrawer
        forceRefresh={true}
        data={selectedTalent}
        open={drawerOpen}
        setOpen={setDrawerOpen}
      />
    </Box>
  );
};
export default TableMatched;
