import React from "react";
import { store, persistor, RootState } from "./app/store";
import { Provider } from "react-redux";
import AppRoutes from "./Routes";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";

import "react-toastify/dist/ReactToastify.css";
import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import shadows, { Shadows } from "@mui/material/styles/shadows";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";

dayjs.extend(advancedFormat);
dayjs.extend(timezone);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <ThemeProvider theme={theme}> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline />
          <AppRoutes />
        </LocalizationProvider>
        {/* </ThemeProvider> */}
      </PersistGate>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
        theme="colored"
      />
    </Provider>
  );
}
export default App;
